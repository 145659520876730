import React, {useContext, useEffect,useState} from 'react';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import {Context as SingleCabContext} from "../../../context/SingleCabContext";
import moment from "moment";
import {Context as PaymentContext} from "../../../context/PaymentContext";
import {Context as VehiclesContext} from "../../../context/VehiclesContext";
import {Context as NotificationContext} from "../../../context/NotificationContext";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) =>({
    paper: {
        padding: theme.spacing(0.5),
        width: "100%",
        height: "100%",
        color: theme.palette.text.primary,
    },
}));
const paymentType =(type) =>{
    switch (type) {
        case 'FLUID': return 'העברה'
        case 'WIRE_TRANSFER': return 'העברה בנקאית'
        case 'CHECKS': return 'המחאות'
        case 'CREDIT_CARD': return 'כ.אשראי'
        case 'CASH': return 'מזומן'
        default: return 'ללא'
    }
}
const columns= [
    {
        title: 'תאריך שורה',
        field: 'createdOn',
        editable:'never',
        render:rowData =>moment(rowData.createdOn).format('YYYY-MM-DD HH:mm:ss')
    },
    {
        title: 'תאריך ביצוע',
        field: 'executionDate',
        editable:'never',
        render:rowData =>rowData.executionDate === null ? '--' : moment(rowData.executionDate).format('YYYY-MM-DD HH:mm:ss')
    },
    {
        title: 'סכום',
        field: 'amount',
        editable:'never',
        render:rowData =>rowData.amount.toFixed(0) +' ₪'

    },
    {
        title: 'מעמ',
        field: 'vat',
        editable:'never',
        render:rowData =>(Number(rowData.amountWithVat) - Number(rowData.amount)).toFixed(0) +' ₪'
    },
    {
        title: 'סכום כולל מעמ',
        field: 'totalAmount',
        editable:'never',
        render: rowData => Number(rowData.amountWithVat).toFixed(2) +' ₪'
    },

    {
        title: 'אצמעי תשלום',
        field: 'paymentType',
        editable:'never',
        render: rowData =>paymentType(rowData.paymentType)

    },
    {
        title: 'סטטוס',
        field: 'status',
        render: rowData => rowData.status===1  ? 'בוצע':'טרם בוצע',
        lookup: { 1: 'בוצע', 0: 'טרם בוצע' },
    },
    {
        title: 'הערות',
        field: 'comment'
    },

]
export default function PaymentsHistoryTable(props) {
    const {state:{dataCab,cabsPaymentUpdated},updatePaymentCommentCabModule} = useContext(SingleCabContext);
    const {state:{vehicle},updatePaymentFrontEnd,updatePaymentCommentFrontEnd,updatePaymentFrontEndSale} = useContext(VehiclesContext);
    const {state:{buyVehiclePaymentOutcome,paymentComment,saleVehiclePaymentIncome},updatePaymentComment} = useContext(PaymentContext);
    const {pipeMessage} =useContext(NotificationContext);

    const [data, setData] = useState(props.paymentsData);
    const classes = useStyles();
    const updateComment = (newData,oldData) =>{
        if(props.entity==='owner' || props.entity==='consumer'){

            updatePaymentCommentCabModule(newData,oldData,props.entity)
        }else{

            updatePaymentComment(newData,oldData)
        }
    }
    useEffect(() =>{
        if(vehicle && props.entity==='BUY-DEAL-PAYMENT' && buyVehiclePaymentOutcome && buyVehiclePaymentOutcome.payments){
            updatePaymentFrontEnd(buyVehiclePaymentOutcome);
            pipeMessage("תשלום תועד בהצלחה");
        }else if(vehicle && props.entity==='SALE-DEAL-PAYMENT' && saleVehiclePaymentIncome && saleVehiclePaymentIncome.payments){

            updatePaymentFrontEndSale(saleVehiclePaymentIncome);
            pipeMessage("תשלום תועד בהצלחה");
        }

    },[buyVehiclePaymentOutcome,saleVehiclePaymentIncome]);

    useEffect(() =>{
        if(dataCab && props.entity==='owner'){
            setData(dataCab.supplierContract.payments);
        }else if(dataCab && props.entity==='consumer'){
            setData(dataCab.consumerContract.payments);

        }
    },[cabsPaymentUpdated,dataCab]);

    useEffect(()=>{
        if(paymentComment && props.entity==='BUY-DEAL-PAYMENT'){
            updatePaymentCommentFrontEnd(paymentComment);
        }else if(paymentComment && props.entity==='SALE-DEAL-PAYMENT'){
            updatePaymentFrontEndSale(paymentComment)
        }
    },[paymentComment]);



    useEffect(() =>{
        if(vehicle && props.entity==='BUY-DEAL-PAYMENT' && buyVehiclePaymentOutcome){
            setData(vehicle.vehicleBook.buyDeal.payments)
        }else if(vehicle && props.entity==='SALE-DEAL-PAYMENT' && saleVehiclePaymentIncome){
            setData(vehicle.vehicleBook.saleDeal.payments)
        }else if(vehicle && props.entity==='BUY-DEAL-PAYMENT'){
            setData(vehicle.vehicleBook.buyDeal.payments)
        }else if(vehicle && props.entity==='SALE-DEAL-PAYMENT'){

            setData(vehicle.vehicleBook.saleDeal.payments)
        }

    },[vehicle])

    return (
        <>
        <Paper variant="outlined" className={classes.paper} >
        <MaterialTable
            title={<Typography variant={'body1'}>{props.tableTitle}</Typography>}
            columns={columns}
            style={{boxShadow:"none",tableLayout: 'auto'}}
            fixedHeader={false}
            data={data }
            localization={{
                toolbar: {
                    searchPlaceholder: 'חפש'
                },
                header: {
                    actions:''
                },

            }}
            options={{actionsColumnIndex: -1, paging: false,search: false}}

            editable={{
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve,reject) => {
                      //  updatePaymentComment(newData,oldData);

                        updateComment(newData,oldData);
                        resolve()
                    }).catch(reason => console.log(reason)),
            }}
        />
        </Paper>
        </>
    );
}
