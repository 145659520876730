import React, {useContext, useEffect} from 'react';
import PaymentsHistoryTable from "./PaymentsHistoryTable";
import Grid from "@material-ui/core/Grid";
import CreatePaymentComponent from "./CreatePaymentComponent";
import {makeStyles} from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import IconButton from "@material-ui/core/IconButton";
import {Context as PaymentContext} from "../../../context/PaymentContext";

const useStyles = makeStyles((theme) =>({
    paper2:{
      //  padding: theme.spacing(2),

    },
}));
const PaymentComponent = (props) =>{

    const {setPaymentModeBuyVehicle,setPaymentModeSaleVehicle} = useContext(PaymentContext);
    const goBack = () =>{
        if(props.entity ==='BUY-DEAL-PAYMENT'){
            setPaymentModeBuyVehicle(false)
        }else if(props.entity ==='SALE-DEAL-PAYMENT'){
            setPaymentModeSaleVehicle(false)
        }
    }
    const classes = useStyles();
    return(
        <Grid
            className={classes.paper2  }
            container
            spacing={1}
            direction="row"
        >
        <Grid item xs={12}>
            <IconButton color="primary" onClick={() =>goBack()}>
                <ArrowForwardIcon />
            </IconButton>
        </Grid>
        <Grid item xs={3} >
            <CreatePaymentComponent
                dealId={props.dealId}
                nextPayment={props.nextPayment}
                entity ={props.entity}
                paymentAmount={props.paymentAmount}
                paymentType={props.paymentType}
                contractId={props.contractId}
                fullPrice={props.fullPrice}
                conStatus={props.conStatus}
                title={props.title}
                vat={props.vat}
                paymentDirection={props.paymentDirection}

            />
        </Grid>
        <Grid item xs={9} >
            <PaymentsHistoryTable
                entity ={props.entity}
                paymentsData = {props.payments}
                tableTitle={props.tableTitle}
            />
        </Grid>
        </Grid>
    )
}
export default PaymentComponent;
