import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi'
const AuthReducer = (state,action) =>{
    switch (action.type) {
        case 'auth_token':
            return{
                ...state,
                token:action.payload.TOKEN.jwt,
                isAuthenticated:true,
                isAdmin:action.payload.IS_ADMIN,
                fullName:action.payload.FULL_NAME
            }
        case 'check_is_authenticated':
            return{
                ...state,
                isAuthenticated:action.payload
            }
        case 'validateName' :
            return{
                ...state,
                fullName:action.payload
        }
        case 'response_issue' :
            return{
                ...state,
                errorMessage:!state.errorMessage

        }

    }
}
const authenticate = dispatch =>  async(user,password) =>{

    const response = await InterceptorApi().post('/api/authenticate', {userEmail:user,password:password});
    if(!response.data){
        dispatch({type:'response_issue',payload:'משהו השתבש, נסה מאוחר יותר'})
    }else{
        localStorage.setItem("token",response.data.TOKEN.jwt);

        if(response.data.IS_ADMIN ===true){
            localStorage.setItem("admin",true)
        }
        if(response.data.SUPER_ADMIN ===true){
            localStorage.setItem("super_admin",true)
        }
        localStorage.setItem('agencies',JSON.stringify(response.data.AGENCIES));
        localStorage.setItem('agency_id',JSON.stringify(response.data.AGENCY_ID));
        localStorage.setItem("name",response.data.FULL_NAME);
        dispatch({type:'auth_token',payload:response.data})
    }




}
const updateToken = async () =>{
    const response = await InterceptorApi().post('/api/refreshToken',{
        token:localStorage.getItem('token')
    })
}
const getFullName = dispatch => ()=>{

    dispatch({type:'validateName',payload:localStorage.getItem("name")})
}
const checkAuth = dispatch=> () =>{
    const tokenString = localStorage.getItem("token");
    dispatch({type:'check_is_authenticated',payload:!!tokenString})
}
const setAuthenticatedFalse = dispatch=> () =>{
    dispatch({type:'check_is_authenticated',payload:false})
}
export const {Provider,Context} = createDataContext(
    AuthReducer,
    {authenticate,checkAuth,setAuthenticatedFalse,getFullName},
    []
)
