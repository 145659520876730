import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Context as PersonContext} from "../../../context/PersonContext";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function PersonDefault(props){

    const {state:{success,warning,singlePerson},createOrUpdatePerson,clearMessages} = useContext(PersonContext);
    const handleSubmitClick = () =>{
        createOrUpdatePerson(state);
    }

    const [state, setState] = useState({
        id:null,
        nationalId:null,
        fullName:null,
        emailAddress:null,
        fullAddress:null,
        phoneOne:null,
        phoneTwo:null,
        folderId:null,
        isSupplier:props.type ==='cabOwner' ? 1 : null,
        isDriver:props.type==='driver' ? 1 : null,
        comment:null
    });
    const handleChange = (k,v) =>{

        setState({
            ...state,
            [k]: v,
        });
    }
    useEffect(()=>{
        clearMessages();
    },[])
    useEffect(()=>{
        if((props.perdonId && singlePerson) || (singlePerson && singlePerson.id !== null)){
            setState({
                id:singlePerson.id,
                nationalId:singlePerson.nationalId,
                fullName:singlePerson.fullName,
                emailAddress:singlePerson.emailAddress,
                fullAddress:singlePerson.fullAddress,
                phoneOne:singlePerson.phoneOne,
                phoneTwo:singlePerson.phoneTwo,
                folderId:singlePerson.folderId,
                isSupplier:singlePerson.isSupplier,
                isDriver:singlePerson.isDriver,
                comment:singlePerson.comment
            });
        }
    },[singlePerson])
    return(
        <>
            {success  || warning  ?
                <Alert severity={success ? "success" : "warning"}>{success ? success : warning}</Alert>
                :<Grid item xs={12}>{props.title}</Grid>
            }
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={state.isSupplier}
                            onChange={e=>handleChange("isSupplier",e.target.checked === true ? 1 : 0)}
                            name="isSupplier"
                            color="primary"
                        />
                    }
                    label="ספק"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={state.isDriver}
                            onChange={e=>handleChange("isDriver",e.target.checked === true ? 1 : 0)}
                            name="isDriver"
                            color="Secondary"
                        />
                    }
                    label="נהג"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={state.nationalId === null || state.nationalId === ''}
                    size={'small'}
                    variant="outlined"
                    disabled={state.id !== null}
                    style = {{width: 400}}
                    value={state.nationalId}
                    helperText={state.nationalId === null || state.nationalId === '' ? 'תעודת זהות הינו שדה חובה':null}
                    onChange={e=>handleChange('nationalId',e.target.value)}
                    label="ת.זהות" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    value={state.fullName}
                    onChange={e=>handleChange('fullName',e.target.value)}
                    error={state.fullName === null || state.fullName === ''}
                    helperText={state.fullName === null || state.fullName === '' ? 'שם מלא הינו שדה חובה':null}
                    label="שם מלא" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    value={state.fullAddress}
                    onChange={e=>handleChange('fullAddress',e.target.value)}
                    error={state.fullAddress === null || state.fullAddress === ''}
                    helperText={state.fullAddress === null || state.fullAddress === '' ? 'כתובת הינו שדה חובה':null}
                    label="כתובת" />
            </Grid>


            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    value={state.phoneOne}
                    onChange={e=>handleChange('phoneOne',e.target.value)}
                    error={state.phoneOne === null || state.phoneOne === ''}
                    helperText={state.phoneOne === null || state.phoneOne === '' ? 'טלפון ראשון הינו שדה חובה':null}
                    label="טלפון ראשון" />
            </Grid>
            <Grid item xs={12}>
                <TextField size={'small'} variant="outlined" style = {{width: 400}} id="standard-basic" value={state.phoneTwo} onChange={e=>handleChange('phoneTwo',e.target.value)} label="טלפון שני" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    value={state.emailAddress}
                    onChange={e=>handleChange('emailAddress',e.target.value)}
                    label="כתובת email" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    value={state.comment}
                    onChange={e=>handleChange('comment',e.target.value)}
                    label="הערה" />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={() =>handleSubmitClick()} variant="contained" size ="small" style = {{width: 400}} fullWidth color="primary">
                    {props.isExist ? 'עדכן':'צור'}
                </Button>
            </Grid>

        </>
    )
}
