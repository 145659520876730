import React, {useContext, useEffect, useState} from 'react';
import {Context as VehiclesContext} from "../../../context/VehiclesContext";
import {Context as PaymentContext} from '../../../context/PaymentContext'
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ListItemText from "@material-ui/core/ListItemText";
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EventIcon from '@material-ui/icons/Event';
import SpeedIcon from '@material-ui/icons/Speed';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import Divider from "@material-ui/core/Divider";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import RoomIcon from '@material-ui/icons/Room';
import Typography from "@material-ui/core/Typography";
import PaymentComponent from "../payments-page/PaymentComponent";
import {Link} from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import {Context as NotificationContext} from "../../../context/NotificationContext";
import {vehicleLocations,vehicleStatuses} from '../../../helpers/convertedPaymentTypes'
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import Button from "@material-ui/core/Button";
import InterceptorApi from "../../../api/InterceptorApi";

const switchLink = (colData,id,pathPart) =>{
    if(!id)
        return 'אין מידע';

    return (
        <Typography variant="body2">
            <Link
                to={{pathname: `/${pathPart}/${id}`,state: {extraData:{id:id}}}}
                style={{ textDecoration: 'none',color:'blue'}}>
                {colData}
            </Link>
        </Typography>
    )
}
 const VehicleBook = () =>{
     const {state:{vBookEnlarged,vehicle},toggleVehicleBook,updateIsOwnerShip} = useContext(VehiclesContext);
     const {state:{paymentModeBdeal,paymentModeSdeal},setPaymentModeBuyVehicle,setPaymentModeSaleVehicle} = useContext(PaymentContext);
     const [isChecked,toggleChecked] = useState(false);
     const [isChecked2,toggleChecked2] = useState(false);
     const {pipeMessage} =useContext(NotificationContext);
     const changeVehicleBookComponent = (val) =>{
         toggleVehicleBook(val);
     }

    const paymentModeSet = (type) =>{
         if(type ==='bDeal')
             setPaymentModeBuyVehicle(true);
         else
             setPaymentModeSaleVehicle(true)
    }
    const sumPayments = (type) => {
        if(type==="seller" && vehicle.vehicleBook.saleDeal.payments){
            return vehicle.vehicleBook.saleDeal.payments.reduce((a, b) => ({amount: a.amount + b.amount}), {amount: 0});
        }
        else if(type==="buyer" && vehicle.vehicleBook.buyDeal.payments){
            return vehicle.vehicleBook.buyDeal.payments.reduce((a, b) => ({amount: a.amount + b.amount}), {amount: 0});
        }
        return {amount:0};
    }
    const [state,setState] = useState({
        bDeal:{
            id:null,
            dealPerson:'--',
            dealName:'--',
            purchaseCost:'--',
            buyContract:'--',
            dealStatus:'--',
            createdOn:'--',
            paymentType:'--',
            sumPayments:'--',
            signDate:'--',
            isOwnerShipUpdatedFromSellerToAgency:'--'
        },
        sDeal:{
            id:null,
            dealPerson:'--',
            saleCost:'--',
            vat:'--',
            saleContract:'--',
            dealStatus:'--',
            createdOn:'--',
            paymentType:'--',
            sumPayments:'--',
            signDate:'--',
            purchaseCost:'--',
            isOwnerShipUpdatedFromAgencyToClient:'--'
        },

        bookStatus:'--',
        vLocation:'--',
        vAgencyLocated:'--',
        receiptDate:'--',
        deliverDate:'--',
        kilometerOnReceiptDate:'--',
        kilometerOnDeliverDate:'--',
        vehicleAcceptanceCertificate:null,
        vehicleDeliveryCertificate:null
    });
    useEffect(()=> {
        console.log('321321132');console.log(vehicle);console.log('321321132');
        let bDeal = {...state.bDeal};
        let sDeal = {...state.sDeal};

        if (vehicle && vehicle.vehicleBook && vehicle.vehicleBook.buyDeal) {
            const buyDeal = {...vehicle.vehicleBook.buyDeal}
            const conBdate = buyDeal.buyContract && buyDeal.buyContract.signDate ? buyDeal.buyContract.signDate : '--';
            bDeal = {
                id: buyDeal.id,
                dealPerson: buyDeal.dealPerson,
                signDate: conBdate,
                purchaseCost: buyDeal.purchaseCost,
                sumPayments: sumPayments("buyer").amount,
                isOwnerShipUpdatedFromSellerToAgency: buyDeal.isOwnerShipUpdatedFromSellerToAgency === 1 ? 'בוצע' : 'טרם בוצע'
            }
            toggleChecked(buyDeal.isOwnerShipUpdatedFromSellerToAgency);
        }
        if (vehicle && vehicle.vehicleBook && vehicle.vehicleBook.saleDeal) {
            const saleDeal = {...vehicle.vehicleBook.saleDeal};
            const conSdate = saleDeal.saleContract && saleDeal.saleContract.signDate ?  saleDeal.saleContract.signDate:'--'
            sDeal = {
                id: saleDeal.id,
                dealPerson: saleDeal.dealPerson,
                signDate: conSdate ,
                purchaseCost: saleDeal.saleCost,
                sumPayments: sumPayments("seller").amount,
                isOwnerShipUpdatedFromAgencyToClient: saleDeal.isOwnershipUpdatedFromAgencyToClient === 1 ? 'בוצע' : 'טרם בוצע'
            }
            toggleChecked2(saleDeal.isOwnershipUpdatedFromAgencyToClient);
        }
        if (vehicle && vehicle.vehicleBook){
            setState((prevState) => {
                return {
                    prevState,
                    bDeal,
                    sDeal,
                    bookStatus: vehicle.vehicleBook.vehicleStatus ? vehicleStatuses(vehicle.vehicleBook.vehicleStatus) : '--',
                    vLocation: vehicle.vehicleBook.vehicleLocation ? vehicleLocations(vehicle.vehicleBook.vehicleLocation)  : '--',
                    vAgencyLocated: vehicle.vehicleBook.agencyId ? getAgency(vehicle.vehicleBook.agencyId) : '--',
                    receiptDate:vehicle.vehicleBook.receiptDateVehicle ? vehicle.vehicleBook.receiptDateVehicle :'--',
                    deliverDate:vehicle.vehicleBook.deliverDateVehicle ? vehicle.vehicleBook.deliverDateVehicle :'--',
                    kilometerOnReceiptDate:vehicle.vehicleBook.kilometerOnReceiptDate ? vehicle.vehicleBook.kilometerOnReceiptDate : '--',
                    kilometerOnDeliverDate:vehicle.vehicleBook.kilometerOnDeliverDate ? vehicle.vehicleBook.kilometerOnDeliverDate : '--',
                    lastTreatDate:vehicle.vehicleBook.kilometerOnDeliverDate ? vehicle.vehicleBook.kilometerOnDeliverDate : '--',
                    lastTreatCost:vehicle.vehicleBook.kilometerOnDeliverDate ? vehicle.vehicleBook.kilometerOnDeliverDate : '--',
                    vehicleAcceptanceCertificate:vehicle.vehicleBook.vehicleAcceptanceCertificate,
                    vehicleDeliveryCertificate:vehicle.vehicleBook.vehicleDeliveryCertificate

                }
            })
        }
    },[vehicle])
    const getAgency =(agencyId) =>{
        const agencies = JSON.parse(localStorage.getItem('agencies'));
        console.log(agencies[agencyId],agencyId);
        return agencies[agencyId]

    }
        const toggleCheckedFunc = (e,type) =>{
            let id;
            if(type==='bDeal'){
                toggleChecked(e.target.checked);
                id = state.bDeal.id;
                updateIsOwnerShip(e.target.checked,id,type,vehicle);
                pipeMessage('פעולה נשמרה בהצלחה וכעת בעלות על הרכב היא עש החברה')
            }else{
                toggleChecked2(e.target.checked);
                id =  state.sDeal.id;
                updateIsOwnerShip(e.target.checked,id,type,vehicle);
                pipeMessage('פעולה נשמרה בהצלחה וכעת בעלות על הרכב היא עש רוכש הרכב')
            }

        }

     const displayContract = (type) =>{
             if( (type==="buyVehicleContract"  && !vehicle && !vehicle.vehicleBook && !vehicle.vehicleBook.buyDeal  && !vehicle.vehicleBook.buyDeal.buyContract) ||
                 (type==="saleVehicleContract" && !vehicle && !vehicle.vehicleBook && !vehicle.vehicleBook.saleDeal && !vehicle.vehicleBook.saleDeal.saleContract)){
                 return;
             }

             let contractId;
             let link;
             let dId;
             if(type ==="buyVehicleContract"){
                 dId =vehicle.vehicleBook.buyDeal.id;
                 contractId = vehicle.vehicleBook.buyDeal.buyContract.id;
                 link ='getVehicleDocument'
             }else if(type ==="saleVehicleContract"){
                 dId = vehicle.vehicleBook.saleDeal.id;
                 contractId = vehicle.vehicleBook.saleDeal.saleContract.id;
                 link ='getVehicleDocument'
                    console.log(vehicle)
             }else if(type==="vehicleAcceptanceCertificate"){
                 contractId = vehicle.vehicleBook.buyDeal.buyContract.id;
                 dId = vehicle.vehicleBook.buyDeal.id;
                 link ='getVehicleDocument'
             }else if(type==="vehicleDeliveryCertificate"){
                 contractId = vehicle.vehicleBook.saleDeal.saleContract.id;
                 dId = vehicle.vehicleBook.saleDeal.id;
                 link ='getVehicleDocument'
             }
             const f= InterceptorApi().get(`/api/${link}?vId=${vehicle.id}&cId=${contractId}&dId=${dId}&dType=${type}`,{responseType: 'blob'})
             f.then((res) =>{
                 if(!res.data){
                     alert('לא מצאנו חוזה במערכת. נא פנה לתמיכה');
                     return;
                 }
                 const file = new Blob([res.data], {type: 'application/pdf'});
                 const fileURL = URL.createObjectURL(file);
                 window.open(fileURL);
             })
     }
    return(
        <>
            {
                paymentModeBdeal ?
                    <PaymentComponent
                         nextPayment={moment().format('YYYY-MM-DD')}
                         entity ={'BUY-DEAL-PAYMENT'}
                         paymentDirection={'OUTCOME'}
                         paymentAmount={(vehicle.vehicleBook.buyDeal.purchaseCost - sumPayments("buyer").amount) < 0 ? 0 : (vehicle.vehicleBook.buyDeal.purchaseCost - sumPayments("buyer").amount)}
                         paymentType={'CREDIT_CARD'}
                         dealId={vehicle.vehicleBook.buyDeal.id}
                         fullPrice={vehicle.vehicleBook.buyDeal.purchaseCoste}
                         conStatus={vehicle.vehicleBook.buyDeal.dealStatus}
                         tableTitle={'היסטוריית תשלומים לבעל הרכב'}
                         title={'תשלום למוכר הרכב'}
                         payments={vehicle.vehicleBook.buyDeal.payments}

                         vat={0.00}
                    />
                :
                    paymentModeSdeal ?
                        <PaymentComponent
                            nextPayment={moment().format('YYYY-MM-DD')}
                            entity ={'SALE-DEAL-PAYMENT'}
                            paymentDirection={'INCOME'}
                            paymentAmount={(vehicle.vehicleBook.saleDeal.purchaseCost - sumPayments("buyer").amount) < 0 ? 0 : (vehicle.vehicleBook.buyDeal.purchaseCost - sumPayments("buyer").amount)}
                            paymentType={'CREDIT_CARD'}
                            dealId={vehicle.vehicleBook.saleDeal.id}
                            fullPrice={vehicle.vehicleBook.saleDeal.purchaseCoste}
                            conStatus={vehicle.vehicleBook.saleDeal.dealStatus}
                            tableTitle={'היסטוריית תשלומים של רוכש הרכב'}
                            title={'תקבולי רוכש'}
                            payments={vehicle.vehicleBook.saleDeal.payments}

                            vat={0.17}
                        />
                    :
                <Grid container justify="center" spacing={3}  >
                        <Grid item xs={4}>ספר רכב</Grid>

                        <Grid item xs={8}>
                            <Grid
                                spacing={1}

                                display="flex"
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                justify="flex-end"
                            >

                                {vBookEnlarged && vBookEnlarged === true ?
                                    <>
                                        <Grid item xs={2}>
                                            <Button disabled={state.bDeal.buyContract ==='--'} onClick={()=>displayContract("buyVehicleContract")} color="primary">חוזה קניית רכב</Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button disabled={state.sDeal.saleContract ==='--'} onClick={()=>displayContract("saleVehicleContract")} color="secondary">חוזה מכירת רכב</Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button disabled={!state.vehicleAcceptanceCertificate} onClick={()=>displayContract("vehicleAcceptanceCertificate")} color="info">אישור קליטת רכב</Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button disabled={!state.vehicleDeliveryCertificate} onClick={()=>displayContract("vehicleDeliveryCertificate")} color="danger">אישור מסירת רכב</Button>
                                        </Grid>
                                    </>:null
                                }

                                <Grid item xs={1}>
                                    <Tooltip title= {vBookEnlarged && vBookEnlarged === true ? 'הקטן ספר רכב':'הרחב ספר רכב'} aria-label="add">
                                        <IconButton
                                            size="small"
                                            onClick={()=>changeVehicleBookComponent(!(vBookEnlarged && vBookEnlarged === true))}
                                        >
                                            {
                                                vBookEnlarged && vBookEnlarged === true ?
                                                    <FullscreenExitIcon  />
                                                    :
                                                    <FullscreenIcon fontSize="small" />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}><Divider /></Grid>
                        {vBookEnlarged && vBookEnlarged === true?

                            <>
                                <Grid item xs={3} >

                                    <List dense={true}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <DoneIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.bookStatus}
                                                secondary={'סטטוס'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <RoomIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.vLocation}
                                                secondary={'מיקום הרכב'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CenterFocusWeakIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={ state.vAgencyLocated}
                                                secondary={'סוכנות'}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={3} >

                                    <List dense={true}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PersonOutlineIcon color={'primary'} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    switchLink(state.bDeal.dealPerson.fullName,state.bDeal.dealPerson.id,'people')
                                                }
                                                secondary={'מוכר הרכב'}
                                            />
                                        </ListItem>
                                        <ListItem
                                            color={'primary'}
                                            button
                                            disabled={!vehicle.vehicleBook || !vehicle.vehicleBook.buyDeal }
                                            onClick={(event) =>paymentModeSet('bDeal', 0)}
                                        >
                                            <ListItemIcon color={'primary'}>
                                                <AddIcon color={'primary'} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography variant="body1" style={state.bDeal.id !=null ? { color: 'blue' }:{ color: 'grey' }}>{'תשלום למוכר הרכב'}</Typography>}
                                                 secondary={'תיעוד תשלום'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <EventIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.bDeal.signDate}
                                                secondary={'תאריך קניה'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <KeyboardArrowLeftIcon  />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.bDeal.purchaseCost + " ₪"}
                                                secondary={'מחיר קניה'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <DoneIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.bDeal.sumPayments + " ₪"}
                                                secondary={'שולם סה"כ'}
                                            />
                                        </ListItem>

                                        <ListItem>
                                            <ListItemIcon >
                                                <Checkbox
                                                    disabled={isChecked }
                                                    color="primary"
                                                    edge="start"
                                                    checked={isChecked}
                                                    onChange={(e) =>toggleCheckedFunc(e,"bDeal")}
                                                    size={'small'}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.bDeal.isOwnerShipUpdatedFromSellerToAgency}
                                                secondary={'העברת בעלות'}
                                            />
                                        </ListItem>

                                    </List>
                                </Grid>
                                <Grid item xs={3} >
                                    <List dense={true}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PersonOutlineIcon color={'primary'} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={ state.sDeal && state.sDeal.dealPerson ?
                                                    switchLink(state.sDeal.dealPerson.fullName,state.sDeal.dealPerson.id,'people')
                                                    :'אין מידע'
                                                }
                                                secondary={'קונה הרכב'}
                                            />
                                        </ListItem>

                                        <ListItem
                                            color={'primary'}
                                            button
                                            disabled={!vehicle.vehicleBook || !vehicle.vehicleBook.saleDeal}
                                            onClick={(event) =>paymentModeSet('sDeal', 0)}
                                        >
                                            <ListItemIcon color={'primary'}>
                                                <AddIcon color={'primary'} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography variant="body1" style={state.sDeal.id !=null ? { color: 'blue' }:{ color: 'grey' }}>{'קבלת תשלום מהקונה'}</Typography>}
                                                secondary={'תיעוד תקבולים'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <EventIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.sDeal.signDate}
                                                secondary={'תאריך מכירה'}
                                            />
                                        </ListItem>

                                        <ListItem>
                                            <ListItemIcon>
                                                <KeyboardArrowLeftIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.sDeal.purchaseCost + " ₪"}
                                                secondary={'מחיר מכירה'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <DoneIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.sDeal.sumPayments + " ₪"}
                                                secondary={'נתקבל סה"כ'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon >
                                                <Checkbox
                                                    color="primary"
                                                    edge="start"
                                                    disabled={isChecked2 || !isChecked || !vehicle.vehicleBook.saleDeal}
                                                    checked={isChecked2}
                                                    onChange={(e) =>toggleCheckedFunc(e,"sDeal")}
                                                    size={'small'}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.sDeal.isOwnerShipUpdatedFromAgencyToClient}
                                                secondary={'העברת בעלות'}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={3} >
                                    <List dense={true}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <EventIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.receiptDate}
                                                secondary={'תאריך קליטה'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <EventIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.deliverDate}
                                                secondary={'תאריך מסירה'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ClearAllIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.kilometerOnReceiptDate}
                                                secondary={'ק"מ במועד קליטה'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ClearAllIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.kilometerOnDeliverDate}
                                                secondary={'ק"מ במועד מסירה'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <EventIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.lastTreatDate}
                                                secondary={'תאריך טיפול אחרון'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <SpeedIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={state.lastTreatCost+ " ₪"}
                                                secondary={'עלות טיפול אחרון'}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={12} >
                                    <Grid container justify="center" justifyContent="center">
                                        <List dense={true}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <DoneIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={state.bookStatus}
                                                    secondary={'סטטוס'}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <RoomIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={state.vLocation}
                                                    secondary={'מיקום הרכב'}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <CenterFocusWeakIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={ state.vAgencyLocated}
                                                    secondary={'שם הסוכנות'}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <EventIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={state.receiptDate}
                                                    secondary={'תאריך קליטה בסוכנות'}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <EventIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={state.deliverDate}
                                                    secondary={'תאריך מסירה ללקוח'}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <ClearAllIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={state.kilometerOnReceiptDate}
                                                    secondary={'ק"מ במועד קבלת הרכב'}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <DoneIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={state.bDeal.purchaseCost + " ₪"}
                                                    secondary={'מחיר קניה'}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <AddIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={state.bDeal.sumPayments + " ₪"}
                                                    secondary={'שולם סה"כ'}
                                                />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
            }
        </>
    )
}
export default VehicleBook;
