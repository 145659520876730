import React, {useContext, useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

import IconButton from "@material-ui/core/IconButton";
import {Context as VehicleContractContext} from "../../context/VehicleContractContext";
import {Context as FileUploaderContext} from "../../context/FileUploaderContext"
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {Context as VehiclesContext} from "../../context/VehiclesContext";
import {Context as SingleCabContext} from "../../context/SingleCabContext";
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    paper2:{
        padding: theme.spacing(2),
        width: "100%",
        height: "100%",
    },
    dropzone:{
        border:'1px dashed #139CFF',
        borderRadius:'5px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
        height:'100%',
        minHeight:'150px',
        maxHeight:'150px',
        minWidth:'150px',
        maxWidth:'150px',
        margin:'3px',
        "&:hover": {
            backgroundColor:'#f8fcfc'
        }
    },
    divider: {
        height: 28,
        margin: 4,
    },
    fileNameIconButton:{
        padding: 10,
    },
    fileNamePaper:{
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth:'150px',
    },
    fileNameInput:{
      //  marginLeft: theme.spacing(1),
        //flex: 1,
        maxWidth:'150px',
      //  margin:'3px'
    }

}));
const FileUploaderMap = (props) =>{
    const {state:{vehicle}} = useContext(VehiclesContext);
    const {state:{dataCab}} = useContext(SingleCabContext);
    const {state:{listOfImg,deleteMsg},uploadVehicleFile,getVehicleFiles,getCabFiles,updateVehicleFileName,deleteVehicleFileName} = useContext(FileUploaderContext);
    const [input,setInput] = useState([{fileType:props.docType}]);

    const classes = useStyles();
    useEffect(()=>{
        if(props.module==='vehicles' && vehicle)
            getVehicleFiles(vehicle.id);
    },[vehicle])

    useEffect(()=>{
        if(props.module==='dataCabs' && dataCab)
            getCabFiles(dataCab.id);
    },[dataCab])


    useEffect(()=>{
        if(listOfImg ){
            setInput(listOfImg.filter(e =>e.fileType ===props.docType));
        }
    },[listOfImg])
    const onInputChange =(element,index) =>{
        const copiedState =[...input];
        copiedState[index]._file = element;
        URL.revokeObjectURL(copiedState[index].objUrl);
        copiedState[index].objUrl = URL.createObjectURL(element);
        copiedState[index].originalName =element.name;
        setInput(copiedState);
        uploadVehicleFile(copiedState[index],index,props.docType,props.id,props.module);
    }
    const onFileNameChange =(value,index) =>{
        const copiedState = [...input];
        copiedState[index].fileViewName = value;
        setInput(copiedState);

    }
    const changeFileName = (element) =>{
        updateVehicleFileName(element)
    }
    const [open, setOpen] = useState(false);
    const handleClose = () => {setOpen(false);};
    const [indexToRemove,setIndexToRemove] = useState(null);
    const handleOpen = (index) =>{
        setIndexToRemove(index);
        setOpen(true);
    }

    const removeFile =(index) =>{
        setOpen(false)
        const copiedState = [...input];
        URL.revokeObjectURL(copiedState[index].objUrl);
        deleteVehicleFileName(copiedState[index]);
        copiedState.splice(index,1);
        if(copiedState.length ===0)
            setInput([{fileType:props.docType}]);
        else
            setInput(copiedState);
    }
    const addFields = () => {
        const newArray = [...input];
        let newField = {fileType:props.docType}
        newArray.push(newField);
        setInput(newArray);
    }
    const handleDragOver =(event) =>{
        event.preventDefault();
        event.stopPropagation();

    }
    const handleDrop =(event,index) =>{
        event.preventDefault();
        event.stopPropagation();
        onInputChange(event.dataTransfer.files[0],index);
    }

    const [enlarged,setEnlarged] = useState();
    const [imgIndex,setImgIndex] = useState();
    const enlargeImage = (index) =>{
        setImgIndex(index);
        setEnlarged(true);
    }
    return(
        <>
        <Paper className={classes.paper2} variant="outlined">
            <>
                <Grid spacing={2} container direction={'row'}>
                    <Grid item xs={12} >
                        {props.title}
                    </Grid>
                    <Grid item xs={12} >
                        <Divider />
                    </Grid>

                {input?.map((element, index) =>{
                        return (
                            <>

                                <Grid item xs={4} >
                                    <TextField
                                        size={'small'}
                                        variant="outlined"
                                        value={input[index].fileViewName ? input[index].fileViewName : input[index].originalName}
                                        onChange={e =>onFileNameChange(e.target.value,index)}
                                        style = {{maxWidth:'150px',margin:'3px'}}
                                        label="שם הקובץ"
                                        disabled={!input[index].fileViewName}
                                    />
                                    {input[index].fileViewName ?
                                        <Button  onClick={() =>changeFileName(input[index])} color="primary" aria-label="upload picture" size={'small'}>
                                            שנה
                                        </Button>:null
                                    }
                                    <div
                                        className={classes.dropzone}
                                        onDragOver={e =>handleDragOver(e)}
                                        onDrop={e=>handleDrop(e,index)}
                                    >


                                        {input && input[index] && input[index].objUrl ?
                                            <img
                                                src={input[index].objUrl}
                                                alt={'nothing'}
                                                width="150"
                                                height="150"
                                            />
                                            :
                                            <>
                                                <Typography variant="body1">גרור קובץ לכאן</Typography>
                                                <Typography variant="body1">או</Typography>
                                                <input
                                                    key={`${props.docType}-${index}`}
                                                    data-id={`ki-${index}`}
                                                    onChange={e =>onInputChange(e.target.files[0],index)}
                                                    accept="image/*"
                                                    className={classes.input}
                                                    id={`${props.docType}-${index}`}
                                                    type="file"
                                                />
                                                <label htmlFor={`${props.docType}-${index}`}>
                                                    <Button disableElevation component="span" variant="contained" color="primary" size={'small'} style={{justifyContent:'center',display:'flex'}} startIcon={<CloudUploadIcon />}>
                                                        {'לחץ כאן'}
                                                    </Button>
                                                </label>
                                            </>
                                        }
                                    </div>

                                    <ButtonGroup size="small" variant="text" color="info">
                                        {input && input[index] && input[index].objUrl && input[index].id ?
                                            <IconButton onClick={()=>handleOpen(index)} aria-label="delete" className={classes.margin}>
                                                <DeleteIcon style={{fontSize:'20'}} />
                                            </IconButton>:null
                                        }

                                        {
                                            input[index] && input[index]._file ? <Button> {input[index]._file.name}</Button>
                                            :
                                            input[index] && input[index].originalName ? <Button> {input[index].originalName}</Button>
                                            :
                                            null
                                        }
                                        {input && input[index] && input[index].objUrl && input[index].id ?
                                            <IconButton onClick={()=>enlargeImage(index)} className={classes.margin}>
                                                <ZoomInIcon style={{fontSize:'20'}} />
                                            </IconButton>:null
                                        }
                                    </ButtonGroup>
                                </Grid>

                            </>
                        )
                    }
                )
                }
                </Grid>
            </>
                <Grid
                    container
                    direction={'row'}
                    justify={'center'}
                    alignContent={'center'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    spacing={2}
                >
                    <Grid item xs={2} >
                        <Button  variant="contained" color="info"
                                 style={{justifyContent:'center',display:'flex'}}
                                 onClick={addFields}
                                 startIcon={<AddCircleOutlineIcon />}
                            size={'small'}
                        >{'הוסף'}</Button>
                    </Grid>
                </Grid>
        </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="scroll-dialog-title">
                    {
                        input[indexToRemove]  && input[indexToRemove].fileViewName ? ` למחוק את  ${input[indexToRemove].fileViewName} ?` :deleteMsg
                    }

                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        בטל
                    </Button>
                    <Button onClick={() =>removeFile(indexToRemove)} color="primary">
                        אשר
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={enlarged}
                onClose={setEnlarged}
            >
                <DialogTitle id="scroll-dialog-title">{
                    input[imgIndex]  && input[imgIndex].fileViewName ? input[imgIndex].fileViewName : ''
                }</DialogTitle>
                <DialogContent>
                    <img
                        src={input && input[imgIndex] && input[imgIndex].objUrl ? input[imgIndex].objUrl : ''}
                        alt={'nothing'}
                        width="450"
                        height="450"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setEnlarged(false)} color="primary">
                        סגור
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default FileUploaderMap;
