import createDataContext from "./createDataContext";

const notificationReducer =(state,action) =>{
    switch (action.type) {
        case 'message_arrived':
            return{
                ...state,
                message:action.payload.message,
                severity:action.payload.severity,
                dummy: !state.dummy
            }
        default: return state;
    }
}
const pipeMessage = dispatch =>(message,severity) =>{
    severity = severity ? severity :"success";
    dispatch({type:'message_arrived',payload: {message, severity}})
}
export const {Provider,Context} = createDataContext(
    notificationReducer,
    {pipeMessage},
    []
)
