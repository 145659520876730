import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import ServerSideTable from "../../../components/ServerSideTable";
import Button from "@material-ui/core/Button";
import PersonDetails from "../people/PersonDetails";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
const switchLink = (colData,ownerId, linking) =>{
    return (
        <Typography variant="body2">
            <Link
                to={{pathname: `/${linking}/${colData}`,state: {extraData:{id: ownerId}}}}
                style={{ textDecoration: 'none',color:'blue'}}>{colData}
            </Link>
        </Typography>
    )
}
const renderRow=(val) =>{
    return <Typography variant="body2">{val}</Typography>
}

export default function CabsOwners(props) {

    const columns =[
        {
            title: 'שם',
            field: 'fullName',
            render: rowData => rowData && rowData.fullName ? switchLink(rowData.fullName,rowData.id,props.linking) : " "
        },
        {
            title: 'טלפון ראשי',
            field: 'phoneOne',
            render: rowData => rowData && rowData.phoneOne ? renderRow(rowData.phoneOne) : " "
        },
        {
            title: 'טלפון משני',
            field: 'phoneTwo',
            render: rowData => rowData && rowData.phoneTwo ? renderRow(rowData.phoneTwo) : renderRow('--')

        },
        {
            title: 'כתובת',
            field: 'fullAddress',
            render: rowData => rowData && rowData.fullAddress ? renderRow(rowData.fullAddress) : renderRow('--')

        },
        {
            title: 'דואר אלקטרוני',
            field: 'emailAddress',
            render: rowData => rowData && rowData.emailAddress ? renderRow(rowData.emailAddress) : renderRow('--')


        },
        {
            title: 'תאריך יצירה',
            field: 'createdOn',
            render:rowData =>moment(rowData.createdOn).format('YYYY-MM-DD HH:mm:ss')

        },
        {
            title: 'הערות',
            field: 'comment'
        },
    ]
    const [state, setState] =useState(false);
    const addNewPersonForm =(val) => setState(val);
    return (
        <>
        <Grid style={{marginBottom:'5px'}} container justify="flex-end" item xs={12}>
            <Button onClick={() =>addNewPersonForm(true)} variant="contained" color="primary" size={'small'} >
                הוסף חדש
            </Button>
        </Grid>
        <Card>
            <CardContent>
                {state === true ?
                    <Grid item xs={2}>
                        <IconButton color="primary" onClick={() =>addNewPersonForm(false)}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </Grid>
                    :
                    null
                }

                {state === false ?
                    <Grid style={{marginTop:'5px'}} item xs={12}>
                        <ServerSideTable initialFilter={props.tableFilter} title={props.title} columns={columns} url={'findPersons'}/>
                    </Grid>
                    :
                    <Grid
                        spacing={3}
                        display="flex"
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        justify="center"
                    >
                        <PersonDetails title={'הוסף בעל זכות'} type={'cabOwner'} />
                    </Grid>
                }
            </CardContent>
        </Card>
        </>
    );
}
