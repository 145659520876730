import React, {useContext, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SignatureCanvas from "./SignatureCanvas";


export default function LocalSignatureDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [state,setState] = useState({resMsg:null});
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setState({resMsg: null})
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                מגש חתימה
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{ props.conTitle ? props.conTitle : 'חתימה על חוזה'}</DialogTitle>
                <DialogContent>
                    <SignatureCanvas conType={props.conType}  />
                </DialogContent>

                <DialogActions>
                    <Button size="small" variant="outlined" onClick={handleClose} >
                        סגור
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
