import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Context as SingleCabContext} from "../../../context/SingleCabContext";
import IconButton from "@material-ui/core/IconButton";
import StopIcon from "@material-ui/icons/Stop";
import {red} from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {Context as NotificationContext} from "../../../context/NotificationContext";
export default function FormDialogCloseContract (props){
    const conId = props.contractId ? props.contractId : props.props.con.id;
    const [open, setOpen] = React.useState(false);
    const {state:{pipeNotifSuccess,pipeNotifFail},endContractGeneric} = useContext(SingleCabContext);
    const {pipeMessage} = useContext(NotificationContext);
    useEffect(()=>{
        if(pipeNotifSuccess)
            pipeMessage('פעולה נשמרה בהצלחה ')
    },[pipeNotifSuccess])
    useEffect(()=>{
        if(pipeNotifFail)
            pipeMessage('משהו השתבש, אנא נסה שנית ','error')
    },[pipeNotifFail])

    const handleClose = () => {
        setOpen(false);
    };

    const handleDateChange = (date) => {

        setSelectedDate(moment(date).format("YYYY-MM-DD HH:mm:ss"));
    };
    const closeContractGeneric =() =>{
        setOpen(false);
        endContractGeneric(props.props.con.cabsId,conId,selectedDate);
    }
    const [selectedDate, setSelectedDate] = React.useState(moment().format("YYYY-MM-DD HH:mm:ss"));
    return (
        <>
            <Tooltip title="עצור חוזה" aria-label="add">
                <IconButton
                    size="small"
                    aria-label="delete"
                    onClick={()=>setOpen(true)}
                    disabled={!(props.props.con && props.props.con.status && (props.props.con.status !== 'ACTIVE' || props.props.con.status !== 'FUTURE'))}
                >
                    <StopIcon fontSize="small"
                              style={props.props.con && props.props.con.status && props.props.con.status === 'ACTIVE' ? { color: red[500] } : null} />
                </IconButton>
            </Tooltip>
            <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.content + ' ניתן לקבוע מועד עתידי לסיום חוזה '}

                    </DialogContentText>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="מועד סיום חוזה"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                    </MuiPickersUtilsProvider>
                </DialogContent>

                <DialogActions>
                    <Button


                        onClick={()=>closeContractGeneric()}
                        color="primary"
                    >
                        בצע
                    </Button>
                    <Button onClick={handleClose} >
                        סגור
                    </Button>


                </DialogActions>
            </Dialog>

        </>
    )
}
