import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi';

const personReducer = (state,action) =>{
    switch (action.type) {
        case 'persons_list':
            return{
                ...state,
                persons:action.payload
            }
            case 'selected_person':
            return{
                ...state,
                selectedPerson:action.payload
            }
            case 'selected_person_for_add_vehicle':
            return{
                ...state,
                selectedPersonForVehicle:action.payload
            }
            case 'selected_person_for_contract':
            return{
                ...state,
                selectedPersonForContract:action.payload
            }
            case 'persons_table':

            return{
                ...state,
                personsTable:action.payload.data,
                totalCount:action.payload.totalCount
            }
        case 'success_person_set':

            return{
                ...state,

                singlePerson:action.payload.person,
                success :action.payload.msg
            }
        case 'im_in_use_msg':
            return{
                ...state,
                warning:action.payload,

            }
        case 'clear_messages':
            return{
                ...state,
                warning:null,
                success:null
            }
        case 'single_person':
            return{
                ...state,
                singlePerson:action.payload,
            }
        case 'update_person':
            return{
                ...state,
                selectedPersonForContract:action.payload,
            }
        case 'selected_person_for_sale_vehicle_contract':
            return{
                ...state,
                selectedPersonForSaleVehicleContract:action.payload,
            }
            case 'clear_person_object':
            return{
                ...state,
                selectedPersonForSaleVehicleContract:null,
                selectedPersonForContract:null,
                selectedPersonForVehicle:null,
                selectedPerson:null,
                singlePerson:null,
            }

        default: return null
    }
}
const clearPersonObject = dispatch =>async() =>{
    dispatch({type: 'clear_person_object', payload:null });
}
const updatePersonBasicDetails = dispatch =>async(conType,personData) =>{
    const response = await InterceptorApi().put('/api/updatePersonFromContract',personData);
    if(response.status === 226){
        alert('תעודת זהות זו משוייכת לאדם אחר');
        dispatch({type: 'im_in_use_msg', payload:'תעודת זהות זו משוייכת לאדם אחר' });
    }
    if(response.status === 200){
        alert('העדכון בוצע בהצלחה');
        if(conType ==='saleVehicle')
            dispatch({type: 'update_person', payload:response.data });
    }
}
const setPersonForSaleContract = dispatch =>(obj) =>{
    dispatch({type:'selected_person_for_sale_vehicle_contract',payload:obj});
}
const findPersonById =dispatch =>async(id) =>{
    const response = await InterceptorApi().get(`/api/findPersonById/${id}`);
    dispatch({type:'single_person',payload:response.data});
}

const clearMessages =dispatch =>() =>{
    dispatch({type: 'clear_messages', payload:null });
}
const createOrUpdatePerson = dispatch =>async(personData) =>{
    const response = await InterceptorApi().post('/api/createPerson',personData);
    if(response.status === 226){
        dispatch({type: 'im_in_use_msg', payload:'תעודת זהות זו משוייכת לאדם אחר' });
    }
    if(response.status === 200){
        dispatch({type: 'success_person_set', payload: {person:response.data,msg:personData.id !== null ? 'התעדכן בהצלחה' : 'נוצר בהצלחה'} });
    }
}

const findPersonByMultipleValues = dispatch => async (value) =>{
    const response = await InterceptorApi().get(`/api/findPersonByMultipleValues/${value}`);
    dispatch({type:'persons_list',payload:response.data});
}
const setPerson = dispatch =>(objectItem) =>{
    dispatch({type:'selected_person',payload:objectItem});
}
const setPersonForContract = dispatch =>(objectItem) =>{
    dispatch({type:'selected_person_for_contract',payload:objectItem});
}
const setPersonForVehicle = dispatch =>(objectItem) =>{
    dispatch({type:'selected_person_for_add_vehicle',payload:objectItem});
}

const findAllPersons = dispatch =>async(url) =>{
    const response = await InterceptorApi().get('/api'+url);
    dispatch({type:'persons_table',payload: {data:response.data.data,totalCount:response.data.totalCount}});
}

export const {Provider, Context} = createDataContext(
    personReducer,
    {clearPersonObject,findPersonByMultipleValues,setPerson,findAllPersons,createOrUpdatePerson,updatePersonBasicDetails,
        clearMessages,findPersonById,setPersonForVehicle,setPersonForContract,setPersonForSaleContract},
    []
)
