import React, {useContext, useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import FileUploaderMap from "./FileUploaderMap";
import {Context as NotificationContext} from "../../context/NotificationContext";
import {Context as FileUploaderContext} from "../../context/FileUploaderContext";
const FileUploader = (props) =>{

    const {pipeMessage} =useContext(NotificationContext);
    const {state:{pipeFailed,message,typeError}} = useContext(FileUploaderContext);
    useEffect(()=>{
        if(pipeFailed)
            pipeMessage(message,typeError);
    },[pipeFailed])
    return(
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FileUploaderMap module={props.module} id={props.id} docType ='vImg' title={'תמונות'} />
                </Grid>
                <Grid item xs={6}>
                    <FileUploaderMap module={props.module} id={props.id} docType ='vDocs' title={'מסמכים'} />
                </Grid>
            </Grid>
        </>
    )
}
export default FileUploader;
