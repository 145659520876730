import React from "react";
import StepperComponent from "./StepperComponent";



const CreateContractComponent =(props) =>{
    return(
         <StepperComponent source ={props.source} />
    )
}
export default CreateContractComponent
