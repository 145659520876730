import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Context as PersonContext} from "../context/PersonContext";


export default function SearchPerson(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const {state:{persons},findPersonByMultipleValues,setPerson,setPersonForVehicle,setPersonForContract,setPersonForSaleContract} = useContext(PersonContext);

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if(!persons)
            await findPersonByMultipleValues('א');

            if (active && persons) {
                setOptions([...persons]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading,persons]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);
    const onSetAutoCompleteValue = async (e) =>{

        if(props.source && props.source === 'addVehicle'){
            setPersonForVehicle(e);
        }else if(props.source && props.source === 'newContract'){
            setPersonForContract(e);
        }
        else if(props.source && props.source === 'saleVehicle'){
            setPersonForSaleContract(e);
        }
        else{
            setPerson(e);
        }

    }
    const onChangeHandle = async (value) => {
        if(value.length >0){
          findPersonByMultipleValues(value);
          setOptions([...persons]);
        }
    };

    const checkFit = (option,value) =>{
        return  option.Fullname === value.Fullname || option.nationalId === value.nationalId || option.phoneOne === value.phoneOne;
    }
    const filterOption = (scope,inputValue) =>{
       return scope && scope.toLowerCase().includes(inputValue)
    }
    return (
        <Autocomplete
            id="autocomplete-person"

            style={{ width: 300 }}
            open={open}
            size="small"
            onOpen={() => {setOpen(true);}}
            onClose={() => {setOpen(false);}}
            getOptionSelected={(option, value) => checkFit(option, value)}
            filterOptions={(options, { inputValue }) => options.filter(item =>filterOption(item.fullName,inputValue)  || filterOption(item.nationalId,inputValue) || filterOption(item.phoneOne,inputValue ))}
            getOptionLabel={(option) =>option.fullName}
            options={options}
            loading={loading}
            noOptionsText={'לא מצאנו אף אחד'}
            onChange={(e,v )=> onSetAutoCompleteValue(v)}
            renderOption={(option) => (
                <React.Fragment>
                    <span>{option ? option.fullName : ''}</span>
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="חפש מישהו"
                    variant="outlined"
                    onChange={ev => {
                        if (ev.target.value !== "" || ev.target.value !== null || true) {
                            onChangeHandle(ev.target.value);
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
