import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
    flicker: {
        animation: '$flickerAnimation 4s infinite',
    },
    '@keyframes flickerAnimation': {
        '0%': {
            opacity: 1,
        },
        '50%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
});

function FlickeringEffectIcon({ Icon, color,size,title }) {
    const classes = useStyles();
    const [state,setState] =useState(true);
    return (
        <>
            {
                state ?
                <Tooltip title={title} >
                    <IconButton size={size} onClick={()=>setState(false)} className={classes.flicker} style={{ color}}>
                        <Icon />
                    </IconButton>
                </Tooltip>
                :null
            }

        </>
    );
}

export default FlickeringEffectIcon;
