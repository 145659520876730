import React from 'react';

import ReactDOM from "react-dom";
import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import {Provider as SingleCabProvider} from './context/SingleCabContext'
import {Provider as AdminProvider} from './context/AdminContext'
import {Provider as CreateContractProvider} from './context/CreateContractContext'
import {Provider as SupplierProvider} from './context/SupplierContext';
import {Provider as DriverProvider} from "./context/DriverContext"
import {Provider as AuthProvider} from './context/AuthenticationContext';
import {Provider as CabsProvider} from './context/CabsContext';
import {Provider as PersonProvider} from './context/PersonContext';
import {Provider as VehicleProvider} from './context/VehiclesContext';
import {Provider as FileUploaderProvider} from './context/FileUploaderContext';
import {Provider as VehicleContractProvider} from './context/VehicleContractContext';
import {Provider as PaymentProvider} from './context/PaymentContext';
import {Provider as NotificationProvider} from './context/NotificationContext';
import { heIL } from "@material-ui/core/locale";
import {create} from "jss";
import rtl from 'jss-rtl';
import MainLayout from "./components/MainLayout";
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createMuiTheme({
    direction: "rtl", heIL
});

export default function App(){

    return(
        ReactDOM.render(
            <NotificationProvider>
            <VehicleProvider>
                <PaymentProvider>
                    <FileUploaderProvider>
                        <CabsProvider>
                            <VehicleContractProvider>
                                    <AdminProvider>
                                        <DriverProvider>
                                            <SupplierProvider>
                                                <CreateContractProvider>
                                                    <SingleCabProvider>
                                                        <StylesProvider jss={jss}>
                                                            <PersonProvider>
                                                                <ThemeProvider theme={theme}>
                                                                    <AuthProvider>
                                                                        <MainLayout />
                                                                    </AuthProvider>
                                                                </ThemeProvider>
                                                            </PersonProvider>
                                                        </StylesProvider>
                                                    </SingleCabProvider>
                                                </CreateContractProvider>
                                            </SupplierProvider>
                                        </DriverProvider>
                                    </AdminProvider>
                            </VehicleContractProvider>
                        </CabsProvider>
                    </FileUploaderProvider>
                </PaymentProvider>
            </VehicleProvider>
            </NotificationProvider>
            ,
            document.querySelector("#root")
        )
    )

}


