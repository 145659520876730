import React, {useContext, useEffect, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SearchPerson from "../../../../components/SearchPerson";
import {Context as PersonContext} from "../../../../context/PersonContext";
import {Context as VehiclesContext} from "../../../../context/VehiclesContext"
import {Context as VehicleContractContext} from "../../../../context/VehicleContractContext";
import Button from "@material-ui/core/Button";


const ContractPerson = (props) =>{

   const {state:{selectedPersonForSaleVehicleContract},updatePersonBasicDetails} = useContext(PersonContext);
   const {state:{vehicle},fetchVehicleByCarNumber} = useContext(VehiclesContext);
   const {setPersonIdBuyer,setPersonIdSeller} = useContext(VehicleContractContext);

    let PersonObj;
    switch (props.contractType) {
        case 'buyVehicle':
            PersonObj ={
                id:vehicle.supplier.id,
                nationalId:vehicle.supplier.nationalId,
                fullName:vehicle.supplier.fullName,
                emailAddress:vehicle.supplier.emailAddress,
                fullAddress:vehicle.supplier.fullAddress,
                phoneOne:vehicle.supplier.phoneOne,
                phoneTwo:vehicle.supplier.phoneTwo,
            }
            break;
        case 'saleVehicle':
        default:
            PersonObj ={
                id:'',
                nationalId:'',
                fullName:'',
                emailAddress:'',
                fullAddress:'',
                phoneOne:'',
                phoneTwo:'',
            }
            break;
    }
    const [state, setState] = useState(PersonObj);

    useEffect(() => {
        if(selectedPersonForSaleVehicleContract && props.contractType ==='saleVehicle'){

            setPersonIdBuyer(selectedPersonForSaleVehicleContract.id);
            setState({
                id:selectedPersonForSaleVehicleContract.id,
                nationalId:selectedPersonForSaleVehicleContract.nationalId ? selectedPersonForSaleVehicleContract.nationalId : '',
                fullName:selectedPersonForSaleVehicleContract.fullName ? selectedPersonForSaleVehicleContract.fullName : '',
                emailAddress:selectedPersonForSaleVehicleContract.emailAddress ? selectedPersonForSaleVehicleContract.emailAddress : '',
                fullAddress:selectedPersonForSaleVehicleContract.fullAddress ? selectedPersonForSaleVehicleContract.fullAddress : '',
                phoneOne:selectedPersonForSaleVehicleContract.phoneOne ? selectedPersonForSaleVehicleContract.phoneOne : '',
                phoneTwo:selectedPersonForSaleVehicleContract.phoneTwo ? selectedPersonForSaleVehicleContract.phoneTwo : '',
            })
        }
        fetchVehicleByCarNumber(vehicle.carNumber);
    }, [selectedPersonForSaleVehicleContract]);

    useEffect(() => {

       if( props.contractType ==='buyVehicle') {
            setPersonIdSeller(vehicle.supplier.id);
        }
    }, []);
    const handleChange = (k,v) =>{
        setState({
            ...state,
            [k]: v,
        });
    }
    const handleSubmitClick = () =>{
        updatePersonBasicDetails(props.contractType,state);
    }

    return(
        <>
            <Grid container spacing={3}>
            {props.contractType ==='saleVehicle' ? <Grid item xs={12} ><SearchPerson source ={'saleVehicle'} /></Grid> : null}
            <Grid item xs={12}>
                <TextField
                    error={state.nationalId === null || state.nationalId === ''}
                    disabled={state.id}
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    id="standard-basic"
                    value={state.nationalId}
                  //  helperText={state.nationalId === null || state.nationalId === '' ? 'תעודת זהות הינו שדה חובה':null}
                    onChange={e=>handleChange('nationalId',e.target.value)}
                    label="ת.זהות" />

            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    id="standard-basic"
                    value={state.fullName}
                    onChange={e=>handleChange('fullName',e.target.value)}
                    error={state.fullName === null || state.fullName === ''}
               //     helperText={state.fullName === null || state.fullName === '' ? 'שם מלא הינו שדה חובה':null}
                    label="שם מלא" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    id="standard-basic"
                    value={state.fullAddress}
                    onChange={e=>handleChange('fullAddress',e.target.value)}
                    error={state.fullAddress === null || state.fullAddress === ''}
               //     helperText={state.fullAddress === null || state.fullAddress === '' ? 'כתובת הינו שדה חובה':null}
                    label="כתובת" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    id="standard-basic"
                    value={state.emailAddress}
                    onChange={e=>handleChange('emailAddress',e.target.value)}
                    label="כתובת email" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    id="standard-basic"
                    value={state.phoneOne}
                    onChange={e=>handleChange('phoneOne',e.target.value)}
                    error={state.phoneOne === null || state.phoneOne === ''}
                 //   helperText={state.phoneOne === null || state.phoneOne === '' ? 'טלפון ראשון הינו שדה חובה':null}
                    label="טלפון ראשון" />
            </Grid>
            <Grid item xs={12}>
                <TextField size={'small'} variant="outlined" style = {{width: 400}} id="standard-basic" value={state.phoneTwo} onChange={e=>handleChange('phoneTwo',e.target.value)} label="טלפון שני" />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={() =>handleSubmitClick()} variant="contained" size ="small" style = {{width: 400}} fullWidth color="secondary">
                    עדכן פרטים
                </Button>
            </Grid>
            </Grid>
        </>
    )
}
export default ContractPerson;
