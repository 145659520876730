import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi'
import moment from 'moment-es6';
const adminReducer = (state,action) =>{
    switch (action.type) {
        case 'companies':
            return{
                ...state,
                companies:action.payload,
                notify:null
            }
        case 'create_company':
            action.payload.agencies = [];
            action.payload.createdOn = moment().format("YYYY-MM-DD HH:mm:ss");
            const comp = state.companies;
            comp.push(action.payload)
            return{
                ...state,
                comp,
                resMessage:'ארגון נוצר בהצלחה'
            }
        case 'update_company':

            const newCompData = state.companies[action.payload.index].name = action.payload.data.name
            return{
                ...state,
                newCompData,
                resMessage:'ארגון התעדכן בהצלחה'
            }
        case 'delete_company':
            const copiedCompanies  = [...state.companies];
            copiedCompanies.splice(copiedCompanies.indexOf(copiedCompanies[action.payload.index]),1);
            return{
                ...state,
                companies:copiedCompanies,
                resMessage:'ארגון נמחק בהצלחה'
            }
        case 'new_agency':
            const agenciesData = state.companies[action.payload.CompanyIndex].agencies;
            action.payload.data.createdOn = moment().format("YYYY-MM-DD HH:mm:ss");
            action.payload.data.users =[];
            agenciesData.push(action.payload.data)
            return{
                ...state,
                agenciesData,
                resMessage:'סוכנות נוצרה בהצלחה'
            }
        case 'update_agency':
            const agencyData = state.companies[action.payload.companyIndex].agencies;
            agencyData[action.payload.agencyIndex].name = action.payload.data.name;
            return{
                ...state,
                agencyData,
                resMessage:'סוכנות עודכנה בהצלחה'
            }
            case 'delete_agency':
            const deletedAgency = state.companies[action.payload.companyIndex].agencies;
                deletedAgency.splice(deletedAgency.indexOf(deletedAgency[action.payload.agencyIndex]));
            return{
                ...state,
                deletedAgency,
                resMessage:'סוכנות הוסרה בהצלחה'
            }
        case 'new_user':
            const userData = state.companies[action.payload.company_index].agencies[action.payload.agency_index].users
            console.log('lllllllllllllll');console.log(userData);console.log('lllllllllllllll');
            action.payload.data.createdOn= moment().format("YYYY-MM-DD HH:mm:ss");
             userData.push(action.payload.data)

            return {
                ...state,
                userData,
                resMessage:'משתמש נוצר בהצלחה'
            }
            case 'new_user_success':
            case 'email_inuse_creation_aborted':
            return {
                ...state,
                resMessage:action.payload
            }
        case 'update_user':
            const userDataToUpdate = state.companies[action.payload.company_index].agencies[action.payload.agency_index].users;
            userDataToUpdate[action.payload.rowIndex] = action.payload.data
            return {
                ...state,
            userDataToUpdate,
            resMessage:'משתמש התעדכן בהצלחה'

            }
        case 'delete_user':
            const userDataToDelete = state.companies[action.payload.company_index].agencies[action.payload.agency_index].users;
            const copied = [...userDataToDelete];
            userDataToDelete.splice(userDataToDelete.indexOf(userDataToDelete[action.payload.rowIndex]),1);
            copied.splice(copied.indexOf(copied[action.payload.rowIndex]),1)
            return {
                ...state,
                copied,
                resMessage:'המשתמש נמחק בהצלחה'
            }
        case 'show_user_table':
            return {
                ...state,
                usersTable:action.payload
            }
        case 'dialog' :
            return {
                ...state,
                resMessage: action.payload
            }
        case 'company_signature_updated' :
            return {
                ...state,
                sucsessAddSignature: !state.sucsessAddSignature
            }
        case 'get_company_signature' :
            return {
                ...state,
                signatureImg: action.payload
            }

    }

}
const addCompanySignature = dispatch =>async(data) =>{
    const response = await InterceptorApi().post('/api/addCompanySignature', {signatureUrl:data});

    if(response.status ===200 && response.data.companySignaturePath !== null)
        dispatch({type: 'company_signature_updated', payload: response.data});
    else
        dispatch({type: 'company_signature_fail_update', payload: false});
}
const getCompanySignature = dispatch =>async(data) =>{
    const response = await InterceptorApi().post('/api/getCompanySignature', {name:data.name,companyGovId:data.companyGovId});
    dispatch({type: 'get_company_signature', payload: response.data});
}
const createCompany = dispatch =>async(data) =>{
    const response = await InterceptorApi().post('/api/createCompany', {name:data.name,companyGovId:data.companyGovId});
    dispatch({type: 'create_company', payload: response.data});
}
const updateCompany = dispatch =>async(name,id,index) =>{
    const response = await InterceptorApi().put('/api/updateCompany', {id:id,name:name})
    dispatch({type: 'update_company', payload: {data:response.data,index}})
}
const deleteCompany = dispatch =>async(name,id,index) =>{
    const response = await InterceptorApi().put('/api/updateCompanyCloseDate', {id:id,name:name});
    dispatch({type: 'delete_company', payload: {data:response.data,index}})
}
const getCompanies = dispatch =>async() =>{
    const  response = await InterceptorApi().get('/api/getCompanies')
    console.log('---%');console.log(response.data);console.log('%---');
    dispatch({type: 'companies', payload: response.data})
}
const createAgency = dispatch =>async(agencyName,companyId,CompanyIndex) =>{

    const response = await InterceptorApi().post('/api/createAgency',{company_id:companyId,name:agencyName})
    console.log(response)
    dispatch({type: 'new_agency', payload: {data:response.data,CompanyIndex}})

}
const updateAgency = dispatch =>async(name,agencyId,companyIndex,agencyIndex) =>{

    const response = await InterceptorApi().put('/api/updateAgency', {id:agencyId,name:name})
    dispatch({type: 'update_agency', payload: {data:response.data,companyIndex,agencyIndex}})
}
const deleteAgency = dispatch =>async(name,agencyId,companyIndex,agencyIndex) =>{
    const response = await InterceptorApi().put('/api/updateAgencyCloseDate', {id:agencyId,name:name});
    dispatch({type: 'delete_agency', payload: {data:response.data,companyIndex,agencyIndex}})
}

const createUser = dispatch =>async(data) =>{


    const response = await InterceptorApi().post('/api/createOrUpdateUser',{
        id:data.id,
        agency_id:data.agency_id,
        fullName:data.fullName,
        email:data.email,
        password:data.password,
        roles:data.roles,
        status: {id:1}
    });
    if(response.status===226){
        dispatch({type: 'email_inuse_creation_aborted', payload: "שם משתמש זה קיים במערכת"})

    }else{
        dispatch({type: 'new_user', payload: {data:response.data,agency_index:data.agencyIndex,company_index:data.companyIndex}})
        dispatch({type: 'new_user_success', payload: "משתמש נוצר בהצלחה"});
    }

}
const updateUser = dispatch =>async(data) =>{

    const response = await InterceptorApi().post('/api/createOrUpdateUser',{
        id:data.id,
        agency_id:data.agency_id,
        fullName:data.fullName,
        email:data.email,
        password:data.password,
        roles:data.roles,
        status: {id:1}
    });
    console.log(response);
    // dispatch({type: 'new_agency', payload: {data:response.data,indexNumber}})
    dispatch({type: 'update_user', payload: {data:response.data,agency_index:data.agencyIndex,company_index:data.companyIndex,rowIndex:data.rowIndex}});
    dispatch({type: 'dialog', payload:'המשתמש עודכן בהצלחה'})
}
const deleteUser = dispatch => async (data) =>{
    const response = await InterceptorApi().post('/api/updateUserCloseDate',{
        id:data.id,
    });
    dispatch({type: 'dialog', payload:null})
    dispatch({type: 'delete_user', payload: {data:response.data,agency_index:data.agencyIndex,company_index:data.companyIndex,rowIndex:data.rowIndex}});
}
const showUsersTable = dispatch =>(state) =>{
    dispatch({type: 'show_user_table', payload:state})
}
const cleanMessage =dispatch =>() =>{
    dispatch({type: 'dialog', payload:''})
}
export const {Provider,Context} = createDataContext(
    adminReducer,
    {getCompanySignature,addCompanySignature,getCompanies,createAgency,updateAgency,deleteAgency,
           createCompany,updateCompany,deleteCompany,
        showUsersTable,createUser,updateUser,deleteUser,cleanMessage},
    []
)
