import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Context as CreateContractContext} from "../context/CreateContractContext"
import TextField from "@material-ui/core/TextField";
import {Context as VehicleContractContext} from "../context/VehicleContractContext";

const useStyles = makeStyles((theme) => ({
    formControl: {
     //   margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
       // marginTop: theme.spacing(2),
    },
}));
const paymentType =(type) =>{
    switch (type) {
        case 'FLUID': return 'העברה'
        case 'CHECKS': return 'המחאות'
        case 'WIRE_TRANSFER': return 'העברה בנקאית'
        case 'CREDIT_CARD': return 'כ.אשראי'
        case 'CASH': return 'מזומן'
        case 'CHOOSE': return 'בחר'
        default: return 'ללא'
    }
}
const AutoWidthSelect =({defaultValue,HelperText,refrence}) =>{
    const [value, setValue] = React.useState(defaultValue);
    const{setNumPayments,setPaymentType,setDatePaymentCycle} = useContext(CreateContractContext);
    const {setVehicleNumPayments,setVehiclePaymentType} = useContext(VehicleContractContext);
    const handleChange = (event) => {
        if(refrence === "DEAL_TYPE"){
            setPaymentType(event.target.value)
        }else if (refrence==="M_DATE"){
            setDatePaymentCycle(event.target.value)
        }else if(refrence==='VEHICLE_DEAL_TYPE'){
            setVehiclePaymentType(event.target.value)
        }else if(refrence==='VEHICLE_NUM_PAY'){

            setVehicleNumPayments(event.target.value)
        }
        else{
            setNumPayments(event.target.value)
        }
        setValue(event.target.value);
    };

const selectValues = {
    dealType:[{value:'CHOOSE'},{value:'FLUID'},{value:'CHECKS'},{value:'CREDIT_CARD'},{value:'CASH'}],
    numPayments:[{value:0,text:'בחר'},{value:1,text:1},{value:2,text:2},{value:3,text:3},{value:4,text:4},{value:5,text:5},{value:6,text:6},{value:7,text:7},{value:8,text:8},{value:9,text:9},{value:10,text:10},{value:11,text:11},{value:12,text:12}],
    datePaymentCycle:['בחר',1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28]
}
const setDropDown =() =>{
    switch (refrence) {
        case 'DEAL_TYPE':
        case 'VEHICLE_DEAL_TYPE':
            return selectValues.dealType.map((value, index)=>{
                return <option key={value.value} value={value.value}>{paymentType(value.value)}</option>
            })
        case 'M_DATE':
            return selectValues.datePaymentCycle.map((value, index)=>{
                return <option key={value} value={value}>{value}</option>
            })
        case 'NUM_PAY':
            return selectValues.numPayments.map((value, index)=>{
                return <option key={value.value} value={value.value}>{value.text}</option>
            })
        default: return null;
    }

}
    return (
            <FormControl>
                <TextField
                    select
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    label={HelperText}
                    defaultValue={value}
                    value={value}
                    onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}

                >
                    {setDropDown()}
                </TextField>
            </FormControl>
    );
}
export {AutoWidthSelect}
