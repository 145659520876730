import React, {useContext} from 'react'
import { Route, Redirect } from "react-router-dom";
import {Context as AuthContext} from "../context/AuthenticationContext";

const AuthenticationRoute = (props) => {

    const {setAuthenticatedFalse} = useContext(AuthContext);

    const { component: Component, ...rest } = props;
    const tokenString = localStorage.getItem("token");
    if(!tokenString){
         setAuthenticatedFalse()
     }

    return (

        tokenString
        ?
        <Route exact {...rest} render={(props) => (<Component {...props}/>)} />
        :
        <Redirect to="/login" />

    )
}
export default AuthenticationRoute;
