import Grid from "@material-ui/core/Grid";
import React, {useContext, useState} from "react";
import {paymentTypes,paymentTypesArray} from '../../../helpers/convertedPaymentTypes'
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import {Context as SingleCabContext} from "../../../context/SingleCabContext";
import {Context as PaymentContext} from "../../../context/PaymentContext";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme)=>({
    paper: {
        padding: theme.spacing(0.5),
        width: "100%",
        height: "100%",
        color: theme.palette.text.primary,
    },
    paper2:{
        padding: theme.spacing(2),

    },
    opacityCover :{
        padding: theme.spacing(2),
        opacity:0.5
    },
    textField: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        minWidth: 250,
        maxHeight:50

    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
}));

export default function CreatePaymentComponent(props) {

    const {setPayment,setPaymentSaleDeal} = useContext(PaymentContext);

    const [state,setState] = useState({
        paymentType:props.paymentType,
        dealId:props.dealId,
        dueDate:props.nextPayment,
        status:1,
        amount:(Number(props.paymentAmount) /(1+props.vat)).toFixed(0),
        vat:props.vat,
        amountWithVat:props.paymentAmount,
        entity:props.entity,
        paymentDirection:props.paymentDirection
    });
    const classes = useStyles();
    const {state:{dataCab},createSinglePayment} = useContext(SingleCabContext);
    const onAmountChanged = (v) =>{

        const priceBeforeVat = (Number(v) /(1+state.vat)).toFixed(0);

        setState({
            ...state,
            amount: priceBeforeVat,
            amountWithVat:Number(v).toFixed(0)
        });
    }

    const onInputChange = (k,v) =>{
        setState({
            ...state,
            [k]: v,
        });
    }
    const createPayment = () =>{
        if(props.entity==='BUY-DEAL-PAYMENT'){
            setPayment(state);
        }else if(props.entity==='SALE-DEAL-PAYMENT'){
            setPaymentSaleDeal(state)
        }
        else {

            if(props.entity==='owner'){
                createSinglePayment(state,props.entity,dataCab.supplierContract.id);
            }else{
                createSinglePayment(state,props.entity,dataCab.consumerContract.id);
            }
        }

    }
    const innerGrid =(data) =>{
        return(
            <Grid
                spacing={1}
                display="flex"
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                justify="flex-end"

            >
                <Grid item xs={6}>
                    {data}
                </Grid>
            </Grid>
        )
    }
    return(
        <Paper variant="outlined" className={classes.paper} >
            <Grid
                className={ classes.paper2  }
                container
                spacing={3}
                direction="row"

            >
                <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                        {props.title}
                    </Typography>
                </Grid>
                <Grid  item xs={12}>
                    <TextField
                        id="date"
                        label="תאריך תשלום"
                        type="date"
                        variant="outlined"
                        value={state.dueDate}
                        onChange={e=>onInputChange('dueDate',e.target.value)}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        label="אמצעי תשלום"
                        value={state.paymentType}
                        onChange={e=>onInputChange('paymentType',e.target.value)}
                        SelectProps={{
                            native: true,
                        }}

                        helperText=""
                        variant="outlined"
                    >
                        {paymentTypesArray().map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="סכום לתשלום"
                        defaultValue={state.amountWithVat}
                        fullWidth
                        helperText=""
                        variant="outlined"
                        onChange={e=>onAmountChanged(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">₪</InputAdornment>,
                        }}
                    />
                </Grid>

                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12}>
                    <Grid
                        className={classes.paper}
                        container
                        direction="row"
                    >
                        <Grid item xs={7}>{' סכום ללא מע"מ ' }</Grid>
                        <Grid item xs={5}>
                            {innerGrid((Number(state.amount)).toFixed(0)+' ₪ ')}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                          className={classes.paper}
                          container
                          direction="row"
                    >
                        <Grid item xs={7}>{' מע"מ '+ (Number(state.vat)*100) + ' %'}</Grid>
                        <Grid item xs={5}>
                            {innerGrid((Number(state.amountWithVat) - Number(state.amount)).toFixed(0)+' ₪ ')}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        className={classes.paper}
                        container
                        direction="row"
                    >
                        <Grid item xs={7}>סה"כ כולל מע"מ</Grid>
                        <Grid item xs={5}>
                            {innerGrid(Number(state.amountWithVat).toFixed(0)+' ₪ ')}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>

                <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="primary"
                            // disabled ={props.conStatus !== 'ACTIVE' }
                            onClick={() =>createPayment()}>
                        {/*{props.conStatus === 'ACTIVE' ? 'בצע' : 'חוזה אינו פעיל' }*/}
                        {'בצע'}
                    </Button>
                </Grid>

            </Grid>
        </Paper>
    )
}
