import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi'

const supplierReducer = (state,action) =>{
    switch (action.type) {
        case 'init_supplier':
            return {...state,
                cab:null,
                personBalance:null,
                dataCabs:[],
                supplier:null,
                contractsHist:[],
                ownerCabVehicleHistory:[],
                driverCabVehicleHistory:[],
                cabExistsMessage:'',
                cabExistsSeverity:'',
                cabExistsDummyBool:null,
                sigPad:false,
                initialText:''
            }
        case 'get_supplier':
            return {...state,
                cab:action.payload.cab,
                personBalance:action.payload.personBalance ? action.payload.personBalance.personBalance : 0,
                dataCabs:action.payload.cabs,
                supplier:action.payload.supplier,
                contractsHist:action.payload.contractsHist,
                ownerCabVehicleHistory:action.payload.ownerCabVehicleHistory,
                driverCabVehicleHistory:action.payload.driverCabVehicleHistory,
            };

        case 'add_new_cab_supplier':
            const dataAdd = [...state.dataCabs];
            dataAdd.push(action.payload.data)
            return {...state,
                dataCabs:dataAdd
            }
        case 'cab_already_exists':
            return{
               ...state,
               cabExistsMessage:'זכות ציבורית זו כבר קיימת במאגר' ,
               cabExistsSeverity:'warning' ,
               cabExistsDummyBool:!state.cabExistsDummyBool
            }
        case 'update_cab_supplier':
            const dataUpdate = [...state.dataCabs];
            dataUpdate[dataUpdate.indexOf(action.oldData)] = action.payload;
            dataUpdate.push(action.payload)
            return {...state,
                dataCabs:dataUpdate
            }
            case 'update_cabs_comment':
            const dataUpdateComment = [...state.dataCabs];
            dataUpdateComment[dataUpdateComment.indexOf(action.oldData)] = action.payload;
            //dataUpdateComment.push(action.payload)
            return {...state,
                dataCabs:dataUpdateComment
            }
        case 'delete_cab_supplier':
            const dataDelete = [...state.dataCabs];
            dataDelete.splice(dataDelete.indexOf(action.oldData), 1);
            return {...state,
                dataCabs:dataDelete
            }

        case 'update_person_details':
            return {...state,
                supplier:action.payload
            }
        case 'set_pad':
            if(!state.initialText){
                return {
                    ...state,
                    sigPad:state.sigPad !== true,
                    initialText:padData
                }
            }
            return {
                ...state,
                sigPad:state.sigPad !== true
            }
        case 'set_supplier_from_out' :
            return {...state,
                supplier:action.payload
            }
        case 'set_show_cab_exists_message':
            return {...state,
                cabExistsDummyBool:null
            }

        default:return;
    }
}
const initSupplier = dispatch => () =>{
    dispatch({type:'init_supplier',payload:null})
}

const padData = async () =>{
    return  await InterceptorApi().get(`/api/genericAuthorization`);
}

const setPad =dispatch =>async (bol) =>{
    dispatch({type:'set_pad',payload:bol})
}
const getSupplierData =  dispatch => async(id) =>{
    //dispatch({type:'init_supplier',payload:null})
    const response = await InterceptorApi().get(`/api/supplierPage/${id}`);

    dispatch({type:'get_supplier',payload:response.data})

}
const addNewCabToSupplier = dispatch => async(newData, id) =>{
    const obj  = {folderId:newData.folderId,cabNumber:newData.cabNumber,supplier:{id:id.supplierId},comment:newData.comment ? newData.comment: null }
    const response = await InterceptorApi().post('/api/addNewCab', obj)

    if(response.status ===226){

        dispatch({type:'cab_already_exists',payload: response})
    }else if (response.status ===200){
        dispatch({type:'add_new_cab_supplier',payload: response})
    }


}
const updateCabsComment = dispatch => async(newData,oldData) =>{
    const obj  = {id:newData.id,comment:newData.comment,folderId: newData.folderId}
    const response = await InterceptorApi().put('/api/updateCabsComment',obj)

    dispatch({type:'update_cabs_comment',payload:response.data,oldData})
}
const softDeleteCabToSupplier = dispatch => async(oldData) =>{
    const obj  = {id:oldData.id};
    const response = await InterceptorApi().put('/api/softDeleteCab',obj)

    dispatch({type:'delete_cab_supplier',payload:response.data,oldData})
}
const updateCabToSupplier = dispatch => async(newData,oldData) =>{
    const response = await InterceptorApi().put('/api/updateCabFromSupplierPagge',newData)
    dispatch({type:'update_cab_supplier',payload:response.data,oldData})
}

const updatePersonDetails = dispatch =>async(data) =>{
    const response = await InterceptorApi().put('/api/updatePerson',data)
    dispatch({type:'update_person_details',payload:response.data.supplier})
}
const setSupplierFromOuterComponent = dispatch =>async(data) =>{
    dispatch({type:'set_supplier_from_out',payload:data})
}

export const {Provider,Context} = createDataContext(
    supplierReducer,
    {initSupplier,getSupplierData,addNewCabToSupplier,updateCabToSupplier,
            softDeleteCabToSupplier,updatePersonDetails,setPad,updateCabsComment,
             setSupplierFromOuterComponent
    },
    []
)

