import React,{useState,useContext} from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import {Context as VehicleContractContext} from "../../../../context/VehicleContractContext"
import {paymentTypes} from '../../../../helpers/convertedPaymentTypes'
const useStyles = makeStyles((theme) => ({
    root: {
        width: 400,
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.secondary,
    },

    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    }

}));
const VehicleContractSummary =() =>{
    const {state:{upfrontPaymentValue,numPayments,contractDate,deliveryDate,kilometersOnSignContractDate,paymentType,paymentAmount,commentValue,violationFeeValue,contractType}} = useContext(VehicleContractContext)
    const [checked, setChecked] = useState([0]);
    const classes = useStyles();
    const getList = () =>{
        if(contractType ==='saleVehicle'){
            return ['תאריך חוזה', 'תאריך מסירה משוער', 'קילומטרים',"סוג עסקה",'מחיר','מע"מ', 'מחיר כולל מעמ','מקדמה','תשלומים',"הערות","פיצוי ביטול"];
        }else{
            return ['תאריך חוזה', 'תאריך מסירה משוער','קילומטרים' ,"סוג עסקה",'מחיר',"הערות","פיצוי ביטול"];
        }
    }
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const setNameElem = (index) =>{
        if(contractType ==='saleVehicle'){
            switch (index) {
                case 0: return <Typography>{contractDate}</Typography>
                case 1: return <Typography>{deliveryDate}</Typography>
                case 2: return <Typography>{kilometersOnSignContractDate}</Typography>
                case 3: return <Typography>{paymentTypes(paymentType)}</Typography>
                case 4: return <Typography>{paymentAmount+' ₪'}</Typography>
                case 5: return <Typography>{(paymentAmount * 0.17).toFixed(0)+' '}&#8362;</Typography>
                case 6: return <Typography>{(paymentAmount * 1.17).toFixed(0)+' '}&#8362;</Typography>
                case 7: return <Typography>{upfrontPaymentValue+' ₪'}</Typography>
                case 8: return <Typography>{numPayments}</Typography>
                case 9: return <Typography>{commentValue}</Typography>
                case 10: return <Typography>{violationFeeValue+' '}&#8362;</Typography>
                default:return null
            }
        }else{
            switch (index) {
                case 0: return <Typography>{contractDate}</Typography>
                case 1: return <Typography>{deliveryDate}</Typography>
                case 2: return <Typography>{kilometersOnSignContractDate}</Typography>
                case 3: return <Typography>{paymentTypes(paymentType)}</Typography>
                case 4: return <Typography>{paymentAmount+' ₪'}</Typography>
                case 5: return <Typography>{commentValue}</Typography>
                case 6: return <Typography>{violationFeeValue+' ₪'}</Typography>
                default:return null
            }
        }

    }
    return(
        <List className={classes.root}>

            {getList().map((name,index) => {
                return (
                    <ListItem key={name} role={undefined} dense button onClick={handleToggle(index)}>
                        <ListItemText>
                            <Typography>{name}</Typography>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="comments">
                                {setNameElem(index)}
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
    )
}
export default VehicleContractSummary
