import React, {useContext, useEffect, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Context as CreateContractContext} from "../../../../context/CreateContractContext";
import {Context as SingleCabContext} from "../../../../context/SingleCabContext";
import SearchPerson from "../../../../components/SearchPerson";
import {Context as PersonContext} from "../../../../context/PersonContext";

const PersonFormAccomplishment = (props) =>{
    const {state:{contractType},checkAndSetDriverByNationalId,setPersonId} = useContext(CreateContractContext);
    const {state:{selectedPerson}} = useContext(PersonContext);
    const {state:{dataCab},updatePersonBasicDetails} = useContext(SingleCabContext);

    let PersonObj;
    switch (contractType) {
        case 'owner':
        case 'ownerRenewal':
            PersonObj ={
                id:dataCab.supplier.id,
                nationalId:dataCab.supplier.nationalId,
                fullName:dataCab.supplier.fullName,
                emailAddress:dataCab.supplier.emailAddress,
                fullAddress:dataCab.supplier.fullAddress,
                phoneOne:dataCab.supplier.phoneOne,
                phoneTwo:dataCab.supplier.phoneTwo,
            }
            break;
        case 'driver':
              default:
            PersonObj ={
                id:'',
                nationalId:'',
                fullName:'',
                emailAddress:'',
                fullAddress:'',
                phoneOne:'',
                phoneTwo:'',
            }
            break;
        case 'driverRenewal':
            PersonObj ={
                id:dataCab.driver.id,
                nationalId:dataCab.driver.nationalId,
                fullName:dataCab.driver.fullName,
                emailAddress:dataCab.driver.emailAddress,
                fullAddress:dataCab.driver.fullAddress,
                phoneOne:dataCab.driver.phoneOne,
                phoneTwo:dataCab.driver.phoneTwo,
            }
            break;


    }
    const [state, setState] = useState(PersonObj);

    useEffect(() => {

      if(selectedPerson && contractType ==='driver'){
          setPersonId(selectedPerson.id);
          setState({
              id:selectedPerson.id,
              nationalId:selectedPerson.nationalId ? selectedPerson.nationalId : '',
              fullName:selectedPerson.fullName ? selectedPerson.fullName : '',
              emailAddress:selectedPerson.emailAddress ? selectedPerson.emailAddress : '',
              fullAddress:selectedPerson.fullAddress ? selectedPerson.fullAddress : '',
              phoneOne:selectedPerson.phoneOne ? selectedPerson.phoneOne : '',
              phoneTwo:selectedPerson.phoneTwo ? selectedPerson.phoneTwo : '',
          })
      }

    }, [selectedPerson]);

    useEffect(() => {
       if(contractType ==='owner' || contractType ==='ownerRenewal') {
           setPersonId(dataCab.supplier.id);
       } else if( contractType ==='driverRenewal') {
           setPersonId(dataCab.driver.id);
       }
    }, []);
    const handleChange = (k,v) =>{
        setState({
            ...state,
            [k]: v,
        });
    }
    const handleSubmitClick = () =>{
        updatePersonBasicDetails(contractType,state);
    }

    return(
        <>
        {contractType ==='driver' ? <Grid item xs={12} ><SearchPerson /></Grid> : null}
        <Grid item xs={12}>
            <TextField
                error={state.nationalId === null || state.nationalId === ''}
               // disabled={state.nationalId && state.nationalId.length >  4}
                size={'small'}
                variant="outlined"
                style = {{width: 400}}
                id="standard-basic"
                value={state.nationalId}
                helperText={state.nationalId === null || state.nationalId === '' ? 'תעודת זהות הינו שדה חובה':null}
                onChange={e=>handleChange('nationalId',e.target.value)}
                label="ת.זהות" />

        </Grid>
        <Grid item xs={12}>
            <TextField
                size={'small'}
                variant="outlined"
                style = {{width: 400}}
                id="standard-basic"
                value={state.fullName}
                onChange={e=>handleChange('fullName',e.target.value)}
                error={state.fullName === null || state.fullName === ''}
                helperText={state.fullName === null || state.fullName === '' ? 'שם מלא הינו שדה חובה':null}
                label="שם מלא" />
        </Grid>
        <Grid item xs={12}>
            <TextField
                size={'small'}
                variant="outlined"
                style = {{width: 400}}
                id="standard-basic"
                value={state.fullAddress}
                onChange={e=>handleChange('fullAddress',e.target.value)}
                error={state.fullAddress === null || state.fullAddress === ''}
                helperText={state.fullAddress === null || state.fullAddress === '' ? 'כתובת הינו שדה חובה':null}
                label="כתובת" />
        </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    variant="outlined"
                    style = {{width: 400}}
                    id="standard-basic"
                    value={state.emailAddress}
                    onChange={e=>handleChange('emailAddress',e.target.value)}
                    label="כתובת email" />
            </Grid>
        <Grid item xs={12}>
            <TextField
                size={'small'}
                variant="outlined"
                style = {{width: 400}}
                id="standard-basic"
                value={state.phoneOne}
                onChange={e=>handleChange('phoneOne',e.target.value)}
                error={state.phoneOne === null || state.phoneOne === ''}
                helperText={state.phoneOne === null || state.phoneOne === '' ? 'טלפון ראשון הינו שדה חובה':null}
                label="טלפון ראשון" />
        </Grid>
        <Grid item xs={12}>
            <TextField size={'small'} variant="outlined" style = {{width: 400}} id="standard-basic" value={state.phoneTwo} onChange={e=>handleChange('phoneTwo',e.target.value)} label="טלפון שני" />
        </Grid>
        <Grid item xs={12}>
            <Button onClick={() =>handleSubmitClick()} variant="contained" size ="small" style = {{width: 400}} fullWidth color="secondary">
                עדכן פרטים
            </Button>
        </Grid>
        </>
    )
}
export default PersonFormAccomplishment;
