import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from "@material-ui/core/Grid";
import {Context as SupplierContext} from "../../../context/SupplierContext";
import Spinner from "../../../components/Spinner";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import {Link} from "react-router-dom";
const useStyles = makeStyles((theme)=>({
    root: {
        minWidth: 275,
        marginBottom:5,
        flexGrow: 1,
        alignItems: 'center',

    },
    paper: {
        padding: theme.spacing(2),
        width: "100%",
        height: "100%",
        color: theme.palette.text.primary,
    },
    divi:{
        display:'flex',
        alignItems:'center',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    details_b:{
        display:'flex',
        alignItems:'center'
    },
    left: {textAlign: 'right',}

}));


const switchLink = (colData,Id,pathPart) =>{
    return (
        <Typography>
            <Link
                to={{pathname: `/${pathPart}/${colData}`,state: {extraData:{id:Id}}}}
                style={{ textDecoration: 'none',color:'blue'}}>{colData}
            </Link>
        </Typography>
    )
}
const SupplierDetails = ({entityType}) => {

    const classes = useStyles();
    const [state,setState] = useState();
    const [cabData,setCabData] = useState({
        cabNumber:'--',
        vehicle:'--'
    })
    const [balance, setBalance] = useState(0);
    const {state:{supplier,cab,personBalance},updatePersonDetails} = useContext(SupplierContext)
    useEffect(() =>{
        if(supplier){
            setState({
                id:supplier.id,
                fullName: supplier.fullName,
                fullAddress:supplier.fullAddress,
                emailAddress:supplier.emailAddress,
                phoneOne:supplier.phoneOne,
                phoneTwo:supplier.phoneTwo,
                folderId:supplier.folderId,
                notaryEndDate:supplier.notaryEndDate,
            })
        }
       if(cab){
           setCabData({
               id:cab.id,
               cabNumber:cab.cabNumber,
               vehicle:cab.vehicle && cab.vehicle.carNumber ? cab.vehicle.carNumber : '--',
               vehcleId:cab.vehicle && cab.vehicle.id ? cab.vehicle.id : ''
           })
       }else{
           setCabData({
               id:'',
               cabNumber:'--',
               vehicle: '--',
               vehcleId: '--'
           })
       }
        if(personBalance){
            setBalance(personBalance);
        }


    },[supplier,cab,personBalance]);
    const updatePerson = ()=>{
        updatePersonDetails(state)
    }

    const [nameEditMode,setNameEditMode] = useState(false)
    return (
        <div>
            <Grid container spacing={3}>
                {
                    nameEditMode ?
                        <Grid item xs={6} xl={6} >
                            <Paper className={classes.paper} >
                                <div className={classes.divi}>
                                    <TextField
                                        placeholder="שם מלא"
                                        label="שם מלא"
                                        size="small"
                                        fullWidth
                                        value={state.fullName}
                                        onChange={e =>setState({...state,fullName:e.target.value})}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            if(state)
                                                setState({
                                                    id:supplier.id,
                                                    fullName: supplier.fullName,
                                                    fullAddress:supplier.fullAddress,
                                                    emailAddress:supplier.emailAddress,
                                                    phoneOne:supplier.phoneOne,
                                                    phoneTwo:supplier.phoneTwo
                                                })
                                            setNameEditMode(false)
                                        }}
                                        aria-label="edit"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                    <IconButton
                                        display="flex"
                                        onClick={() => {
                                            setNameEditMode(false);
                                            updatePerson();
                                        }}
                                        aria-label="edit"
                                    >
                                        <DoneIcon />
                                    </IconButton>
                                </div>
                                <TextField
                                    size="small"
                                    value={state.fullAddress}
                                    onChange={e =>setState({...state,fullAddress:e.target.value})}
                                    fullWidth
                                    label="כתובת"
                                    placeholder="כתובת"
                                />
                                <TextField
                                    size="small"
                                    fullWidth id="standard-basic"
                                    label='דוא"ל'
                                    value={state.emailAddress}
                                    onChange={e =>setState({...state,emailAddress:e.target.value})}
                                />

                                <div className={classes.divi} >
                                    <TextField
                                        style={{margin:5}}
                                        size="small"
                                        value={state.phoneOne}
                                        onChange={e =>setState({...state,phoneOne:e.target.value})}
                                        id="standard-basic"
                                        label='טלפון 1'/>
                                    <TextField
                                        style={{margin:5}}
                                        size="small"
                                        value={state.phoneTwo}
                                        onChange={e =>setState({...state,phoneTwo:e.target.value})}
                                        id="standard-basic"
                                        label='טלפון 2'/>
                                </div>
                            </Paper>
                        </Grid>
                        :
                        <Grid item xl={6} xs={6}>
                            <Paper className={classes.paper} >
                                <div className={classes.divi}>
                                    <Typography variant="h5">
                                        {state ? state.fullName : <Spinner/>}
                                    </Typography>
                                    <IconButton style={{marginRight: 'auto'}}
                                                onClick={() =>setNameEditMode(true)}
                                                aria-label="edit"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </div>
                                <Typography variant="subtitle1">
                                    {state ? state.fullAddress : null}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {state ? state.emailAddress : null}
                                </Typography>
                                <div className={classes.divi} >
                                    <Typography variant="subtitle1">{state ? state.phoneOne : null}</Typography>
                                    <Typography variant="subtitle1">{state && state.phoneTwo ? ',': null}</Typography>
                                    <Typography variant="subtitle1">{state ? state.phoneTwo : null}</Typography>
                                </div>
                            </Paper>
                        </Grid>
                }
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Typography variant="subtitle1">
                                    {'נוהג על רכב מספר'}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" style={{marginRight: 'auto'}}>
                                    {switchLink(cabData.vehicle,cabData.vehcleId,'vehicles')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Typography variant="subtitle1">
                                    {'משודך לזכות מספר'}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" style={{marginRight: 'auto'}}>
                                    {switchLink(cabData.cabNumber,cabData.id,'cabs')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Typography variant="subtitle1">
                                    מאזן כספי
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" style={{marginRight: 'auto'}}>
                                    {balance +' ₪ '}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
export default SupplierDetails

