import React,{useState,useContext} from "react";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import {Context as CreateContractContext} from "../../../../context/CreateContractContext"
const useStyles = makeStyles((theme) => ({
    root: {
        width: 400,
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.secondary,
    },

    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    }

}));
const ContractSummary =() =>{
    const {state:{contractType,startDate,endDate,dateFirstPayment,datePaymentCycle,numPayments,paymentAmount}} = useContext(CreateContractContext)
    const [checked, setChecked] = useState([0]);
    const classes = useStyles();
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const setNameElem = (index) =>{
        switch (index) {
            case 0: return <Typography>{contractType ==="owner" || contractType ==="ownerRenewal" ? 'בעלים':'נהג'}</Typography>
            case 1: return <Typography>{startDate}</Typography>
            case 2: return <Typography>{endDate}</Typography>
            case 3: return <Typography>{dateFirstPayment}</Typography>
            case 4: return <Typography>{datePaymentCycle}</Typography>
            case 5: return <Typography>{numPayments}</Typography>
            case 6: return <Typography>{paymentAmount+' '}&#8362;</Typography>
            default:return null
        }
    }
    return(
        <List className={classes.root}>
            {['חוזה מול','תאריך התחלה', 'תאריך סיום', 'מועד תשלום ראשון',"מחזור חיוב חודשי", "מס' תשלומים",'סכום לתשלום'].map((name,index) => {
                return (
                    <ListItem key={name} role={undefined} dense button onClick={handleToggle(index)}>
                        <ListItemText>
                            <Typography>{name}</Typography>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="comments">
                                {setNameElem(index)}
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
    )
}
export default ContractSummary
