import React, {useContext, useEffect} from 'react'
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {Context as SupplierContext} from "../../../context/SupplierContext";
import SupplierDetails from "./SupplierDetails";
import moment from "moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import {Context as NotificationContext} from "../../../context/NotificationContext";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));
const paymentType =(type) =>{
    switch (type) {
        case 'FLUID': return 'העברה'
        case 'CHECKS': return 'המחאות'
        case 'WIRE_TRANSFER': return 'העברה בנקאית'
        case 'CREDIT_CARD': return 'כ.אשראי'
        case 'CASH': return 'מזומן'
        default: return 'ללא'
    }
}
const switchLink = (colData,Id,pathPart) =>{
    return (
        <Typography>
            <Link
                to={{pathname: `/${pathPart}/${colData}`,state: {extraData:{id:Id}}}}
                style={{ textDecoration: 'none',color:'blue'}}>{colData}
            </Link>
        </Typography>
    )
}
const findNextUnpaiedPayment = (payments,src) =>{
    if(!payments){
        return ''
    }
    const found = payments.find(e => e.status ===0)
    if(found){
        return src==="amount" ? found.amount+ ' ₪' : found && found.dueDate ? moment(found.dueDate).format('YYYY-MM-DD') : ''
    }else{
        return ''
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const sumPayments =(payments) =>{
    const sumPayments =   payments.reduce((a, b) => ({amount: a.amount + b.amount}), {amount: 0});
    return sumPayments.amount
}
export default function SupplierComponent (props) {

    const {pipeMessage} =useContext(NotificationContext);
    const classes = useStyles();
    const supplierId = props.location.state.extraData.id
    const {state:{cabExistsDummyBool,cabExistsMessage,cabExistsSeverity,dataCabs,supplier,ownerCabVehicleHistory,driverCabVehicleHistory},
        getSupplierData,addNewCabToSupplier,updateCabsComment,softDeleteCabToSupplier,initSupplier} = useContext(SupplierContext);
    console.log('tttttttttttttttttttttttttttt',driverCabVehicleHistory)
    const columns= [
        {
            title: 'id',
            field: 'id',
            editable:'never',
            hidden: true,
        },
        {
            title: 'מספר',
            field: 'cabNumber',
            editable:'onAdd',
            render: rowData => rowData && rowData.cabNumber ? switchLink(rowData.cabNumber,rowData.id,'cabs') : ""
        },


        {
            title: 'מספר קלסר',
            field: 'folderId',
           // render: rowData => rowData && rowData.folderId ? rowData.folderId : "",

        },
        {
            title: 'ת.התחלה',
            field: 'startDate',
            editable:'never',
            render: rowData => rowData && rowData.supplierContract && rowData.supplierContract.startDate ? rowData.supplierContract.startDate.substring(0,10) : ""
        },
        {
            title: 'ת.סיום',
            field: 'endDate',
            editable:'never',
            render: rowData => rowData && rowData.supplierContract && rowData.supplierContract.endDate ? rowData.supplierContract.endDate.substring(0,10) : ""
        },
        {
            title: 'ת.מחיקה',
            field: 'endDate',
            editable:'never',
            render: rowData => rowData && rowData.closeDate  ? moment(rowData.closeDate).format('YYYY-MM-DD HH:mm:ss')  : "--"
        },
        {
            title: 'תשלום הבא',
            field: 'paymentUpTo',
            editable:'never',
            render: rowData => rowData && rowData.supplierContract && rowData.supplierContract.payments ?  findNextUnpaiedPayment(rowData.supplierContract.payments,"dueDate") : ""

        },
        {
            title: 'סכום',
            field: 'paymentAmount',
            editable:'never',
            render: rowData => rowData && rowData.supplierContract && rowData.supplierContract.payments ?  findNextUnpaiedPayment(rowData.supplierContract.payments,"amount") : ""
        },
        {
            title: 'אמצעי תשלום',
            field: 'paymentType',
            editable:'never',
            render: rowData => rowData && rowData.supplierContract && rowData.supplierContract.paymentType ?  paymentType(rowData.supplierContract.paymentType) : "ללא"
        },
        {
            title: 'הערות',
            field: 'comment'

        },
    ]
    const loanColumns =[

        {
            title: 'מספר זכות',
            field: 'cabNumber',
            editable:'onAdd',
            render: rowData => rowData && rowData.cabNumber ? switchLink(rowData.cabNumber,rowData.id,'cabs') : ""
        },

        {
            title: 'ת.יצירה',
            field: 'createdOn',
            editable:'never',
            render: rowData => rowData.createdOn  && rowData.createdOn ? moment(rowData.createdOn).format('DD/MM/YYYY') : ""
        },
        {
            title: 'בעל הזכות',
            field: 'cabOwnerFullName',
            editable:'never',
            render: rowData => rowData.cabOwnerFullName  ?  rowData.cabOwnerFullName : "--"
        },
        {
            title: 'מספר רכב',
            field: 'vehicleNumber',
            editable:'never',
            render: rowData => rowData.vehicleNumber  ?  rowData.vehicleNumber : "--"
        },
        {
            title: 'בעל הרכב',
            field: 'vehicleOwnerFullName',
            editable:'never',
            render: rowData => rowData.cabOwnerFullName  ?  rowData.cabOwnerFullName : "--"
        },

        {
            title: 'הערות',
            field: 'comment'

        },
    ]

    const cabVehicleHistColumns =[
        {
            title: 'מספר זכות',
            field: 'cabNumber',
            render: rowData => rowData && rowData.cabNumber ? switchLink(rowData.cabNumber,rowData.cabId,'cabs') : ""
        },
        {
            title: 'בעל הזכות',
            field: 'cabOwnerFullName',
            render: rowData => rowData && rowData.cabOwnerFullName ? switchLink(rowData.cabOwnerFullName,rowData.cabOwnerFullName,'cabs') : ""
        },
        {
            title: 'מספר רכב',
            field: 'vehicleNumber',
            render: rowData => rowData && rowData.vehicleNumber ? switchLink(rowData.vehicleNumber,rowData.vehicleNumber,'vehicles') : ""
        },
        {
            title: 'בעל הרכב',
            field: 'vehicleOwnerFullName',
            render: rowData => rowData && rowData.vehicleOwnerFullName ? switchLink(rowData.vehicleOwnerFullName,rowData.vehicleOwnerId,'cabsowners') : ""
        },
        {
            title: 'נהג',
            field: 'driverFullName',
            render: rowData => rowData && rowData.driverFullName ? switchLink(rowData.driverFullName,rowData.driverId,'drivers') : ""
        },
        {
            title: 'תאריך יצירה',
            field: 'createdOn',
            render: rowData => rowData && rowData.createdOn ? moment(rowData.createdOn).format('DD/MM/YYYY HH:MM:SS') : ""
        },
    ]

    useEffect(() =>{
        getSupplierData(supplierId)
        return () => {
            initSupplier()
        }
    },[])
    useEffect(() =>{
        if(typeof cabExistsDummyBool !== 'undefined' && cabExistsDummyBool !== null){
            pipeMessage(cabExistsMessage,cabExistsSeverity)
        }
    },[cabExistsDummyBool])
    const  cabsForSupplier = () => {
        return (
            <MaterialTable
                size="small"
                title="זכויות בבעלות"
                columns={columns}
                style={{boxShadow:"none"}}
                data= {dataCabs}
                localization={{
                    body:{ editRow: { deleteText: 'האם אתה בטוח שברצונך למחוק זכות זו?' } },
                    toolbar: {
                        searchPlaceholder: ''
                    },
                    header: {
                        actions:''
                    },
                }}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    padding: "dense",
                    rowStyle: rowData => {
                        return {
                            backgroundColor: rowData.closeDate ? '#E0E0E0' : '#FFF'
                        };
                    }
                }}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve,reject) => {
                            addNewCabToSupplier(newData, {supplierId: supplier.id})
                            resolve();
                        }).catch(reason => console.log(reason)),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve,reject) => {
                            updateCabsComment(newData,oldData)
                            resolve();
                        }),
                    onRowDelete: oldData => new Promise((resolve, reject) => {
                        if (oldData.closeDate != null) {
                            alert("זכות זו כבר נמחקה");
                            reject();
                        } else {
                            softDeleteCabToSupplier(oldData);
                            resolve();
                        }
                    }),
                }}

            />
        );
    }

    const  loanHistory = () => {
        return (
            <MaterialTable
                size="small"
                title="היסטוריית חוזי זכויות"
                columns={loanColumns}
                style={{boxShadow:"none"}}
                data= {driverCabVehicleHistory}
                localization={{
                    toolbar: {
                        searchPlaceholder: ''
                    },
                    header: {
                        actions:''
                    },

                }}
                options={{actionsColumnIndex: -1, search: false, padding: "dense"}}
            />
        );
    }
    const cabVehicleHistoryTable =() =>{

        return (
            <MaterialTable
                size="small"
                title="היסטוריית רכבים בבעלות"
                columns={cabVehicleHistColumns}
                style={{boxShadow:"none"}}
                data= {ownerCabVehicleHistory}
                localization={{
                    toolbar: {
                        searchPlaceholder: ''
                    },
                    header: {
                        actions:''
                    },

                }}
                options={{actionsColumnIndex: -1, search: false, padding: "dense"}}
            />
        );
    }

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(
        <div className={classes.root}>
            <SupplierDetails entityType="supplier"/>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                >
                    <Grid item xs={12}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="simple tabs example">

                            <Tab label="זכויות בבעלות" {...a11yProps(0)} />
                            <Tab label="היסטורית שכירויות" {...a11yProps(1)} />
                            <Tab label="היסטורית רכבים" {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            {cabsForSupplier()}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {loanHistory()}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {cabVehicleHistoryTable()}
                        </TabPanel>
                    </Grid>
                </Grid>

        </div>
    )


}


