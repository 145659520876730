import React, {useContext, useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ServerSideTable from "../../../components/ServerSideTable";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import DashboardIcon from '@material-ui/icons/Dashboard';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AllPayments from "./AllPayments";
import {Context as SingleCabContext} from "../../../context/SingleCabContext";
import moment from "moment";
const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
          flexGrow: 1,
          backgroundColor: theme.palette.background.paper,
    },

}));
const switchLink = (colData,id,pathPart) =>{

    return (
        <Typography variant="body2">
            <Link
                to={{pathname: `/${pathPart}/${colData}`,state: {extraData:{id:id}}}}
                style={{ textDecoration: 'none',color:'blue'}}>
                {colData}
            </Link>
        </Typography>
    )
}
const wrapWithTypography = (colData) =>{
    return (
        <Typography variant="body2">
            {colData}
        </Typography>
    )

}
export default function HomePage() {
    const { state: { driversPaymentsBadge,ownersPaymentsBadge,paymentsBadgeChanged}, getBadges } = useContext(SingleCabContext);
    const [badgeState,setBadgeState] = useState({ drivers: 0, owners: 0 });
    useEffect(()=>{
        if(typeof paymentsBadgeChanged ==='undefined'){
            getBadges();
        }
        if(typeof paymentsBadgeChanged !=='undefined'){
            setBadgeState({ drivers: driversPaymentsBadge, ownersPaymentsBadge: ownersPaymentsBadge })
        }
    },[paymentsBadgeChanged])

    const classes = useStyles();
    const columns= [
        {
            title: 'רכב',
            field: 'carNumber',
            editable:'never',
            render: rowData => rowData && rowData.carNumber  ? switchLink(rowData.carNumber,rowData.vehicleId,'vehicles') : " ",

        },
        {
            title: 'בעל הרכב',
            field: 'carOwner',
            editable:'never',
            render: rowData => rowData && rowData.vehicleOwnerFullName  ? switchLink(rowData.vehicleOwnerFullName,rowData.vehicleOwnerId,'cabsowners') : " ",

        },
        {
            title: 'ת.רישיון רכב',
            field: 'vehicleTestDate',
            editable:'never',
            render: rowData => rowData && rowData.vehicleTestDate  ? wrapWithTypography(moment(rowData.vehicleTestDate).format('YYYY-MM-DD'))  : " ",

        },
        {
            title: 'מספר זכות',
            field: 'cabNumber',
            editable:'never',
            render: rowData => rowData && rowData.cabNumber ? switchLink(rowData.cabNumber,rowData.id,'cabs') : " "
        },
        {
            title: 'בעל הזכות',
            field: 'fullName',
            editable:'never',
            render: rowData => rowData && rowData.supplierFullName  ? switchLink(rowData.supplierFullName,rowData.supplierId,'cabsowners') : " ",

        },
        {
            title: 'טלפון בעל הזכות',
            field: 'fullName',
            editable:'never',
            render: rowData => rowData && rowData.supplierPhoneOne ? wrapWithTypography(rowData.supplierPhoneOne) : " ",

        },
        {
            title: 'שם השוכר',
            field: 'consumerName',
            editable:'never',
            render: rowData => rowData && rowData.driverFullName ? switchLink(rowData.driverFullName,rowData.driverId,'drivers') : " ",

        },
        {
            title: 'טלפון השוכר',
            field: 'consumerName',
            editable:'never',
            render: rowData => rowData && rowData.driverPhoneOne  ? wrapWithTypography(rowData.driverPhoneOne): " ",

        },
        {
            title: 'הערות',
            field: 'comment',
            render: rowData => rowData && rowData.comment  ? wrapWithTypography(rowData.comment): " ",
        },
    ]
    const vehicleTestTracker= [
        {
            title: 'רכב',
            field: 'carNumber',
            editable:'never',
            render: rowData => rowData &&  rowData.car &&  rowData.car.carNumber ? switchLink(rowData.car.carNumber,rowData.car.id,'vehicles') : "--",

        },
        {
            title: 'בעל הרכב',
            field: 'carOwner',
            editable:'never',
            render: rowData => rowData && rowData.car && rowData.car.vehicleOwner  ? switchLink(rowData.car.vehicleOwner.fullName,rowData.car.vehicleOwner.id,'cabsowners') : "--",

        },
        {
            title: 'תאריך טסט',
            field: 'vehicleTestDate',
            editable:'never',
            render: rowData => rowData && rowData.car && rowData.car.vehicleTestDate  ? moment(rowData.car.vehicleTestDate).format('YYYY-MM-DD') : "--",

        },
        {
            title: 'מספר זכות',
            field: 'cabNumber',
            editable:'never',
            render: rowData => rowData && rowData.cab && rowData.cab.cabNumber  ? switchLink(rowData.cab.cabNumber,rowData.cab.id,'cabs') : "--"
        },
        {
            title: 'בעל הזכות',
            field: 'fullName',
            editable:'never',
            render: rowData => rowData && rowData.cab && rowData.cab.supplier && rowData.cab.supplier.fullName ? switchLink(rowData.cab.supplier.fullName,rowData.cab.supplier.id,'cabsowners') : "--",

        },
        {
            title: 'טלפון בעל הזכות',
            field: 'fullName',
            editable:'never',
            render: rowData => rowData && rowData.cab && rowData.cab.supplier && rowData.cab.supplier.phoneOne ? rowData.cab.supplier.phoneOne : "--",

        },
        {
            title: 'שם השוכר',
            field: 'consumerName',
            editable:'never',
            render: rowData => rowData && rowData.car && rowData.car.driver &&  rowData.car.driver.fullName ? switchLink(rowData.car.driver.fullName,rowData.car.driver.id,'drivers') : "--",

        },
        {
            title: 'טלפון השוכר',
            field: 'consumerName',
            editable:'never',
            render: rowData => rowData && rowData.car && rowData.car.driver &&  rowData.car.driver.phoneOne ? rowData.car.driver.phoneOne : "--",

        },
        {
            title: 'הערות',
            field: 'vehicleComments',
            render: rowData => rowData && rowData.car && rowData.car.vehicleComments &&  rowData.car.vehicleComments ? rowData.car.vehicleComments : "--",
        },
    ]

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={classes.root}>
            <Grid
                container
                direction="row"
                spacing={3}
                justify="center"
                alignItems="center"
            >

                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                    >

                        <Grid item xs={12}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="simple tabs example">


                                <Tab icon={
                                    <IconButton aria-label="cart">
                                        <StyledBadge badgeContent={0} color="secondary">
                                            <DashboardIcon />
                                        </StyledBadge>
                                    </IconButton>

                                }
                                     label="מידע כללי"
                                     {...a11yProps(0)}
                                />
                                <Tab icon={
                                    <IconButton aria-label="cart">
                                        <StyledBadge badgeContent={badgeState.owners} color="secondary">
                                            <ArrowUpwardIcon />
                                        </StyledBadge>
                                    </IconButton>

                                }
                                     label="תשלומים לבעלים"
                                     {...a11yProps(1)}
                                />
                                <Tab icon={
                                    <IconButton aria-label="cart">
                                        <StyledBadge badgeContent={badgeState.drivers} color="secondary">
                                            <ArrowDownwardIcon />
                                        </StyledBadge>
                                    </IconButton>

                                }
                                     label="תקבולי נהגים"
                                     {...a11yProps(2)}
                                />
                                <Tab icon={
                                    <IconButton aria-label="cart">
                                        <StyledBadge badgeContent={0} color="secondary">
                                            <HistoryIcon />
                                        </StyledBadge>
                                    </IconButton>

                                }
                                     label="היסטוריית תשלומים"
                                     {...a11yProps(3)}
                                />
                                <Tab icon={
                                    <IconButton aria-label="cart">
                                        <StyledBadge badgeContent={0} color="secondary">
                                            <DateRangeIcon />
                                        </StyledBadge>
                                    </IconButton>

                                }
                                     label="מעקב רישויים"
                                     {...a11yProps(4)}
                                />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <ServerSideTable title={'מידע כללי'} columns={columns} url={'getCabsHomePage'}/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <AllPayments contractType={'supplier'}  />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <AllPayments contractType={'consumer'}  />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <AllPayments contractType={'allTypes'}  />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <ServerSideTable title={'חידוש רישיון החודש'} columns={vehicleTestTracker} url={'findVehicleWIthNearTestDate'}/>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
