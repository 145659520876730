import React, {useContext, useEffect} from 'react'
import PersonDetails from "./PersonDetails";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {Context as PersonContext} from "../../../context/PersonContext";
import Spinner from "../../../components/Spinner";

const SinglePerson = (props) =>{
    const slitted = props.location.pathname.split('/');
    const {state:{singlePerson},findPersonById} = useContext(PersonContext);
    useEffect(()=>{
        findPersonById(slitted[2])
    },[])
    return(
        <Card>
            <CardContent>
                {!singlePerson ?
                    <Spinner />
                    :
                    <Grid
                        spacing={3}
                        display="flex"
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        justify="center"
                    >
                        <PersonDetails perdonId={slitted[2]} title={singlePerson.fullName} type={'purePerson'} isExist />
                    </Grid>
                }

            </CardContent>
        </Card>

    )
}
export default SinglePerson;
