import React, {useContext, useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from "@material-ui/core/Box";
import {Context as NotificationContext} from "../../../context/NotificationContext";
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Context as AdminContext} from "../../../context/AdminContext"
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import AdminDialog from "./AdminDialog";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import UsersTable from "./UsersTable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import AppSnackbar from "../../../components/AppSnackbar";
import PeopleIcon from '@material-ui/icons/People';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import LocalSignatureDialog from "../single-cab/NewContract/LocalSignatureDialog";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '24%',
    },
    column1: {
        flexBasis: '5%',
    },
    column75: {
        flexBasis: '71%',
    },
    column100: {
        flexBasis: '100%',
        boxShadow: "none"
    },
    columnHeader:{
        flexBasis:'18%'
    },
    tailOfColumnHeader:{
        flexBasis:'14%'
    },
    helper: {
        borderRight: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    component:{
        boxShadow: "none"
    },
    directionRow:{
        display:'flex',
        flexDirection:'row',
        justifyContent: 'flex-start'
    }
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function AdminPage({source}) {
    const classes = useStyles();
    const {state:{companies,usersTable,resMessage,sucsessAddSignature},getCompanies,showUsersTable} = useContext(AdminContext);
    const {pipeMessage} = useContext(NotificationContext);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const isSuperAdmin= useRef(localStorage.getItem("super_admin"));
    const handleListItemClick = (event, index,value) => {
        setSelectedIndex(index);
    };
    useEffect(() =>{

        showUsersTable(false)
        getCompanies();
    },[])
    useEffect(() =>{
        if(sucsessAddSignature)
            pipeMessage('חתימת החברה נשמרה בהצלחה')
    },[sucsessAddSignature])

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const shoeUsers = () =>{
        showUsersTable(true)
    }
    const divSummeryLayout = (label,value) =>{
        return (
            <div className={classes.columnHeader}>

                <Typography className={classes.secondaryHeading}>{label}</Typography>
                <Typography className={classes.heading}>{value}</Typography>
            </div>
        )
    }
    const beautifyDate =(date) =>{
        return moment(date).format('YYYY-MM-DD');
    }
    const divSummeryDetails =(object,companyIndex) =>{
        return (
            <Grid container className={classes.root}>
                <Grid item xl={12} xs={12}>
                    <Grid container>
                        <Grid item xs={1}>
                            <AdminDialog companyGovId={object.companyGovId} requestType ="createAgency" title=" צור סוכנות " compId={object.id} indexNumber={companyIndex}   />
                        </Grid>
                        <Grid item xs={1}>
                            <LocalSignatureDialog conTitle={'חתימת החברה'} conType ={'COMPANY_SIGNATURE'} />
                        </Grid>
                    </Grid>

                    <br/>
                    <Divider />
                    <br/>
                </Grid>
                <Grid item xl={2} xs={2}>
                    <List component="nav">
                        {
                            object.agencies?.map((value,index) => {
                                return (
                                    <ListItem
                                        button
                                        selected={selectedIndex === index}
                                        onClick={(event) => handleListItemClick(event, index,value.name)}
                                    >
                                        <ListItemIcon>
                                            <EmojiTransportationIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={value.name} />
                                    </ListItem>
                                );
                            })

                        }
                    </List>
                </Grid>
                <Grid item xl={10} xs={10}>
                    {object.agencies?.map((valueBody,indexBody) => {
                        return (
                            <div className={classes.directionRow}>
                                {
                                    selectedIndex === indexBody ?

                                        <Grid container spacing={1} style={{textAlign: 'center'}}>
                                            {
                                                usersTable === false ?
                                                <>
                                                <Grid item xs={3}>
                                                    <Typography color="primary">שם הסוכנות </Typography><Typography>{valueBody.name}</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography color="primary">תאריך יצירה </Typography><Typography>{beautifyDate(valueBody.createdOn)}</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                <Typography color="primary">סטטוס </Typography><Typography>{valueBody.status}</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                                        <IconButton onClick={shoeUsers} aria-label="users"><PeopleIcon color="primary" /></IconButton>
                                                        <AdminDialog indexNumber={companyIndex} requestType ="updateAgency" title="ערוך סוכנות" oldName={valueBody.name} agencyIndex={indexBody} agencyId={valueBody.id} />
                                                        <AdminDialog indexNumber={companyIndex} requestType ="deleteAgency" title="מחק סוכנות"  oldName={valueBody.name} agencyIndex={indexBody} agencyId={valueBody.id} />
                                                    </ButtonGroup>
                                                </Grid>

                                                <Grid item xl={12} xs={12}>
                                                </Grid>
                                                </>
                                                :
                                                <Grid item xl={12} xs={12}>
                                                <UsersTable
                                                    compId={valueBody.company_id}
                                                    name={valueBody.name}
                                                    createdOn={valueBody.createdOn}
                                                    status={valueBody.status}
                                                    agencyId={valueBody.id}
                                                    users={valueBody.users}
                                                    agencyIndex={indexBody}
                                                    companyIndex={companyIndex}
                                                />
                                                </Grid>
                                            }
                                        </Grid>
                                        : null
                                }
                            </div>
                        )
                    })}
                </Grid>

            </Grid>
        )
    }
    return (
        <div className={classes.root}>
            {resMessage ? <AppSnackbar openFromOut={true} message={resMessage} /> : null}
            {isSuperAdmin.current === "true" ?
                <Grid
                    spacing={2}
                    display="flex"
                    container
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    justify="flex-end"

                >
                    <Grid
                        item
                        xs={3}
                    >
                        <AdminDialog  requestType ="createCompany" title="צור ארגון" />

                    </Grid>
                </Grid>
                :
                null
            }

            {companies?.map((val,index) =>{
        return <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                    >

                        <Grid container>
                            <Grid item xs={3}>{divSummeryLayout('מספר ארגון',val.id)}</Grid>
                            <Grid item xs={3}>{divSummeryLayout('שם ארגון',val.name)}</Grid>
                            <Grid item xs={3}>{divSummeryLayout('מספר חברה',val.companyGovId)}</Grid>
                            <Grid item xs={3}>{divSummeryLayout('תאריך יצירה',beautifyDate(val.createdOn))}</Grid>
                        </Grid>
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                                <Tooltip title={'צפה בחותמת חברה'} >
                                    <AdminDialog companyGovId={val.companyGovId} compId={val.id} indexNumber={index} requestType ="viewCompSignature" title="חותמת חברה" oldName={''} />
                                </Tooltip>
                            }
                            label=""
                        />
                        {isSuperAdmin.current === "true" ?
                            <>
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                    <AdminDialog companyGovId={val.companyGovId} compId={val.id} indexNumber={index} requestType ="updateCompany" title="ערוך ארגון" oldName={val.name} />
                                }
                                label=""
                            />
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                    <AdminDialog companyGovId={val.companyGovId} compId={val.id} indexNumber={index} requestType ="deleteCompany" title="מחק ארגון" oldName={val.name} />
                                }
                                label=""
                            />

                            </>
                            :
                            null
                        }

                    </AccordionSummary>
                    <AccordionDetails>
                        {divSummeryDetails(val,index)}
                    </AccordionDetails>
                </Accordion>
            })}
        </div>
    );

}
