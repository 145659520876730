import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi';

const paymentReducer =(state,action) =>{
    switch(action.type){
        case 'set_payment_mode_bdeal':
            return{
                ...state,
                paymentModeBdeal:action.payload
            }
         case 'set_payment_mode_sdeal':
            return{
                ...state,
                paymentModeSdeal:action.payload
            }

        case 'update_comment':
            return{
                ...state,
                paymentComment:action.payload
            }
        case 'create_buy_vehicle_payment_outcome':

            return{
                ...state,
                buyVehiclePaymentOutcome:action.payload
            }
        case 'create_sale_vehicle_payment_income':

            return{
                ...state,
                saleVehiclePaymentIncome:action.payload
            }
        default: return state;
    }
}

const setPayment = dispatch =>async(data) =>{
    const response = await InterceptorApi().post('/api/createVehiclePayment', data);
    dispatch({type: 'create_buy_vehicle_payment_outcome', payload:response.data});
}
const setPaymentSaleDeal = dispatch =>async(data) =>{
    const response = await InterceptorApi().post('/api/createVehiclePayment', data);
    dispatch({type: 'create_sale_vehicle_payment_income', payload:response.data});
}
const setPaymentModeBuyVehicle = dispatch =>(bol) =>{
    dispatch({type:'set_payment_mode_bdeal',payload:bol})
}
const setPaymentModeSaleVehicle = dispatch =>(bol) =>{
    dispatch({type:'set_payment_mode_sdeal',payload:bol})
}
const updatePaymentComment = dispatch =>async(data,moreData) =>{
    const obj={id:data.id,comment:data.comment}
    const response = await InterceptorApi().put('/api/updatePaymentComment', obj);
    dispatch({type: 'update_comment', payload: obj});
}
export const {Provider,Context} =createDataContext(
    paymentReducer,
    {setPaymentModeBuyVehicle,setPaymentSaleDeal,setPaymentModeSaleVehicle,setPayment,updatePaymentComment},
    []
)
