
import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi';
const createContractReducer = (state,action) =>{
    switch (action.type) {
        case 'set_person_and_cab' :
            return {
                ...state,
                cabId:action.payload.cabId,
                personId:action.payload.personId
            }
        case 'set_contract_type':
            return {
                ...state,
                contractType:action.payload
            }
        case 'set_digit':
            return {
                ...state,
                numberSequence:action.payload
            }
        case 'fetch_driver_details':
            return {
                ...state,
                driverDetails:action.payload,
                personId:action.payload.id

            }
        case 'init_dates':
            return{
                ...state,
                conAndPayDates:action.payload
            }
        case 'set_is_contract_create_mode':
            return{
                ...state,
                isContractCreateMode:action.payload
            }
        case 'set_date_payment_cycle':
            return{
                ...state,
                datePaymentCycle:action.payload
            }
        case 'set_payment_amount':
            return{
                ...state,
                paymentAmount:action.payload
            }
        case 'set_date_first_payment':
            return{
                ...state,
                dateFirstPayment:action.payload
            }
        case 'set_start_date':
            return{
                ...state,
                startDate:action.payload
            }
        case 'set_end_date':
            return{
                ...state,
                endDate:action.payload
            }
        case 'set_num_payments':
            return{
                ...state,
                numPayments:action.payload
            }
        case 'set_payment_type':
            return{
                ...state,
                paymentType:action.payload
            }
        case 'set_data_url':
            return{
                ...state,
                dataUrlState:action.payload
            }
        case 'set_create_con_res':
            return{
                ...state,
                createContractResponse:action.payload.contract,
                conInProcess:"ENDED",
                dataCabFromRes:action.payload.cab,
                conversionStatus:action.payload.conversionStatus,
                foundFuture:null,
                errorRaised:null
            }
        case 'set_create_con_res_found_future':

            return{
                ...state,
                conInProcess:"ENDED",
                foundFuture:!state.foundFuture
            }
        case 'set_create_con_res_error':

            return{
                ...state,
                conInProcess:"ENDED",
                errorRaised:!state.errorRaised
            }
        case 'contract_in_process_mode':
            return{
                ...state,
                conInProcess:action.payload
            }
        case 'signature_submitted':
            return{
                ...state,
                signatureSubmittedValue:true
            }
        case 'person_id' :

            return{
                ...state,
                personId:action.payload
            }
        default: return null
    }
}

const setPersonIdAndCabId = dispatch =>(personId,cabId) =>{
    dispatch({type:'set_person_and_cab',payload: {personId,cabId}})
}
const submitNewContract = dispatch =>async(dataUrlState,paymentType,numPayments,endDate,startDate,dateFirstPayment,paymentAmount,datePaymentCycle,cabId,personId,contractType) =>{
     dispatch({type:'contract_in_process_mode',payload:true});
     try{
         const response = await InterceptorApi().post('/api/createNewContract',{dataUrlState,paymentType,numPayments,endDate,startDate,dateFirstPayment,paymentAmount,datePaymentCycle,cabId,personId,contractType});
         if(response.data && response.data.foundFuture){
             dispatch({type:'set_create_con_res_found_future',payload: {}})
         }else{
             dispatch({type:'set_create_con_res',payload: {cab:response.data.cab, contract:response.data.contract, conversionStatus:response.data.conversionStatus}})
         }
     }catch (e) {
         dispatch({type:'set_create_con_res_error',payload: {}})
     }



}
const setSignature = dispatch =>(dataUrl) =>{
    dispatch({type:'set_data_url',payload:dataUrl})
}
const setPaymentAmount= dispatch =>(num) =>{
    dispatch({type:'set_payment_amount',payload:num})
}
const setDatePaymentCycle= dispatch =>(date) =>{
    dispatch({type:'set_date_payment_cycle',payload:date})
}
const setDateFirstPayment= dispatch =>(date) =>{
    dispatch({type:'set_date_first_payment',payload:date})
}
const setStartDate= dispatch =>(date) =>{
    dispatch({type:'set_start_date',payload:date})
}
const setEndDate= dispatch =>(date) =>{
    dispatch({type:'set_end_date',payload:date})
}
const setNumPayments= dispatch =>(date) =>{
    dispatch({type:'set_num_payments',payload:date})
}
const setPaymentType= dispatch =>(type) =>{
    dispatch({type:'set_payment_type',payload:type})
}
const setContractType= dispatch =>(type) =>{
    dispatch({type:'set_contract_type',payload:type})
}
const setIsContractCreateMode =dispatch => (value) =>{
    dispatch({type:'set_is_contract_create_mode',payload:value})
}
const checkAndSetDriverByNationalId = dispatch => async (natId) =>{
    dispatch({type:'set_digit',payload:natId})
    if(natId.length>8){
        if(is_israeli_id_number(natId)){
            const response = await InterceptorApi().get(`/api/findPersonByNationalId/${natId}`);

            dispatch({type:'fetch_driver_details',payload:response.data})
        }
    }
}
const signatureSubmitted = dispatch =>() =>{
    dispatch({type:'signature_submitted',payload:null})

}
const initiateDatesForContract = dispatch =>() =>{
  const startDate = new Date();
  const firstPaymentDate = new Date();
  const endDate = new Date(startDate.setFullYear(startDate.getFullYear() + 1));
  dispatch({type:'init_dates',payload:{startDate,firstPaymentDate,endDate}})
}

const setDate =dispatch =>(ref,date) =>{
    dispatch({type:'set_date',payload:{ref,date}})
}
const setPersonId =dispatch => (id) =>{
    dispatch({type:'person_id',payload:id})
}
const is_israeli_id_number= (id)=> {
    id = String(id).trim();
    if (id.length > 9 || isNaN(id) || id.length<2) return false;
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
    return Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
    }) % 10 === 0;
}

export const {Provider,Context} = createDataContext(
    createContractReducer,
    {signatureSubmitted,submitNewContract,setNumPayments,setEndDate,setStartDate,setDateFirstPayment,setDatePaymentCycle,setPaymentAmount,
        setPaymentType,setIsContractCreateMode,setContractType,checkAndSetDriverByNationalId,
        initiateDatesForContract,setSignature,setPersonIdAndCabId,setPersonId},
    []
)
