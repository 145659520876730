import React, {useContext, useEffect, useState} from 'react';
import {Context as VehicleContractContext} from "../../../../context/VehicleContractContext"
import {Context as VehiclesContext} from '../../../../context/VehiclesContext'
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Typography from "@material-ui/core/Typography";
import InterceptorApi from "../../../../api/InterceptorApi";
import {Context as NotificationContext} from "../../../../context/NotificationContext";

const SubmitNewContract = () =>{
    const {pipeMessage} =useContext(NotificationContext);
    const {setVehicleAfterMajorChange} = useContext(VehiclesContext)
    const {state:{dataChanged,violationFeeValue,commentValue,vehicleLicense,dataUrlState,paymentType,contractDate,kilometersOnSignContractDate,singlePersonSeller,
        singlePersonBuyer,deliveryDate,paymentAmount,personId,contractType,conInProcess,createContractResponse,lastTreatDate,vehicleForContract,upfrontPaymentValue,numPayments,conversionStatus},
        submitNewContract} = useContext(VehicleContractContext);

    const handleClick = () =>{
        setConPState(true)
        submitNewContract(singlePersonSeller,singlePersonBuyer,contractDate,kilometersOnSignContractDate,dataUrlState,violationFeeValue,commentValue,
            paymentType,paymentAmount,personId,contractType,deliveryDate,lastTreatDate,vehicleLicense,vehicleForContract,upfrontPaymentValue);
    }
    const displayContract = () =>{
      if(createContractResponse && createContractResponse.id){
          let f;
          let contractId;
          if(contractType ==='buyVehicle'){
              contractId = createContractResponse.vehicleBook.buyDeal.buyContract.id;
            f=InterceptorApi().get(`/api/getVehicleDocument?vId=${createContractResponse.id}&cId=${contractId}&dId=${createContractResponse.vehicleBook.buyDeal.id}&dType=buyVehicleContract`,{responseType: 'blob'})
          }else {
              contractId = createContractResponse.vehicleBook.saleDeal.saleContract.id;
              f=InterceptorApi().get(`/api/getVehicleDocument?vId=${createContractResponse.id}&cId=${contractId}&dId=${createContractResponse.vehicleBook.saleDeal.id}&dType=saleVehicleContract`,{responseType: 'blob'})

          }
          f.then((res) =>{
              if(!res.data){
                  alert('לא מצאנו חוזה במערכת. נא פנה לתמיכה');
                  return;
              }
              const file = new Blob([res.data], {type: 'application/pdf'});
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
          })
      }else{
          alert('חוזה עדיין לא מוכן מוכן');
      }

    }
   const[conPState,setConPState] = useState(null)
    useEffect(()=>{
        if(conInProcess ==="ENDED"){
            setVehicleAfterMajorChange(createContractResponse)
            setConPState(conInProcess);
            pipeMessage('חוזה נוצר בהצלחה')
        }else if(conInProcess ==="ENDED_WITH_FAILURE"){
            setConPState(conInProcess);
            pipeMessage(`${createContractResponse} אנא השלם שדה `,"error")
        }
    },[dataChanged]);

    return (
        !conPState ?
            <>
                {!dataUrlState ?
                    <Alert severity="warning">
                        <AlertTitle>ליצירת חוזה יש לחתום על החוזה תחילה</AlertTitle>
                    </Alert>
                    :
                    <Alert severity="info">
                        <AlertTitle>הכל מוכן</AlertTitle>
                        <Button onClick={handleClick} color="primary">לחץ כאן כדי ליצור חוזה</Button>
                    </Alert>
                }

            </>
            :
            conPState && conPState === true ?
            <>
                <LinearProgress />
                <Alert severity="info">
                    <AlertTitle>מעבד נתונים</AlertTitle>
                    <Typography color="primary">אנא המתן</Typography>
                </Alert>
            </>
            :
            conPState ==="ENDED" ?
            <>

                <LinearProgress variant="determinate" value={100} />
                <Alert severity="success">
                    <AlertTitle>הסתיים</AlertTitle>
                    {'חוזה מוכן'}
                    <Button onClick={displayContract} color="primary">הצג חוזה</Button>
                </Alert>
            </>
            :
            conPState ==="ENDED" && conversionStatus==="EXECUTION-ERROR" ?
            <>
                <Alert severity="error">
                    <AlertTitle>שגיאת יצירת PDF</AlertTitle>
                    <Button onClick={handleClick} color="warning">התהליך הושלם בהצלחה אך החוזה לא הומר ל-PDF</Button>
                </Alert>
            </>
            :
            <>
                <Alert severity="info">
                    <AlertTitle>הכל מוכן</AlertTitle>
                    <Button onClick={handleClick} color="primary">לחץ כאן כדי ליצור חוזה</Button>
                </Alert>
            </>
    )

}
export default SubmitNewContract;
