import React, {useContext, useState} from 'react';
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ServerSideTable from "../../../components/ServerSideTable";
import PersonDetails from "./PersonDetails";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {Context as PersonContext} from "../../../context/PersonContext";

const renderRow=(val) =>{
    return <Typography variant="body2">{val}</Typography>
}
const switchLink = (colData,id, linking) =>{
    return (
        <Typography variant="body2">
            <Link
                to={{pathname: `/people/${id}`,state: {extraData:{id: id}}}}
                style={{ textDecoration: 'none',color:'blue'}}>{colData}
            </Link>
        </Typography>
    )
}

const PeopleComponent = (props) =>{
    const columns =[
        {
            title: 'שם',
            field: 'fullName',
            render: rowData => rowData && rowData.fullName ? switchLink(rowData.fullName,rowData.id,props.linking) : " "
        },
        {
            title: 'ת.זהות',
            field: 'nationalId',
            render: rowData => rowData && rowData.nationalId ? renderRow(rowData.nationalId) : " "
        },
        {
            title: 'טלפון ראשי',
            field: 'phoneOne',
            render: rowData => rowData && rowData.phoneOne ? renderRow(rowData.phoneOne) : " "
        },
        {
            title: 'טלפון משני',
            field: 'phoneTwo',
            render: rowData => rowData && rowData.phoneTwo ? renderRow(rowData.phoneTwo) : renderRow('--')

        },
        {
            title: 'כתובת',
            field: 'fullAddress',
            render: rowData => rowData && rowData.fullAddress ? renderRow(rowData.fullAddress) : renderRow('--')

        },
        {
            title: 'דואר אלקטרוני',
            field: 'emailAddress',
            render: rowData => rowData && rowData.emailAddress ? renderRow(rowData.emailAddress) : renderRow('--')


        },
        {
            title: 'תאריך יצירה',
            field: 'createdOn',
            render:rowData =>moment(rowData.createdOn).format('YYYY-MM-DD HH:mm:ss')

        },
        {
            title: 'הערות',
            field: 'comment'
        },
    ]
    const [state, setState] =useState(false);
    const {clearPersonObject} = useContext(PersonContext);
    const addNewPersonForm =(val) => {
        clearPersonObject();
        setState(val)
    };
    return (
        <>
            <Grid style={{marginBottom:'5px'}} container justify="flex-end" item xs={12}>
                <Button onClick={() =>addNewPersonForm(true)} variant="contained" color="primary" size={'small'} >
                    הוסף חדש
                </Button>
            </Grid>
            <Card>
                <CardContent>
                    {state === true ?
                        <Grid item xs={2}>
                            <IconButton color="primary" onClick={() =>addNewPersonForm(false)}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Grid>
                        :
                        null
                    }

                    {state === false ?
                        <Grid style={{marginTop:'5px'}} item xs={12}>
                            <ServerSideTable
                                initialFilter={'noFilter'}
                                title={'אנשים'}
                                columns={columns}
                                url={'findPersons'}
                            />
                        </Grid>
                        :
                        <Grid
                            spacing={3}
                            display="flex"
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            justify="center"
                        >
                            <PersonDetails title={'הוסף אדם'} type={'purePerson'} />
                        </Grid>
                    }
                </CardContent>
            </Card>
        </>
    );
}
export default PeopleComponent;
