import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ContractSummary from "./ContractSummary";
import Spinner from "../../../../components/Spinner";
import IconButton from "@material-ui/core/IconButton";
import {Context as CreateContractContext} from "../../../../context/CreateContractContext";
import SubmitNewContract from "./SubmitNewContract";
import LocalSignatureDialog from "./LocalSignatureDialog";
import {Context as SingleCabContext} from "../../../../context/SingleCabContext";
import PersonFormAccomplishment from "./PersonFormAccomplishment";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CreateContractContractDetails from "./CreateContractContractDetails";
import SignatureCanvas from "./SignatureCanvas.js";

const useStyles = makeStyles((theme) => ({
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['בחירת סוג חוזה','פרטי בעלים\\\נהג','פרטי חוזה','סיכום','חתימות'];
}

export default function StepperComponent(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const {state:{paymentAmount,contractType,paymentType,numPayments,endDate,startDate,dateFirstPayment,datePaymentCycle,signatureSubmittedValue},setPaymentAmount,setContractType} = useContext(CreateContractContext);
    const {state:{dataCab},setActiveComponent} = useContext(SingleCabContext);

    useEffect(()=>{
        if(props.source &&( props.source === 'ownerRenewal' || props.source==='driverRenewal')){
            setActiveStep(1);
            setContractType(props.source);
        }
    },[])
    const handleRadioContractTypeChange = (event) => {
        setContractType(event.target.value);
    };
    const getStepContent = (stepIndex)=> {

        switch (stepIndex) {
            case 0:
                return (
                    <Grid
                        spacing={1}
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Grid item xs={12}>
                            <FormLabel>בחר סוג חוזה</FormLabel>
                        </Grid>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                        <Grid item xs={12} >
                            <FormControl>
                                <RadioGroup aria-label="gender" name="contractType" value={contractType} onChange={e =>handleRadioContractTypeChange(e)}>
                                    <FormControlLabel value="owner" control={<Radio />} label="חוזה מול בעל הזכות" />
                                    <FormControlLabel value="driver" control={<Radio />} label="חוזה מול נהג" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )

            case 1 : return(
                <Grid
                    spacing={3}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    textAlign="center"
                >
                    <Grid item xs={12}>
                        <FormLabel component="legend">{contractType ==='owner'|| contractType ==='ownerRenewal' ? 'פרטי בעלים':'פרטי נהג'}</FormLabel>
                        <Divider />
                    </Grid>
                    <PersonFormAccomplishment />
                </Grid>
            )

            case 2:
                return (
                    <Grid
                        spacing={3}
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Grid item xs={12}>
                            <FormLabel component="legend">תנאי החוזה</FormLabel>
                            <Divider />
                        </Grid>
                    <CreateContractContractDetails />
                    </Grid>
                )
            case 3:
                return (

                    <Grid
                        spacing={3}
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Grid item xs={12}>
                            <FormLabel>סיכום</FormLabel>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <ContractSummary />
                        </Grid>
                    </Grid>
                )
            case 4:
                return (
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={8}>
                            <FormLabel component="legend">חתימה</FormLabel>
                            <Divider />
                            <br/>
                            {/*<LocalSignatureDialog conType={'CAB'} />*/}
                            <SignatureCanvas conType={'CAB'}  />
                        </Grid>
                    </Grid>
                )
            case 5:
                return (
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <Spinner />
                        </Grid>
                    </Grid>
                )
            case 6:
                return (
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <SubmitNewContract />
                        </Grid>
                    </Grid>
                )
            default:
                return (
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <SubmitNewContract />
                        </Grid>
                    </Grid>
                )
        }
    }
    const steps = getSteps();

    const handleNext = () => {
        if(activeStep===0 && !contractType){
            alert('יש לבחור את סוג החוזה תחילה');
            return;
        }else if(activeStep===1 && contractType==='owner'){
            if(!dataCab.supplier.fullName || dataCab.supplier.fullName ===''){
                alert('נא למלא את השם המלא של בעל הזכות');
                return;
            }
            if(!dataCab.supplier.nationalId || dataCab.supplier.nationalId===''){
                alert('נא למלא את מספר הזהות של בעל הזכות ולעדכן תחילה');
                return;
            }
            if(!dataCab.supplier.nationalId || dataCab.supplier.nationalId===''){
                alert('נא לעדכן את מספר הזהות של בעל הזכות ולעדכן תחילה');
                return;
            }
            if(!dataCab.supplier.phoneOne || dataCab.supplier.phoneOne===''){
                alert('נא לעדכן לפחות מספר טלפון אחד');
                return;
            }
        }else if(activeStep ===2 &&( !paymentType || !numPayments ||
            !endDate || !startDate || !dateFirstPayment || !paymentAmount || !datePaymentCycle)){
            alert('יש למלא את כל פרטי החוזה');
            return;
        }else if(activeStep ===4 && !signatureSubmittedValue){
            alert('יש לחתום ולאשר לפני מעבר לשלב הבא');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if(props.source &&( props.source === 'ownerRenewal' || props.source==='driverRenewal') && activeStep ===1){
           return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        if(props.source &&( props.source === 'ownerRenewal' || props.source==='driverRenewal') && activeStep ===1){

            setActiveStep(1);
        }else{
            setActiveStep(0);
        }

    };

    return (
        <Card>
        <CardContent>
            <Grid
                container
            >
                <Grid item xs={2}>
                    <IconButton color="primary" onClick={() =>setActiveComponent("MAIN")}>
                        <ArrowForwardIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={8}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
            </Grid>

        <div className={classes.root}>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={8}>
                                <SubmitNewContract />
                            </Grid>
                        </Grid>
                        <Button onClick={handleReset}>איפוס</Button>
                    </div>
                ) : (
                    <div>
                        <Grid
                            spacing={1}
                            display="flex"
                            container
                            direction={ "column" }
                            justifyContent="center"
                            alignItems="center"
                            justify="center"

                        >
                            <Grid item xs={12}>
                                {getStepContent(activeStep)}
                            </Grid>
                        </Grid>

                        <div>

                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                            >
                                חזור
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleNext}>
                                { 'הבא'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
        </CardContent>
        </Card>
    );
}
