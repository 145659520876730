
import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi';
const vehicleContractReducer = (state,action) =>{
    switch (action.type) {
        case 'init_state':
            return null;
        case 'set_vehicle_for_contract' :
            return {
                ...state,
                vehicleForContract:action.payload,
            }
        case 'set_contract_type':
            return {
                ...state,
                contractType:action.payload
            }
        case 'set_digit':
            return {
                ...state,
                numberSequence:action.payload
            }
        case 'init_dates':
            return{
                ...state,
                conAndPayDates:action.payload
            }
        case 'set_is_contract_create_mode':
            return{
                ...state,
                isContractCreateMode:action.payload
            }
        case 'set_date_payment_cycle':
            return{
                ...state,
                datePaymentCycle:action.payload
            }
        case 'set_payment_amount':
            return{
                ...state,
                paymentAmount:action.payload
            }
        case 'set_date_first_payment':
            return{
                ...state,
                dateFirstPayment:action.payload
            }
        case 'set_contract_date':
            return{
                ...state,
                contractDate:action.payload
            }
        case 'set_delivery_date':
            return{
                ...state,
                deliveryDate:action.payload
            }
        case 'set_num_payments':
            return{
                ...state,
                numPayments:action.payload
            }
        case 'set_payment_type':
            return{
                ...state,
                paymentType:action.payload
            }
        case 'set_data_url':
            return{
                ...state,
                dataUrlState:action.payload
            }
        case 'set_receive_vehicle_url':
            return{
                ...state,
                dataUrlReceiveVehicleState:action.payload
            }
        case 'set_deliver_vehicle_url':
            return{
                ...state,
                dataUrlDeliverVehicleState:action.payload
            }
        case 'set_create_con_res':

            return{
                ...state,
                createContractResponse:action.payload,
                conInProcess:"ENDED",
                dataChanged: !state.dataChanged
            }
        case 'set_create_con_res_missing_params':

            return{
                ...state,
                createContractResponse:action.payload,
                conInProcess:"ENDED_WITH_FAILURE",
                dataChanged: !state.dataChanged
            }
        case 'contract_in_process_mode':
            return{
                ...state,
                conInProcess:action.payload
            }
        case 'person_id' :

            return{
                ...state,
                personId:action.payload
            }
        case 'person_id_buyer':

            return{
                ...state,
                singlePersonBuyer:action.payload,
            }
        case 'person_id_seller':
            return{
                ...state,
                singlePersonSeller:action.payload,
            }
        case 'set_upfront_payment':
            return{
                ...state,
                upfrontPaymentValue:action.payload,
            }
        case 'set_kilometers_con_date':
            return{
                ...state,
                kilometersOnSignContractDate:action.payload,
            }
            case 'last_treat_date':
            return{
                ...state,
                lastTreatDate:action.payload,
            }
            case 'set_vehicle_license':
            return{
                ...state,
                vehicleLicense:action.payload,
            }
            case 'set_vehicle_imgs':
            return{
                ...state,
                vehicleImgs:action.payload,
            }
        case 'set_violation_fee':
            return{
                ...state,
                violationFeeValue:action.payload,
            }
        case 'set_comments':
            return{
                ...state,
                commentValue:action.payload,
            }
        default: return null
    }
}
const initState = dispatch => () =>{
    dispatch({type:'init_state',payload:true})
}
const setCommentsForContract  = dispatch => (comments) =>{
    dispatch({type:'set_comments',payload:comments})
}

const setViolationFee  = dispatch => (fee) =>{
    dispatch({type:'set_violation_fee',payload:fee})
}

const setVehicleLicense = dispatch =>(dataArray,docType) =>{
    if(docType==='vDocs')
         dispatch({type:'set_vehicle_license',payload:dataArray})
    else
        dispatch({type:'set_vehicle_imgs',payload:dataArray})
}
const setLastTreatDate = dispatch => (value) =>{
    dispatch({type:'last_treat_date',payload:value})
}
const setKilometersOnSignContractDate = dispatch =>(value) =>{
    dispatch({type:'set_kilometers_con_date',payload:value})
}
const setVehicleForContract=dispatch =>(vehicle) =>{
    dispatch({type:'set_vehicle_for_contract',payload:vehicle})
}

const setUpfrontPayment =dispatch => (value) =>{
    dispatch({type:'set_upfront_payment',payload:value})
}
const setPersonIdBuyer =dispatch => (id) =>{

    dispatch({type:'person_id_buyer',payload:id})
}
const setPersonIdSeller =dispatch => (id) =>{
    dispatch({type:'person_id_seller',payload:id})
}

const submitNewContract = dispatch =>async(singlePersonSeller,singlePersonBuyer,contractDate,kilometersOnSignContractDate,dataUrlState,violationFeeValue,commentValue,
                                           paymentType,paymentAmount,personId,contractType,deliveryDate,lastTreatDate,vehicleLicense,vehicleForContract,upfrontPaymentValue,numPayments) =>{

    const obj ={
        singlePersonSeller,
        singlePersonBuyer,
        contractDate,
        kilometersOnSignContractDate ,
        dataUrlState ,
        paymentType ,
        paymentAmount ,
        personId,
        contractType,
        deliveryDate,
        lastTreatDate,
        vehicleForContract,
        violationFeeValue,
        commentValue ,
        upfrontPaymentValue,
        numPayments
    }
    let response;
    if( contractType ==='saleVehicle'){
        response = await InterceptorApi().post('/api/createNewSaleVehicleContract', {obj});
    }else{
        response =  await InterceptorApi().post('/api/createNewBuyVehicleContract', {obj});
    }
    if(response.data && response.data.missingValue){
        dispatch({type:'set_create_con_res_missing_params',payload:response.data.missingValue})
    }else{
        dispatch({type:'set_create_con_res',payload:response.data.car})
    }


}
const setDeliverVehicleSiganture = dispatch =>(dataUrl) =>{
    dispatch({type:'set_deliver_vehicle_url',payload:dataUrl})
}
const setReceiveVehicleSignature = dispatch =>(dataUrl) =>{
    dispatch({type:'set_receive_vehicle_url',payload:dataUrl})
}
const setVehicleContractSignature = dispatch =>(dataUrl) =>{
    dispatch({type:'set_data_url',payload:dataUrl})
}
const setPaymentAmount= dispatch =>(num) =>{
    dispatch({type:'set_payment_amount',payload:num})
}
const setDatePaymentCycle= dispatch =>(date) =>{
    dispatch({type:'set_date_payment_cycle',payload:date})
}
const setDateFirstPayment= dispatch =>(date) =>{
    dispatch({type:'set_date_first_payment',payload:date})
}
const setContractDate= dispatch =>(date) =>{
    dispatch({type:'set_contract_date',payload:date})
}
const setDeliveryDate= dispatch =>(date) =>{
    dispatch({type:'set_delivery_date',payload:date})
}
const setVehicleNumPayments = dispatch =>(num,v) =>{
    dispatch({type:'set_num_payments',payload:num})
}

const setVehiclePaymentType= dispatch =>(type) =>{
    dispatch({type:'set_payment_type',payload:type})
}
const setContractType= dispatch =>(type) =>{
    dispatch({type:'set_contract_type',payload:type})
}
const setIsContractCreateMode =dispatch => (value) =>{
    dispatch({type:'set_is_contract_create_mode',payload:value})
}
const checkAndSetDriverByNationalId = dispatch => async (natId) =>{
    dispatch({type:'set_digit',payload:natId})
    if(natId.length>8){
        if(is_israeli_id_number(natId)){
            const response = await InterceptorApi().get(`/api/findPersonByNationalId/${natId}`);

            dispatch({type:'fetch_driver_details',payload:response.data})
        }
    }
}
// const findPersonByMultipleValues = dispatch => async (value) =>{
//     const response = await InterceptorApi().get(`/api/findPersonByMultipleValues/${value}`);
//     console.log('##########');console.log(response.data);console.log('#########');
//     dispatch({type:'persons_list',payload:response.data});
// }
const initiateDatesForContract = dispatch =>() =>{
    const startDate = new Date();
    const firstPaymentDate = new Date();
    const endDate = new Date(startDate.setFullYear(startDate.getFullYear() + 1));
    dispatch({type:'init_dates',payload:{startDate,firstPaymentDate,endDate}})
}

const setPersonId =dispatch => (id) =>{
    dispatch({type:'person_id',payload:id})
}
const is_israeli_id_number= (id)=> {
    id = String(id).trim();
    if (id.length > 9 || isNaN(id) || id.length<2) return false;
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
    return Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
    }) % 10 === 0;
}

export const {Provider,Context} = createDataContext(
    vehicleContractReducer,
    {setDeliverVehicleSiganture,initState,submitNewContract,setLastTreatDate,setVehicleForContract,setVehicleNumPayments,setDeliveryDate,setContractDate,setDateFirstPayment,setDatePaymentCycle,setPaymentAmount,
        setVehicleLicense, setVehiclePaymentType,setIsContractCreateMode,setContractType,checkAndSetDriverByNationalId,
        initiateDatesForContract,setViolationFee,setCommentsForContract,setKilometersOnSignContractDate,setVehicleContractSignature,setReceiveVehicleSignature,setPersonId,setPersonIdBuyer,setPersonIdSeller,setUpfrontPayment},
    []
)
