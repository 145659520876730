import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
Breadcrumbs as MUIBreadCrumbs,
Link,
Typography
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import {withRouter} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        textDecoration:'none'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));
const  Breadcrumbs = props => {
    const lastIndexOfPath = (pathName) => {return keyValueTranslator(pathName)}
    const keyValueTranslator = (pathName) =>{
        switch (pathName){
            case 'home':
            case '/':
                return 'בית'
            case 'cabsowners': return 'ספקים'
            case 'cabs': return 'זכויות צבויריות'
            case 'drivers':return 'נהגים'
            case 'vehicles': return 'רכבים'
            case 'people': return 'אנשים'
            case 'generalVehiclePool': return 'מאגר רכבים כללי'
            case 'vehiclesFleet': return 'רכבי צי'
            case 'admin': return 'אדמין'
            default :return  pathName
        }
    }
    const classes = useStyles();
    const {history, location:{pathname}} = props
    const pathNames = pathname.split("/").filter(x => x)
    return (
        <MUIBreadCrumbs aria-label="breadcrumb">
            <Link onClick={() =>history.push("/")} className={classes.link}>
                <HomeIcon className={classes.icon} />
                בית
            </Link>
            {pathNames.map((name, index)=>{

                const routeTo = `/${pathNames.slice(0,index+ 1).join("/")}`
                const isLast = index === pathNames.length -1;
                return isLast
                    ?
                    <Typography>{lastIndexOfPath(name)}</Typography>
                    :
                    <Link onClick={() =>history.push(routeTo)} className={classes.link}>
                        {keyValueTranslator(name)}
                    </Link>
            })}
        </MUIBreadCrumbs>
    );
}
export default withRouter(Breadcrumbs)

