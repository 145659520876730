import React, {useContext,useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {Context as AdminContext} from "../../../context/AdminContext";
import Button from "@material-ui/core/Button";

export default function CreateCompany(props){
    const {createCompany,updateCompany,deleteCompany,createAgency,updateAgency,deleteAgency} = useContext(AdminContext);
    const [name, setName] = useState({name:props.oldName,companyGovId:props.companyGovId});
    const handleChange =(k,v) =>{
        setName({
            ...name,
            [k]:v
        })
    }
    const handleClick = () => {

        if(props.reqType==='createCompany')
            createCompany(name);
        else if(props.reqType==='updateCompany')
            updateCompany(name.name,props.compId,props.indexNumber)
        else if(props.reqType==='deleteCompany')
            deleteCompany(name.name,props.compId,props.indexNumber)
        else if(props.reqType==='createAgency')
            createAgency(name.name,props.compId,props.indexNumber)
        else if(props.reqType==='updateAgency')
            updateAgency(name.name,props.agencyId,props.indexNumber,props.agencyIndex)
        else if(props.reqType==='deleteAgency')
            deleteAgency(name.name,props.agencyId,props.indexNumber,props.agencyIndex)
    };
    return(
        <div>
            <TextField
                id="standard-basic"
                label="שם החברה"
                variant="standard"
                onChange={e=>handleChange("name",e.target.value)}
                value={name.name}
            />
            <br/> <br/>
            <TextField
                disabled={props.reqType ==='updateCompany'}
                id="standard-basic"
                label='ח"פ'
                variant="standard"
                onChange={e=>handleChange("companyGovId",e.target.value)}
                value={name.companyGovId}
            />
            <br/> <br/>
            <Button variant="contained" onClick={handleClick}>שמור</Button>

        </div>
    )
}
