import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Spinner from "../../../../components/Spinner";
import IconButton from "@material-ui/core/IconButton";
import {Context as VehicleContractContext} from "../../../../context/VehicleContractContext";
import {Context as VehiclesContext} from "../../../../context/VehiclesContext"
import LocalSignatureDialog from "../../single-cab/NewContract/LocalSignatureDialog";
import VehicleContractDetails from "./VehicleContractDetails";
import ContractPerson from "./ContractPerson";
import VehicleDetails from "../VehicleDetails";
import VehicleContractSummary from "./VehicleContractSummary";
import SubmitNewVehicleContract from "./SubmitNewVehicleContract";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    root:{
        minHeight: '22vw',

    }
}));

function getSteps(conType) {
    if(conType ==='saleVehicle')
        return ['פרטי רוכש','פרטי רכב','פרטי חוזה','סיכום','חתימות','בצע'];
        else
        return ['פרטי רכב','פרטי חוזה','סיכום','חתימות','בצע'];
}

export default function PurchaseVehicleContract(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const{state:{vehicle},setActiveComponent} = useContext(VehiclesContext);
    const {state:{contractType,conInProcess}, setContractType,setVehicleForContract} = useContext(VehicleContractContext);

    useEffect(()=>{
        if((props.typeOfContract ==='buyVehicle' || props.typeOfContract==='saleVehicle') && conInProcess !=="ENDED"&& conInProcess !=="ENDED_WITH_FAILURE"){

            setVehicleForContract(vehicle);
            setContractType(props.typeOfContract);
        }
    },[props])


    const steps = getSteps(contractType);
    const handleNext = () => {
        // if(activeStep===0 && !contractType){
        //     alert('יש לבחור את סוג החוזה תחילה');
        //     return;
        // }
        // else if(activeStep===1 && contractType==='owner'){
        //     if(!dataCab.supplier.fullName || dataCab.supplier.fullName ===''){
        //         alert('נא למלא את השם המלא של בעל הזכות');
        //         return;
        //     }
        //     if(!dataCab.supplier.nationalId || dataCab.supplier.nationalId===''){
        //         alert('נא למלא את מספר הזהות של בעל הזכות ולעדכן תחילה');
        //         return;
        //     }
        //     if(!dataCab.supplier.nationalId || dataCab.supplier.nationalId===''){
        //         alert('נא לעדכן את מספר הזהות של בעל הזכות ולעדכן תחילה');
        //         return;
        //     }
        //     if(!dataCab.supplier.phoneOne || dataCab.supplier.phoneOne===''){
        //         alert('נא לעדכן לפחות מספר טלפון אחד');
        //         return;
        //     }
        // }else if(activeStep ===2 &&( !paymentType || !numPayments ||
        //     !endDate || !startDate || !dateFirstPayment || !paymentAmount || !datePaymentCycle)){
        //     alert('יש למלא את כל פרטי החוזה');
        //     return;
        // }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const gridIteOne= (stepIndex,component) =>{
        return(
            <Grid
                spacing={3}
                container
                justify={'center'}
            >
                <Grid item xs={7}>
                    <Typography component="div">
                        <Box fontStyle="normal" m={1}>
                            {getSteps(contractType)[stepIndex]}
                        </Box>
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={7}>
                    {component}
                </Grid>
            </Grid>
        )
    }
    const getStepContent = (stepIndex)=> {
        switch (stepIndex) {

            case 0 : return(
                <>
                    {props.typeOfContract ==='saleVehicle' ?
                        gridIteOne(stepIndex,<ContractPerson contractType={contractType} />)
                        :
                        <>

                            {gridIteOne(stepIndex,<VehicleDetails vehicleId={vehicle.id} detailsForContract={true} />)}
                        </>
                    }
                </>
            )
            case 1:
                return (
                    <>
                        {props.typeOfContract ==='saleVehicle' ?
                            gridIteOne(stepIndex,<VehicleDetails detailsForContract={true} vehicleId={vehicle.id} />)
                            :
                            gridIteOne(stepIndex,<VehicleContractDetails />)
                        }
                    </>
                )
            case 2:
                return (
                    <>
                        {props.typeOfContract ==='saleVehicle' ?
                            gridIteOne(stepIndex,<VehicleContractDetails />)
                            :
                            gridIteOne(stepIndex,<VehicleContractSummary />)
                        }
                    </>
                )
            case 3:
                return (
                    <>
                    {props.typeOfContract ==='saleVehicle' ?
                            gridIteOne(stepIndex,<VehicleContractSummary />)
                            :
                            gridIteOne(stepIndex,<LocalSignatureDialog conType={'VEHICLE'} />)
                    }
                    </>

                )
            case 4:
                return (
                    <>
                        {props.typeOfContract ==='saleVehicle' ?
                            gridIteOne(stepIndex,<LocalSignatureDialog conType={'VEHICLE'} />)
                            :
                            gridIteOne(stepIndex,<SubmitNewVehicleContract />)
                        }
                    </>
                )
            case 5:
                return (
                    <>
                        {gridIteOne(stepIndex, <SubmitNewVehicleContract/>)}
                    </>
                )
            default:
            case 6:
                return (
                    <Spinner />
                )
        }
    }
    return (
        <Card style={{minHeight: '31vw'}}>
            <CardContent>
                <Grid container>
                    <Grid item xs={2}>
                        <IconButton color="primary" onClick={() =>setActiveComponent("MAIN")}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={8}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>

                <div>
                    <div>
                        <div>
                            <Grid
                                spacing={1}
                                container
                                justify={'center'}
                                className={classes.root}
                            >
                                <Grid item xs={6}>
                                    {getStepContent(activeStep)}
                                </Grid>
                            </Grid>
                            <div>
                                {activeStep === steps.length -1 ?
                                    <Button onClick={handleReset}>איפוס</Button>
                                    :
                                    <>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.backButton}
                                        >
                                            חזור
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={handleNext}>
                                            {'הבא'}
                                        </Button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}
