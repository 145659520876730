import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {paymentTypes} from '../../../helpers/convertedPaymentTypes'
import 'date-fns';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import moment from "moment";
export default function DialogPaymentsHistory (props){

    const [open, setOpen] = React.useState(false);
    const renderTable = () => {
        return (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>תאריך יצירה</TableCell>
                            <TableCell>סכום</TableCell>
                            <TableCell>מעמ</TableCell>
                            <TableCell>סכום כולל מעמ</TableCell>
                            <TableCell>אצמעי תשלום</TableCell>
                            <TableCell>סטטוס</TableCell>
                            <TableCell>הערות</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rowData.payments?.map(payment => (
                            <TableRow key={payment.id}>
                                <TableCell>{moment(payment.createdOn).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                <TableCell>{payment.amount+' ₪'}</TableCell>
                                <TableCell>{payment.vat+' ₪'}</TableCell>
                                <TableCell>{payment.amountWithVat ? payment.amountWithVat+' ₪'  :payment.amount+' ₪' }</TableCell>
                                <TableCell>{paymentTypes(payment.paymentType)}</TableCell>
                                <TableCell>{payment.status}</TableCell>
                                <TableCell>{payment.comment}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
        );
    };
    const handleClose = () => {setOpen(false);};
    return (
        <>

            <Button onClick={()=>setOpen(true)} color={'primary'}>תשלומים</Button>
            <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    {renderTable()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >
                        סגור
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
