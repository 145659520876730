export const paymentTypes =(type) =>{
    switch (type) {
        case 'FLUID': return 'העברה'
        case 'WIRE_TRANSFER': return 'העברה בנקאית'
        case 'CHECKS': return 'המחאות'
        case 'CREDIT_CARD': return 'כ.אשראי'
        case 'CASH': return 'מזומן'
        case 'CHOOSE': return 'בחר'
        default: return 'ללא'
    }
}
export const paymentTypesArray =() =>{
    return [
        {
            value: 'FLUID',
            label: 'העברה',
        },
        {
            value: 'WIRE_TRANSFER',
            label: 'העברה בנקאית',
        },
        {
            value: 'CHECKS',
            label: 'המחאות',
        },
        {
            value: 'CREDIT_CARD',
            label: 'כ.אשראי',
        },
        {
            value: 'CASH',
            label: 'מזומן',
        },
        {
            value: 'NONE',
            label: 'ללא',
        },
    ];
}
export const vehicleLocations =(location) =>{
    switch (location) {
        case 'SELLER': return 'מוכר הרכב'
        case 'AGENCY': return 'סוכנות'
        case 'BUYER': return 'קונה הרכב'
        case 'GARAGE': return 'מוסך'
        case 'OTHER':
        default: return 'אחר'
    }
}
export const vehicleStatuses =(status) =>{
    switch (status) {
        case 'BOUT': return 'נרכש'
        case 'RECEIVED': return 'במלאי'
        case 'GARAGE': return 'מוסך'
        case 'SOLD': return 'נמכר'
        case 'DELIVERED': return 'נמסר'
        case 'BUY_CONTRACT_SIGNED': return 'נחתם חוזה קניה'
        case 'OTHER':
        default: return 'אחר'
    }
}

export  const translateCabContractStatuses =(status) =>{
    switch (status) {
        case 'CREATED': return 'נוצר'
        case 'ACTIVE':return 'פעיל'
        case 'FREEZE':return 'מוקפא'
        case 'ENDED':return 'הסתיים'
        case 'STOPPED':return 'הופסק'
        case 'FUTURE':return 'עתידי'
        default: return ''
    }
}
