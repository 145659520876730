import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Context as SingleCabContext} from "../../../context/SingleCabContext";
import AsyncAutoComplete from "./AsyncAutoComplete";
import IconButton from "@material-ui/core/IconButton";
import CachedIcon from "@material-ui/icons/Cached";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from "@material-ui/core/Tooltip";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {Context as NotificationContext} from "../../../context/NotificationContext";
import Spinner from "../../../components/Spinner.js";
import CircularProgress from "@material-ui/core/CircularProgress";


const FormDialogReplaceCar =(props) =>{
    const [open, setOpen] = React.useState(false);
    const {state:{newVehicle,dataCab,isNewVehicleAvailable,vehicleChangedFailed,singleCabChangeEvent},removeVehicleFromDriver,changeVehicleForDriver} = useContext(SingleCabContext)
    const [state,setState] = useState({resMsg:null});
    const {pipeMessage} = useContext(NotificationContext);
    const [spin,setSpin] = useState(false);

    useEffect(()=>{
        if(vehicleChangedFailed){
            pipeMessage('פעולת העדכון נכשלה, אנא נסה שנית','error')
            setSpin(false)
        }

    },[vehicleChangedFailed])
    useEffect(()=>{
        if(singleCabChangeEvent){
            pipeMessage('הפעולה בוצעה בהצלחה','success')
            setSpin(false)
        }

    },[singleCabChangeEvent])
    const handleClickOpen = () => {
        setSpin(false)
        setOpen(true);
    };
    const handleClose = () => {
        setSpin(false)
        setOpen(false);
    };
    const saveNewCarNumber = async() =>{
        changeVehicleForDriver(dataCab.driver.id, newVehicle.id, dataCab.id,props.type)
        setSpin(true)

    }
    const detouchVehicle = async (cabId) =>{
        removeVehicleFromDriver(dataCab.driver.id, cabId)
        setSpin(true)
    }
    return (
        <div>
            <Tooltip title={props.type==='replace' ? 'החלף רכב' : props.type==='detouch' ? 'הסר רכב' : 'שבץ רכב'} aria-label="change">
                <IconButton size="small" aria-label="change" onClick={()=>handleClickOpen()} >
                    {
                        props.type==='replace' ? <CachedIcon fontSize="small" color="primary" /> :
                            props.type==='detouch' ? <DeleteOutlineIcon fontSize="small" color="secondary" /> :
                        <AddCircleOutlineIcon fontSize="small" color="primary" />
                    }

                </IconButton>
            </Tooltip>
            <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{props.title }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.type==='detouch' ? 'ביטול צימוד רכב מנהג' : 'על מנת לשדך רכב על הרכב להיות רשום במערכת. כמו כן, רכב המצומד לנהג אחר יש להסיר צימוד מהנהג האחר תחילה.'}

                    </DialogContentText>
                    {props.type === 'detouch' ? null :  <AsyncAutoComplete type={props.type} />}
                    {spin === true? <><CircularProgress size={20} /> {' מבצע, אנא המתן'}</>: null}

                </DialogContent>

                <DialogActions>

                    <Button
                        onClick={() => {
                            props.type !== 'detouch' ? saveNewCarNumber() : detouchVehicle(dataCab.id)
                        }}
                        color="primary"
                        disabled={props.type !== 'detouch' && (!isNewVehicleAvailable || (isNewVehicleAvailable && isNewVehicleAvailable === false))}
                    >
                        {props.type === 'detouch' ? 'הסר' : 'שבץ'}

                    </Button>
                    <Button onClick={()=>handleClose()} >
                        סגור
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default FormDialogReplaceCar;
