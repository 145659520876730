import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useContext, useEffect, useState} from "react";
import {Context as SingleCabContext} from "../../../context/SingleCabContext";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {debounce} from "@material-ui/core";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function AsyncAutoComplete(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [errorState,setErrorState] = useState({error:null});
    const loading = open && options.length === 0;
    const {state:{vehicleList,newVehicle,dataCab,isNewVehicleAvailable},
        fetchAllVehicles,checkAndPreSetNewVehicleNumber,fetchVehiclesStartsWith} = useContext(SingleCabContext);
    const onChangeHandle = debounce(async (value) => {
        if(value.length >0){
            fetchVehiclesStartsWith(value);
            setOptions([...vehicleList]);
        }
    }, 300);
    const setTextData =() =>{
        if(props.typs==='replace'){
            return(
             <>
                {'רכב פנוי לשיבוץ.'}<br />
                {' מספר רכב יוצא: '+dataCab.vehicle.carNumber  }<br />
                {' מספר רכב נכנס: '+newVehicle.carNumber  }<br />
                {newVehicle.supplier ? ' בעל הרכב הנכנס: '+newVehicle.supplier.fullName :null  }
            </>
            )

        }else{
            return(
                <>
                    {'רכב פנוי לשיבוץ.'}<br />
                    {' מספר רכב נכנס: '+newVehicle.carNumber  }<br />
                    {newVehicle.supplier ? ' בעל הרכב הנכנס: '+newVehicle.supplier.fullName :null  }
                </>
            )
        }
    }
    const onSetAutoCompleteValue = async (e) =>{

        checkAndPreSetNewVehicleNumber(e.id);
    }
    useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            if(!vehicleList)
            await fetchAllVehicles();
            if (active && vehicleList) {
                setOptions([...vehicleList]);
            }
        })();
        return () => {
            active = false;
        };
    }, [loading,vehicleList]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <>
        <Autocomplete
            id="autocomplete-vehicle"
            sx={{ width: 300 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.carNumber === value.carNumber}
            getOptionLabel={(option) => option.carNumber}
            options={vehicleList ? vehicleList : []}
            loading={loading}
            noOptionsText={'לא נמצא רכב עם מספר זה'}

            renderOption={(option) => (
                <React.Fragment>
                    <span>{' רכב '+option.carNumber + ' ' }</span> <span>&nbsp;&nbsp;</span>
                     {option.driver ?  ' נהג '+option.driver.fullName : ' נהג  לא מצומד לנהג '}<span>{' '}</span>
                </React.Fragment>
            )}
            onChange={(e,v )=> onSetAutoCompleteValue(v)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={'שיבוץ רכב'}
                    onChange={ev => {
                        // dont fire API if the user delete or not entered anything
                        if (ev.target.value !== "" || ev.target.value !== null) {
                            onChangeHandle(ev.target.value);
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}

                />
            )}
        />
        <br />
            {
            newVehicle && isNewVehicleAvailable ?
            <Typography>
                <Box color="primary.main">
                    {setTextData()}
                </Box>
            </Typography>
            : newVehicle && !isNewVehicleAvailable ?
            <Typography>
                <Box color="error.main">
                    {newVehicle.carNumber +' מצומד ל'+ newVehicle.driver.fullName}  <br/>
                    { 'יש לבטל צימוד קודם'} <br/>
                </Box>
            </Typography>
            : null
            }
        </>
    );
}

