import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import HomePage from "../PaymentsApp/pages/home_page/HomePage";
import {Router, Route, Link, Switch} from "react-router-dom";
import { createBrowserHistory } from "history";
import BreadCrumbs from "./BreadCrumbs";
import SupplierComponent from "../PaymentsApp/pages/supplier/SupplierComponent";
import ConsumerComponent from "../PaymentsApp/pages/archived-pages/consumer/ConsumerComponent";
import SingleCabNew from "../PaymentsApp/pages/single-cab/SingleCabNew";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CabsOwners from "../PaymentsApp/pages/supplier/CabsOwners";
import Drivers from "../PaymentsApp/pages/archived-pages/consumer/Drivers";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AdminPageNew from "../PaymentsApp/pages/admin/AdminPageNew";
import Login from "../PaymentsApp/pages/login/Login";
import AuthenticationRoute from "../auth/AuthenticationRoute";
import {Context as AuthContext} from "../context/AuthenticationContext";
import {Context as NotificationContext} from "../context/NotificationContext"
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from "@material-ui/core/Tooltip";
import VehicleComponent from "../PaymentsApp/pages/single-vehicle/VehicleComponent";
import PeopleComponent from '../PaymentsApp/pages/people/PeopleComponent';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SinglePerson from "../PaymentsApp/pages/people/SinglePerson";
import FleetVehiclePool from "../PaymentsApp/pages/vehicles/FleetVehiclePool";
import GeneralVehiclePool from "../PaymentsApp/pages/vehicles/GeneralVehiclePool";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
const history = createBrowserHistory();
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1,
    },
}));

const MainLayout = () =>{

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleButtonClick = (path) =>{

    }
    const {state:{isAuthenticated,fullName},getFullName,checkAuth} = useContext(AuthContext);
    useEffect(() => {
        checkAuth();
        getFullName();
    }, [fullName]);
    const logout = () =>{
        localStorage.clear();
        history.push('/login');
    }
    const {state:{message,dummy,severity}} = useContext(NotificationContext);
    const [notifOpen, setNotifOpen] = useState(false);
    useEffect(()=>{
        if(message)
            setNotifOpen(true)
    },[dummy])
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotifOpen(false);
    };
    return (

    <Router history={history}>
        {isAuthenticated ?
            <div className={classes.root}>

                <CssBaseline/>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {fullName}
                        </Typography>
                        <Tooltip title="התנתק" aria-label="logout">
                            <IconButton
                                color="inherit"
                                aria-label="logout"
                                onClick={logout}
                                edge="end"
                            >
                                <PowerSettingsNewIcon/>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                        </IconButton>
                    </div>
                    <Divider/>
                    <List>
                        <ListItem button component={Link} to="/" onClick={() => handleButtonClick}>
                            <ListItemIcon><HomeIcon/> </ListItemIcon>
                            <ListItemText primary="בית"/>
                        </ListItem>
                        <ListItem button component={Link} to="/cabsowners" onClick={() => handleButtonClick}>
                            <ListItemIcon><AccountTreeIcon/> </ListItemIcon>
                            <ListItemText primary="ספקים"/>
                        </ListItem>
                        <ListItem button component={Link} to="/drivers" onClick={handleButtonClick}>
                            <ListItemIcon><GroupIcon/> </ListItemIcon>
                            <ListItemText primary="נהגים"/>
                        </ListItem>
                        <ListItem button component={Link} to="/people" onClick={handleButtonClick}>
                            <ListItemIcon><GroupIcon/> </ListItemIcon>
                            <ListItemText primary="אנשי קשר"/>
                        </ListItem>
                        <ListItem button component={Link} to="/generalVehiclePool" onClick={() => handleButtonClick}>
                            <ListItemIcon><DriveEtaIcon/> </ListItemIcon>
                            <ListItemText primary="רכבים"/>
                        </ListItem>
                        <ListItem button component={Link} to="/vehiclesFleet" onClick={() => handleButtonClick}>
                            <ListItemIcon><ImportExportIcon/> </ListItemIcon>
                            <ListItemText primary="רכבים במלאי"/>
                        </ListItem>
                        {/*<ListItem button onClick={() => handleButtonClick}>*/}
                        {/*    <ListItemIcon><TrendingUpIcon/> </ListItemIcon>*/}
                        {/*    <ListItemText primary="דוחות"/>*/}
                        {/*</ListItem>*/}
                        {/*<ListItem button onClick={() => handleButtonClick}>*/}
                        {/*    <ListItemIcon><AccountCircleIcon/> </ListItemIcon>*/}
                        {/*    <ListItemText primary="משתמש"/>*/}
                        {/*</ListItem>*/}
                        {localStorage.getItem("admin") ?
                            <ListItem button component={Link} to="/admin" onClick={handleButtonClick}>
                                <ListItemIcon><SettingsIcon/> </ListItemIcon>
                                <ListItemText primary="אדמין"/>
                            </ListItem>
                            :
                            null
                        }

                    </List>
                </Drawer>

                <main className={classes.content}>
                    <br/><br/><br/>
                    <BreadCrumbs/>
                    <br/>

                    <Switch>
                        <Route exact path="/login" component ={Login} />
                        <AuthenticationRoute exact path="/home" component={HomePage}/>
                        <AuthenticationRoute exact path="/cabsowners/:name" component={SupplierComponent}/>

                        <AuthenticationRoute exact path="/cabsowners"
                             component={() =>
                             <CabsOwners
                                title={'ספקים'}
                                linking={'cabsowners'}
                                tableFilter={'owners'}
                                adPersonTitle={'הוסף ספק'}
                                personType={'isPurePerson'}
                            />
                         }/>
                        <AuthenticationRoute exact path="/drivers"
                             component={() =>
                                 <CabsOwners
                                     title={'נהגים'}
                                     linking={'drivers'}
                                     tableFilter={'drivers'}
                                     adPersonTitle={'הוסף נהג'}
                                     personType={'isPurePerson'}
                                 />
                             }
                        />
                        <AuthenticationRoute exact path="/drivers/:name" component={SupplierComponent}/>
                        <AuthenticationRoute exact path="/people"
                             component={() =>
                                 <PeopleComponent
                                     title={'אנשי קשר'}
                                     linking={'justPerson'}
                                     tableFilter={'noFilter'}
                                     adPersonTitle={'הוסף אדם'}
                                     personType={'isSupplier'}
                                 />
                             }
                        />
                        {/*<AuthenticationRoute exact path="/cabs" component={CabsPage}/>*/}
                        <AuthenticationRoute exact path="/cabs/:cabId" component={SingleCabNew}/>
                        <AuthenticationRoute exact path="/generalVehiclePool" component={GeneralVehiclePool}/>
                        <AuthenticationRoute exact path="/vehiclesFleet" component={FleetVehiclePool}/>
                        <AuthenticationRoute exact path="/vehicles/:vId" component={VehicleComponent}/>
                        <AuthenticationRoute exact path="/vehicles/addVehicle" component={VehicleComponent}/>
                        <AuthenticationRoute exact path="/people/:vId" component={SinglePerson}/>
                        <AuthenticationRoute exact path="/admin" component={AdminPageNew}/>
                        <AuthenticationRoute exact path="" component={HomePage}/>
                    </Switch>

                </main>
                <Snackbar open={notifOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert variant="filled" onClose={handleClose} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
            </div>
            :
            <Switch>
                <Route exact path="/login" component ={Login} />
                <AuthenticationRoute exact path="/home" component={HomePage}/>
                <AuthenticationRoute exact path="/cabsowners/:name" component={SupplierComponent}/>
                <AuthenticationRoute exact path="/cabsowners" component={CabsOwners}/>
                <AuthenticationRoute exact path="/drivers" component={Drivers}/>
                <AuthenticationRoute exact path="/drivers/:dId" component={ConsumerComponent}/>
                <AuthenticationRoute exact path="/people" component={PeopleComponent}/>
                <AuthenticationRoute exact path="/people/:vId" component={SinglePerson}/>
                {/*<AuthenticationRoute exact path="/cabs" component={CabsPage}/>*/}
                <AuthenticationRoute exact path="/cabs/:cabId" component={SingleCabNew}/>
                <AuthenticationRoute exact path="/generalVehiclePool" component={GeneralVehiclePool}/>
                <AuthenticationRoute exact path="/vehiclesFleet" component={FleetVehiclePool}/>
                <AuthenticationRoute exact path="/vehicles/:vId" component={VehicleComponent}/>
                <AuthenticationRoute exact path="/vehicles/addVehicle" component={VehicleComponent}/>
                <AuthenticationRoute exact path="/admin" component={AdminPageNew}/>
                <AuthenticationRoute exact path="" component={HomePage}/>
            </Switch>
        }
    </Router>
    );
}
export default MainLayout ;
