import React, {useContext, useEffect, useState} from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import {AutoWidthSelect} from "../../../../components/SelectComponent"
import {Context as VehicleContractContext} from "../../../../context/VehicleContractContext"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";
import {Context as VehiclesContext} from "../../../../context/VehiclesContext";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";

const VehicleContractDetails =() =>{
    const numPaymentsArray =[{value:0,text:'בחר'},{value:1,text:1},{value:2,text:2},{value:3,text:3},{value:4,text:4},{value:5,text:5},{value:6,text:6},{value:7,text:7},{value:8,text:8},{value:9,text:9},{value:10,text:10},{value:11,text:11},{value:12,text:12}]
    const {state:{vehicle}} = useContext(VehiclesContext);
    const {state:{contractType},setVehicleNumPayments, setPaymentAmount,setCommentsForContract,setViolationFee,
        setContractDate,setDeliveryDate,setUpfrontPayment,setKilometersOnSignContractDate,setLastTreatDate} = useContext(VehicleContractContext);


    const [state, setState] = useState({
        signDate:moment().format('YYYY-MM-DD'),
        approxDeliverDate:moment().add(7, 'days').format('YYYY-MM-DD'),
        kilometers:0,
        fullCost:0,
        upfront:0,
        numPayments:1,
        lastTreatDate:'',
        comments:'',
        violationFee:0,
        costLabel:'מחיר קניה',
        vat:0,
        fullCostWithVat: 0
    });
    useEffect(() =>{
        if(vehicle && contractType ==='saleVehicle'){
            setState({
                ...state,
                kilometers:vehicle.vehicleBook.kilometerOnReceiptDate,
                fullCost:(Number(vehicle.vehicleBook.buyDeal.purchaseCost) * 1.3).toFixed(0),
                upfront:(Number(vehicle.vehicleBook.buyDeal.purchaseCost * 0.2)).toFixed(0),
                lastTreatDate:vehicle.vehicleBook.lastTreatDate,
                violationFee:(Number(vehicle.vehicleBook.buyDeal.purchaseCost) * 0.2).toFixed(0),
                comments:'',
                costLabel: 'מחיר מכירה מוצע',
            })
            setKilometersOnSignContractDate(vehicle.vehicleBook.kilometerOnReceiptDate);
            setViolationFee((Number(vehicle.vehicleBook.buyDeal.purchaseCost) * 0.2).toFixed(0));
            setLastTreatDate(vehicle.vehicleBook.lastTreatDate);
            setPaymentAmount((Number(vehicle.vehicleBook.buyDeal.purchaseCost) * 1.3).toFixed(0));
            setUpfrontPayment((Number(vehicle.vehicleBook.buyDeal.purchaseCost * 0.2)).toFixed(0));
            setVehicleNumPayments(state.numPayments,1)
        }
        setContractDate(state.signDate);
        setDeliveryDate(state.approxDeliverDate);

    },[vehicle])


    const handleChange =(k,v) =>{

        switch (k) {
            case"signDate":setContractDate(v)
                break;
            case"approxDeliverDate":setDeliveryDate(v)
                break;
            case"lastTreatDate":setLastTreatDate(v)
                break;
            case "fullCost" :setPaymentAmount(v);
                break;
            case "upfront" :setUpfrontPayment(v);
                break;
            case 'kilometers':setKilometersOnSignContractDate(v);
                break;
            case 'comments':setCommentsForContract(v);
                break;
            case 'violationFee':setViolationFee(v);
                break;
            case 'numPayments':setVehicleNumPayments(v,1)
                break;
            default:
                break;
        }
        setState({
            ...state,
            [k]:v
        })
    }
    return(
        <>
            <Grid
                spacing={3}
                container
            >
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    size={'small'}
                    value={state.signDate}
                    label={'תאריך חוזה'}
                    style = {{width: 400}}
                    format="YYYY-MM-DD"
                    type="date"
                    onChange={
                        e=> handleChange("signDate",e.target.value)
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    size={'small'}
                    value={state.approxDeliverDate}
                    label={'תאריך מסירה משוער'}
                    style = {{width: 400}}
                    type="date"
                    onChange={
                        e=> handleChange("approxDeliverDate",e.target.value)
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    size={'small'}
                    value={state.lastTreatDate}
                    label={'תאריך טיפול אחרון'}
                    style = {{width: 400}}
                    type="date"
                    onChange={
                        e=> handleChange("lastTreatDate",e.target.value)
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'} variant="outlined" style = {{width: 400}}
                    value={state.kilometers}
                    onChange={e=> handleChange('kilometers',e.target.value)}
                    label={'קמ בעת חתימה על חוזה'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <AutoWidthSelect defaultValue={state.paymentType} fullWidth HelperText="סוג עסקה" refrence="VEHICLE_DEAL_TYPE" />
            </Grid>
            <Grid item xs={12}>

                <TextField
                    size={'small'} variant="outlined" style = {{width: 400}}
                    value={state.fullCost}
                    onChange={e=>handleChange('fullCost',e.target.value)}
                    label={state.costLabel}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">₪</InputAdornment>,
                    }}
                />
                { contractType ==='saleVehicle' ?
                    <>
                        <br />
                        <Grid container style = {{width: 400}}>
                            <Grid item xs={6}>
                                <Typography align="left">מע"מ 17%</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography align="right">{(state.fullCost * 0.17).toFixed(0)} ₪</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography align="left">מחיר כולל מעמ</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography align="right">{(state.fullCost * 1.17).toFixed(0)} ₪</Typography>
                            </Grid>
                        </Grid>
                    </>:null

                }

            </Grid>

            {contractType ==="saleVehicle" ?
                <>
                <Grid item xs={12}>
                    <TextField
                        size={'small'} variant="outlined" style = {{width: 400}}
                        value={state.upfront}
                        onChange={e=>handleChange('upfront',e.target.value)}
                        label="מקדמה"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">₪</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <TextField
                            select
                            size={'small'}
                            variant="outlined"
                            style = {{width: 400}}
                            label={'היתרה במספר תשלומים'}
                            defaultValue={state.numPayments}
                            value={state.numPayments}
                            onChange={e=>handleChange('numPayments',e.target.value)}
                            SelectProps={{
                                native: true,
                            }}

                        >
                            {numPaymentsArray.map((value,number) =>{
                                return <option key={value.value} value={value.value}>{value.value}</option>
                            })}
                        </TextField>
                    </FormControl>
                </Grid>
                </>:null
            }
                <Grid item xs={12}>
                    <TextField
                        size={'small'} variant="outlined" style = {{width: 400}}
                        value={state.violationFee}
                        onChange={e=>handleChange('violationFee',e.target.value)}
                        label="פיצוי ביטול"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">₪</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        rows={2}
                        maxRows={3}
                        placeholder="הערות לעסקה"
                        size={'small'} variant="outlined" style = {{width: 400}}
                        value={state.comments}
                        onChange={e=>handleChange('comments',e.target.value)}
                        label="הערות לעסקה"
                    />
                </Grid>
            </Grid>
        </>
    )
}
export default VehicleContractDetails
