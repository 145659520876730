import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi';
const FileUploaderReducer =(state,action) =>{
    switch(action.type){
        case 'list_of_imgs' :
            return{
                ...state,
                listOfImg:action.payload
            }
        case 'list_of_files' :
            return{
                ...state,
                listOfFiles:action.payload
            }
        case 'delete_file' :
            return{
                ...state,
                deleteMsg:action.payload
            }
        case 'upload_failed' :
            return{
                ...state,
                pipeFailed:!state.pipeFailed,
                message:'העלאה נכשלה, אנא נסה שנית. שים לב לגודל הקובץ שלא יעלה על 5M',
                typeError:'error'
            }
        case 'delete_failed' :
            return{
                ...state,
                pipeFailed:!state.pipeFailed,
                message:'המחיקה נכשלה, אנא נסה שנית בעוד מספר רגעים',
                typeError:'error'
            }
        case 'upload_success' :
            return{
                ...state,
                pipeFailed:!state.pipeFailed,
                message:'הפעולה התבצעה בהצלחה',
                typeError:'success'
            }
    }
}
const getVehicleFiles = dispatch =>async(value) =>{
    const response = await InterceptorApi().get(`/api/get-vehicle-files/${value}`);
    dispatch({type:'list_of_imgs',payload:response.data})
}

const getCabFiles = dispatch =>async(value) =>{
    const response = await InterceptorApi().get(`/api/get-cab-files/${value}`);
    dispatch({type:'list_of_imgs',payload:response.data})
}
const uploadVehicleFile = dispatch =>async(file,index,fileType,id,module) =>{

    const imgData = new FormData();
    imgData.append("imageFile",file._file);
    imgData.append("index",index);
    imgData.append("fileType",fileType);
    imgData.append("id",id);
    imgData.append("fileViewName",file.fileViewName ? file.fileViewName : file.originalName);
    imgData.append("module",module);
    const response = await InterceptorApi().post('/api/addFileToFilesDirectory', imgData)

    if(response.isAxiosError){
        dispatch({type:'upload_failed',payload:false})
    }else if(response.status===200){
        dispatch({type:'upload_success',payload:true})
    }

}

const updateVehicleFileName = dispatch =>async(path) =>{
    const newFileName = path.fileViewName;
    const fileId = path.id;
    const response = await InterceptorApi().put('/api/updateFileName', {newFileName,fileId});
}

const deleteVehicleFileName = dispatch =>async(element) =>{
    const elementId = element.id;
    const response = await InterceptorApi().put('/api/deleteFilePath', {elementId});

    if(response.isAxiosError){
        dispatch({type:'delete_failed',payload:false})
    }else if(response.status===200){
        dispatch({type:'upload_success',payload:true})
    }

}
export const {Provider,Context} = createDataContext(

    FileUploaderReducer,
    {getCabFiles,uploadVehicleFile,getVehicleFiles,updateVehicleFileName,deleteVehicleFileName},
    []
)
