import React, {useContext, useEffect, useRef} from 'react'
import SignaturePad from 'react-signature-pad-wrapper'
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Context as CreateContractContext} from "../../../../context/CreateContractContext";
import {Context as VehicleContractContext} from "../../../../context/VehicleContractContext";
import {Context as AdminContext} from "../../../../context/AdminContext"
import {Context as VehicleContext} from "../../../../context/VehiclesContext"
const SignatureCanvas = (props) =>{
    const sigCanvas = useRef({})
    const canvasContainerRef = useRef(null);
    const clear = () => sigCanvas.current.clear()
    const {setSignature,signatureSubmitted} = useContext(CreateContractContext);
    const {setVehicleContractSignature,setReceiveVehicleSignature,setDeliverVehicleSiganture} = useContext(VehicleContractContext);

    const {addCompanySignature} = useContext(AdminContext);
    const handleSign = () =>{
        if(props.conType ==='VEHICLE'){
            setVehicleContractSignature(sigCanvas.current.toDataURL('image/png'))
        }
        if(props.conType ==='CAB'){
            setSignature(sigCanvas.current.toDataURL('image/png'))
            signatureSubmitted()
        }
        if(props.conType ==='COMPANY_SIGNATURE'){
            addCompanySignature(sigCanvas.current.toDataURL('image/png'))
        }
        if(props.conType ==='vehicleRecipient'){
            setReceiveVehicleSignature(sigCanvas.current.toDataURL('image/png'))
        }
        if(props.conType ==='vehicleDeliver'){
            setDeliverVehicleSiganture(sigCanvas.current.toDataURL('image/png'))
        }
    }
    const resizeCanvas = () => {
        const canvas = sigCanvas.current.canvas;
        if (canvas && canvasContainerRef.current) {
            canvas.width = canvasContainerRef.current.offsetWidth;
            canvas.height = canvasContainerRef.current.offsetHeight;
        }
    };
    useEffect(() => {
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas(); // Initial resize

        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);
    return   <>
            <div ref={canvasContainerRef} style={{border:'2px solid silver',borderRadius:'5px',minHeight:'200px',minWidth:'250px'}}>
                <SignaturePad
                    ref={sigCanvas}
                    options={{
                        minWidth: 0.9,
                        maxWidth: 0.9,
                        penColor: 'rgb(00, 0, 255)',
                        className: "signaturePad"
                    }}
                />
             </div>

            <Typography align="center">
                <Button color="danger" onClick={clear}>נקה</Button>
                &nbsp;
                <Button color="primary" onClick={handleSign}>אישור</Button>
            </Typography>
            </>
}
export default SignatureCanvas
