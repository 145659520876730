import React, {useContext, useEffect, useState} from 'react'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ServerSideTable from "../../../components/ServerSideTable";
import VehicleDetails from "../single-vehicle/VehicleDetails";
import Alert from "@material-ui/lab/Alert";
import {Context as VehiclesContext} from "../../../context/VehiclesContext";
import Divider from "@material-ui/core/Divider";

const Vehicles =(props) =>{
    console.log('vv');console.log(props);console.log('vv');
    const {state:{success,warning}} = useContext(VehiclesContext);
    const [state, setState] =useState(false);
    const addNewVehicleForm =(val) => setState(val);

    return(
        <>
            <Grid style={{marginBottom:'5px'}} container item xs={12}>
                <Button href={'/vehicles/addVehicle'} variant="contained" color="primary" size={'small'} >
                    הוסף רכב
                </Button>
            </Grid>
            <Card>
                <CardContent>
                    {state === true ?
                        <Grid

                            display="flex"
                            container
                            direction="row"
                            alignItems="center"
                        >
                        <Grid item xs={5}>
                            <IconButton color="primary" onClick={() =>addNewVehicleForm(false)}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Grid>
                            {success  || warning  ?
                                <Grid item xs={5}><Alert severity={success ? "success" : "warning"}>{success ? success : warning}</Alert></Grid>
                                :
                                <Grid item xs={5}>{'הוסף רכב'}</Grid>
                            }
                            <Grid item xs={12} >
                                <Divider />
                            </Grid>
                        </Grid>
                        :
                        null
                    }
                    {state === false ?
                        <Grid style={{marginTop:'5px'}} item xs={12}>
                            <ServerSideTable columns={props.columns} initialFilter={props.ptype} title={props.title} url={props.url}/>
                        </Grid>
                        :
                        <VehicleDetails />

                    }
                </CardContent>
            </Card>
        </>
    )
}
export default Vehicles;
