import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi'
const singleCabReducer = (state,action) =>{
    switch (action.type) {
        case 'fetch_single_cab':
            return {
                ...state,

                dataCabId:action.payload.cab ? action.payload.cab.id : action.payload.cab,
                dataCab:action.payload.cab,
                recurring:action.payload.recurring

            };
        case 'close_contract':
            return {
                ...state,
                singleCabChangeEvent:!state.singleCabChangeEvent,
                dataCabId:action.payload ? action.payload.id : action.payload,
                dataCab:action.payload,
                pipeNotifSuccess:!state.pipeNotifSuccess

            };
        case 'close_contract_fail':
            return {
                ...state,
                pipeNotifFail:!state.pipeNotifFail

            };
        case 'fetch_owners_hist':
            return {...state,
                dataSuppContractsHist:action.payload.suppCon,
                dataConsContractHist:action.payload.consCon
            }

        case 'fetch_vehicles_start_with':
        case 'fetch_all_vehicles':
            return {
                ...state,
                vehicleList:action.payload
            }
        case 'new_vehicle_data':

            return {
                ...state,
                newVehicle:action.payload.newCar,
                isNewVehicleAvailable:action.payload.available,
            }
        case 'clear_new_vehicle_data':
            return {
                ...state,
                newVehicle:null,
                newVehicleId:null
            }
        case 'changed_vehicle':
            return {
                ...state,
                singleCabChangeEvent:!state.singleCabChangeEvent,
                newVehicle:null,
                isNewVehicleAvailable:null
            }
            case 'change_vehicle_failed':
            return {
                ...state,
                vehicleChangedFailed:!state.vehicleChangedFailed
            }
        case 'set_con_end_date':
            return {
                ...state,
                endConDate:action.payload
            }
        case 'set_con_end_reason':
        return {
            ...state,
            endConReason:action.payload
        }
        case 'notify_start':
            return {
                ...state,
                notify:{notif:1, msg:'עובדים על זה נא המתן'}

            }
        case 'notify_done':
        return {
            ...state,
            notify:{notif:0, msg:'הסתיים'}
        }
        case 'create_payment':
            let p
            if(action.payload.entity ==='owner'){
                p = state.dataCab.supplierContract.payments = action.payload.res
            }else{
                p = state.dataCab.consumerContract.payments = action.payload.res
            }
            return {
                ...state,
                p,
                snackbar:action.payload.status ===200 ? "תשלום בוצע בהצלחה" : "משהו השתבש, אנא פנה למנהל המערכת",
                cabsPaymentUpdated:!state.cabsPaymentUpdated
            }
        case 'update_comment':

            let row;
            if(action.payload.type==='owner'){
                row =  state.dataCab.supplierContract.payments.find(e =>e.id ===action.payload.data.id);
                row.comment = action.payload.data.comment;
                row.status = Number(action.payload.data.status);
                row.executionDate = action.payload.data.executionDate;
            }else{
                row =  state.dataCab.consumerContract.payments.find(e =>e.id ===action.payload.data.id);
                row.comment = action.payload.data.comment;
                row.status = Number(action.payload.data.status);
                row.executionDate = action.payload.data.executionDate;
            }

        return {
            ...state,
            row
        }
        case 'active_component':

            return{
                ...state,
                activeComponent:action.payload
            }
        case 'update_person':
            const person = state.dataCab.supplier = action.payload;
            return{
                ...state,
                person
            }
        case 'im_in_use_msg':

            return{
                ...state,
                errMsg:action.payload
            }
        case 'fetch_driver_details':
            const personF = state.dataCab.supplier = action.payload;
            return{
                ...state,
                personF:action.payload
            }
        case 'set_cab_afterContract_update':

            return{
                ...state,
                dataCab:action.payload
            }
            case 'fetch_cab_vehicle_hist':

            return{
                ...state,
                vehiclesHistory:action.payload
            }
            case 'fetch_todays_payment_duties':
            return{
                ...state,
                todaysPaymentsDuties:action.payload
            }
            case 'update_payment_status':
            return{
                ...state,
                paymentUpdated:action.payload
            }
            case 'badges_for_cabs':
            return{
                ...state,
                driversPaymentsBadge:action.payload.driver,
                ownersPaymentsBadge:action.payload.supplier,
                paymentsBadgeChanged: !state.paymentsBadgeChanged
            }
        default:return;
    }
}
const getBadges = dispatch =>async()=>{
    const response = await InterceptorApi().get(`/api/getBadges`);
    dispatch({type:'badges_for_cabs',payload: response.data})
}
const updatePaymentStatus = dispatch => async(id,invoiceNumber,receiptNumber,comment,status) =>{
    try{
        const response = await InterceptorApi().put(`/api/updatePaymentStatus`,{id,status,invoiceNumber,receiptNumber,comment});

        if(response.status ===200)
            dispatch({type:'update_payment_status',payload: {status:'DONE_SUCCESSFULLY',id:id,paymentStatus:response.data.status,executionDate:response.data.executionDate}})
        else
            dispatch({type:'update_payment_status',payload: {status:'FAILED',id:id}})
    }catch (e) {
        dispatch({type:'update_payment_status',payload: {status:'FAILED',id:id}})
    }
}
const getTodaysPaymentsDuties =dispatch =>async(page,limit,contractType) =>{
    const response = await InterceptorApi().get(`/api/getTodaysPaymentDuties?_page=${page}&_limit=${limit}&contractType=${contractType}`);

    dispatch({type:'fetch_todays_payment_duties',payload: {data:response.data,count:response.totalCount}})
}
const getVehiclesHistory = dispatch => async(id) =>{
    const response = await InterceptorApi().get(`/api/getCabVehicleHistoryByCabId/${id}?_skip=0&_limit=10`);

    dispatch({type:'fetch_cab_vehicle_hist',payload:response.data})
}
const setCabAfterContractUpdate = dispatch => async(cab) =>{
    dispatch({type:'set_cab_afterContract_update',payload:cab})
}


const checkAndSetDriverByNationalId = dispatch => async (natId) =>{
    dispatch({type:'set_digit',payload:natId})
    if(natId.length>8){
        if(is_israeli_id_number(natId)){
            const response = await InterceptorApi().get(`/api/findPersonByNationalId/${natId}`);

            dispatch({type:'fetch_driver_details',payload:response.data})
        }
    }
}
const is_israeli_id_number= (id)=> {
    id = String(id).trim();
    if (id.length > 9 || isNaN(id) || id.length<2) return false;
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
    return Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
    }) % 10 === 0;
}
const updatePersonBasicDetails = dispatch =>async(conType,personData) =>{
    const response = await InterceptorApi().put('/api/updatePersonFromContract',personData);
    if(response.status === 226){
        alert('תעודת זהות זו משוייכת לאדם אחר');
        dispatch({type: 'im_in_use_msg', payload:'תעודת זהות זו משוייכת לאדם אחר' });
    }
    if(response.status === 200){
        alert('העדכון בוצע בהצלחה');
        if(conType ==='owner' || conType ==='ownerRenewal'|| conType ==='driverRenewal')
        dispatch({type: 'update_person', payload:response.data });
    }
}
const setActiveComponent = dispatch =>(data) =>{
    dispatch({type: 'active_component', payload:data });
}
const updatePaymentCommentCabModule = dispatch =>async(data,moreData,entity) =>{
     const obj={id:data.id,comment:data.comment,status:data.status}
     const response = await InterceptorApi().put('/api/updatePaymentCommentCabModule', obj);
     dispatch({type: 'update_comment', payload: {type:entity,data:response.data}});
}
const createSinglePayment = dispatch =>async(data,entity,contractId) =>{
    const obj ={
        dueDate:data.dueDate,
        paymentType:data.paymentType,
        contractId:contractId,
        status:data.status,
        amount:data.amount,
        vat:data.vat,
        amountWithVat:data.amountWithVat,
        paymentDirection:data.paymentDirection
    }

    const response = await InterceptorApi().post('/api/addPayment', obj);
    console.log(response)
    dispatch({type: 'create_payment', payload: {res:response.data,entity,status:response.status}});
}
const setConEndDate = dispatch =>(date) => {
    dispatch({type: 'set_con_end_date', payload: date})
}
const setConCloseReason = dispatch =>(reason) => {
    dispatch({type: 'set_con_end_reason', payload: reason})
}
const updateEndContractDate = dispatch =>async (cabId,conId,endDate) =>{
    const response = await InterceptorApi().post('/updateContractEndDate', {cabId,conId,endDate})
    dispatch({type:'fetch_single_cab',payload:response.data})
}
const endContractGeneric = dispatch =>async(cabId,conId,closeDate) =>{

    const obj = {id:conId,cabsId:cabId,endDate:closeDate,status:'STOPPED'}
    const response = await InterceptorApi().post('/api/closeContract', obj)

    if(response.status ===200 || response.status ===202)
        dispatch({type:'close_contract',payload:response.data})
    else
        dispatch({type:'close_contract_fail',payload:response.data})
}
const changeVehicleForDriver = dispatch =>async(driverId,newCarId,cabId,type) =>{

    const response = await InterceptorApi().post('/api/changeVehicleForDriver', {driverId,newCarId,cabId,type});
    if(response.status ===200){
        dispatch({type:'changed_vehicle',payload:response.data})
    }
    if(response.status ===500){
        dispatch({type:'change_vehicle_failed',payload:response.data})
    }

}
const removeVehicleFromDriver = dispatch =>async(driverId,cabId) =>{
    const response = await InterceptorApi().post('/api/removeVehicleFromDriver', {driverId,cabId});
    if(response.status ===200){
        dispatch({type:'changed_vehicle',payload:response.data})
    }
    if(response.status ===500){
        dispatch({type:'change_vehicle_failed',payload:response.data})
    }
}
const clearPresetNewVehilce = dispatch =>() =>{
    dispatch({type:'clear_new_vehicle_data'})
}
const checkAndPreSetNewVehicleNumber = dispatch =>async(val) =>{
    const response = await InterceptorApi().get(`/api/checkIfVehicleAvailible/${val}`)

    dispatch({type:'new_vehicle_data',payload: {newCar:response.data.carObject,available:response.data.isAvailible}})


}

const fetchVehiclesStartsWith = dispatch => async(val) =>{
    const response =  await InterceptorApi().get(`/api/getVehicleByNumberStartsWith/${val}`);


    dispatch({type:'fetch_vehicles_start_with',payload:response.data})
}
const fetchAllVehicles = dispatch => async() =>{
    const response = await InterceptorApi().get(`/api/getAllVehicles`)
    dispatch({type:'fetch_all_vehicles',payload:response.data})
}

const fetchCabData = dispatch => async(cabId) =>{
    const response =await InterceptorApi().get(`/api/getSingleCab/${cabId}`);


    dispatch({type:'fetch_single_cab', payload: {cab:response.data.cab,recurring:response.data.recurring}})
}
const fetchCabDataByCabNumber = dispatch => async(cabNumber) =>{

    const response =await InterceptorApi().get(`/api/getSingleCabByCabNumber/${cabNumber}`);


    dispatch({type:'fetch_single_cab',payload: {cab:response.data.cab,recurring:response.data.recurring}})
}
const fetchHist = dispatch => async(cabId) =>{
    const response = await InterceptorApi().get(`/contractHistByCabId/${cabId}`)
    dispatch({type:'fetch_owners_hist',payload:response.data})
}

export const {Provider,Context} = createDataContext(
            singleCabReducer,
    {removeVehicleFromDriver,getBadges,updatePaymentStatus,getTodaysPaymentsDuties ,getVehiclesHistory,fetchVehiclesStartsWith,fetchCabData,fetchHist,endContractGeneric,
            fetchAllVehicles,checkAndPreSetNewVehicleNumber,setConCloseReason,checkAndSetDriverByNationalId,
            clearPresetNewVehilce,changeVehicleForDriver,fetchCabDataByCabNumber,setActiveComponent,
            updateEndContractDate,setConEndDate,createSinglePayment,updatePaymentCommentCabModule,updatePersonBasicDetails,
            setCabAfterContractUpdate},

    []
)
