import React, {useContext, useEffect, useState} from 'react';
import {Context as CreateContractContext} from "../../../../context/CreateContractContext"
import {Context as SingleCabContext} from "../../../../context/SingleCabContext";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Typography from "@material-ui/core/Typography";

import InterceptorApi from "../../../../api/InterceptorApi";

const SubmitNewContract = () =>{
    const {state:{errorRaised,foundFuture,dataUrlState,paymentType,numPayments,endDate,startDate,
        dateFirstPayment,paymentAmount,datePaymentCycle,personId,contractType,conInProcess,createContractResponse,dataCabFromRes,conversionStatus},
        submitNewContract} = useContext(CreateContractContext);
    const {state:{dataCab},setCabAfterContractUpdate} = useContext(SingleCabContext)
    const handleClick = () =>{
        submitNewContract(dataUrlState,paymentType,numPayments,endDate,startDate,dateFirstPayment,paymentAmount,datePaymentCycle,dataCab.id,personId,contractType);
    }
    const displayContract = () =>{
      if(createContractResponse && createContractResponse.id){
          const f=   InterceptorApi().get(`/api/files/${createContractResponse.id}`,{responseType: 'blob'})
          f.then((res) =>{
              const file = new Blob([res.data], {type: 'application/pdf'});
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
          })
      }else{
          alert('חוזה עדיין לא מוכן מוכן');
      }

    }
   const[conPState,setConPState] = useState(null)
    useEffect(()=>{
        setConPState(conInProcess)
    },[conInProcess]);

    useEffect(()=>{
        if(dataCabFromRes)
            setCabAfterContractUpdate(dataCabFromRes)
    },[dataCabFromRes]);

    return (
        !conPState ?
            <>
                {!dataUrlState ?
                    <Alert severity="warning">
                        <AlertTitle>ליצירת חוזה יש לחתום על החוזה תחילה</AlertTitle>
                    </Alert>
                    :
                    <Alert severity="info">
                        <AlertTitle>הכל מוכן</AlertTitle>
                        <Button onClick={handleClick} color="primary">לחץ כאן כדי ליצור חוזה</Button>
                    </Alert>
                }

            </>
            :
            conPState && conPState === true ?
            <>
                <LinearProgress />
                <Alert severity="info">
                    <AlertTitle>מעבד נתונים</AlertTitle>
                    <Typography color="primary">אנא המתן</Typography>
                </Alert>
            </>
            :
            conPState ==="ENDED" && (foundFuture || errorRaised) ?
            <>
                {
                    foundFuture ?
                    <Alert severity="error">
                        <AlertTitle>קיים חוזה עתידי</AlertTitle>
                        <Button onClick={handleClick} color="warning">קיים חוזה עתידי. יש לממש או לבטל את החוזה העתידי תחילה</Button>
                    </Alert>
                    :
                    errorRaised ?
                    <Alert severity="error">
                        <AlertTitle>שגיאה</AlertTitle>
                        <Button onClick={handleClick} color="warning">שגיאה ביצירת חוזה</Button>
                    </Alert>:null
                }

            </>
            :
            conPState ==="ENDED" && conversionStatus==="SUCCESS" ?
            <>
                <LinearProgress variant="determinate" value={100} />
                <Alert severity="success">
                    <AlertTitle>הסתיים</AlertTitle>
                    {'חוזה מוכן'}
                    <Button onClick={displayContract} color="primary">הצג חוזה</Button>
                </Alert>
            </>
            :
            conPState ==="ENDED" && conversionStatus==="EXECUTION-ERROR" ?
            <>
                <Alert severity="error">
                    <AlertTitle>שגיאת יצירת PDF</AlertTitle>
                    <Button onClick={handleClick} color="warning">התהליך הושלם בהצלחה אך החוזה לא הומר ל-PDF</Button>
                </Alert>
            </>
            :
            <>
                <Alert severity="info">
                    <AlertTitle>הכל מוכן</AlertTitle>
                    <Button onClick={handleClick} color="primary">לחץ כאן כדי ליצור חוזה</Button>
                </Alert>
            </>
    )



}
export default SubmitNewContract;
