import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import {Context as AdminContext} from "../../../context/AdminContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppSnackbar from "../../../components/AppSnackbar";

const listRoles = [
    { description: 'סוכן', id: 1 },
    { description: 'מנהל', id: 2 },
    { description: 'מנהל מערכת', id: 3 },
];
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

}));

export default function UserManagementModal({compId,agencyId, title, ButtonIcon,rowData,companyIndex,agencyIndex,type}) {

   // console.log('-----------'); console.log(rowData); console.log('-----------');
    const classes = useStyles();
    const [modalStyle] =    useState(getModalStyle);
    const [open, setOpen] = useState(false);
    const {state:{resMessage},createUser,updateUser,deleteUser,cleanMessage} =    useContext(AdminContext);
    const snake = () =>{
        return <AppSnackbar command={true} />
    }
    useEffect(()=>{
        cleanMessage();
    },[])
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [values, setValues] = useState({
        companyIndex,
        agencyIndex,
        rowIndex:rowData && rowData.tableData ? rowData.tableData.id : null,
        company_id:compId,
        agency_id:agencyId,
        id:rowData ? rowData.id :null,
        fullName: rowData ? rowData.fullName :null,
        email: rowData ? rowData.email :null,
        password: null,
        roles:rowData ? rowData.roles : [],
    });
    const[passMode,setPassMode] = useState(false);
    const submitForm = () =>{
        if(type==="create"){
            createUser(values);
        }else if(type==="update"){
            updateUser(values);
        }else{
            deleteUser(values);
            setOpen(false);
        }
    }
    const handleChange = (prop,v) =>  {
        setValues({ ...values, [prop]: v });
    };
    const handleClickShowPassword = () => {
        setPassMode(!passMode);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [checked, setChecked] = useState(true);
    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };
    const body = () =>{
       return   (
           <div style={modalStyle} className={classes.paper}>

               <h2 id="simple-modal-title">{title}</h2>
               {type ==="delete" ?
               <Grid container spacing={3}>
                   <Grid item xs={12}>
                       <Typography>
                           {`למחוק משתמש ${rowData.fullName} ?`}
                       </Typography>
                   </Grid>
                   <Grid  item xs={6}>
                       <Button onClick={submitForm} variant="contained" color="primary" size="small" >
                           אישור
                       </Button>
                   </Grid>
               </Grid>
                   :
               <Grid container spacing={3}>
                   <Grid item xs={12}>
                       <TextField
                           fullWidth
                           label="שם מלא"
                           defaultValue=""
                           size="small"
                           value={values.fullName}
                           onChange={e =>handleChange("fullName",e.target.value)}

                       />
                   </Grid>
                   <Grid item  xs={12}>
                       <TextField
                           fullWidth
                           label="כתובת מייל"
                           defaultValue=""
                           size="small"
                           value={values.email}
                           onChange={e =>handleChange("email",e.target.value)}
                       />
                   </Grid>
                   <Grid item  xs={12}>
                       <Input
                           fullWidth
                           autoComplete="new-password"
                           id="standard-adornment-password"
                           type={passMode ? 'text' : 'password'}
                           value={values.password}
                           onChange={e =>handleChange("password",e.target.value)}

                           endAdornment={
                               <InputAdornment position="end">
                                   <IconButton
                                       aria-label="toggle password visibility"
                                       onClick={handleClickShowPassword}
                                       onMouseDown={handleMouseDownPassword}
                                   >
                                       {passMode ? <Visibility /> : <VisibilityOff />}
                                   </IconButton>
                               </InputAdornment>
                           }
                       />
                   </Grid>
                   <Grid item  xs={12}>
                       <Autocomplete
                           multiple
                           size="small"
                           options={listRoles}
                           defaultValue={rowData ? rowData.roles : []}
                           getOptionLabel={(option) => option.description}
                           onChange={(e,v) =>handleChange("roles",v)}
                           renderInput={(params) => (
                               <TextField {...params} variant="outlined" label="פרופיל" placeholder="פרופיל" />
                           )}
                       />
                   </Grid>
                   {/*<Grid item  xs={12}>*/}
                   {/*    <FormControlLabel*/}
                   {/*        control={<Switch size="small" checked={checked} onChange={toggleChecked} />}*/}
                   {/*        label="פעיל"*/}
                   {/*    />*/}
                   {/*</Grid>*/}
                   <Grid  item xs={6}>
                       <Button onClick={submitForm} variant="contained" color="primary" size="small" >
                           אישור
                       </Button>
                   </Grid>
                   <Grid  item xs={6}>
                       <Typography>
                           {resMessage}
                       </Typography>
                   </Grid>
               </Grid>
               }
           </div>
       );
    }

    const RelevantIcon = () =>{
        switch(ButtonIcon){
            case 'PersonAddIcon': return <PersonAddIcon color="primary" />
            case 'DeleteIcon': return <DeleteIcon color="secondary" />
            case 'EditIcon': return <EditIcon color="primary"  />
            default: return <PersonAddIcon />
        }
    }
    return (
        <>
            <Tooltip title={title} aria-label="add">
            <IconButton aria-label="back"  size="small" onClick={handleOpen}>
                {RelevantIcon()}
            </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body()}
            </Modal>
        </>
    );
}
