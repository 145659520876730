import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi'
const personReducer = (state,action) =>{
    switch (action.type) {
        case 'get_driver':

            return{
                ...state,
                driver:action.payload.driver,
                attachedCab:action.payload.cab,
                contractsHistory:action.payload.contractsHist
            }
        case 'update_person_details':
            return {...state,
                driver:action.payload
            }
        case 'driver_history':
            return {...state,
                driverHist:action.payload
            }
    }
}
const getDriverData = dispatch => async (id) =>{
    const response = await InterceptorApi().get(`/api/driverPage/${id}`)
    console.log("--*");
    console.log(id);
    console.log(response);
    console.log("*--");
    console.log('343434343434');console.log(response);console.log('343434343434');

    dispatch({type:'get_driver',payload:response.data})
}
const updatePersonDetails = dispatch =>async(data) =>{

    const response = await InterceptorApi().put('/api/updatePerson',data)
    dispatch({type:'update_person_details',payload:response.data.supplier})
}
const getDriverHistory = dispatch => async (id) =>{
    const response = await InterceptorApi().get(`/api/getDriverRentHistory/${id}`)
    console.log('6655--'); console.log(response); console.log('6655--');
    dispatch({type:'driver_history',payload:response.data})
}
export const {Provider,Context} = createDataContext(
    personReducer,
    {getDriverData,updatePersonDetails,getDriverHistory},
    []
)
