import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Context as VehiclesContext} from "../../../context/VehiclesContext";
import {Context as PersonContext} from "../../../context/PersonContext";
import Button from "@material-ui/core/Button";
import SearchPerson from "../../../components/SearchPerson";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import PortraitIcon from '@material-ui/icons/Portrait';
import HomeIcon from '@material-ui/icons/Home';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import CalendarViewDayOutlinedIcon from '@material-ui/icons/CalendarViewDayOutlined';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TitleIcon from '@material-ui/icons/Title';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tooltip from "@material-ui/core/Tooltip";
import {Context as NotificationContext} from '../../../context/NotificationContext'
import Divider from "@material-ui/core/Divider";
import moment from "moment/moment";



export default function VehicleDetails(props){
    const history = useHistory();
    const {state:{newVehicle,vehicle,warningAttention,warning},addVehicle} = useContext(VehiclesContext);
    const {pipeMessage} =useContext(NotificationContext);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const {state:{selectedPersonForVehicle}} = useContext(PersonContext);
    const handleSubmitClick = () =>{
        setButtonDisabled(true);
        addVehicle(state);
    }
    let vehicleObj= {
            id: null,
            carNumber: null,
            bodyNumber:null ,
            vehicleOwner:null ,
            brand: null,
            model: null,
            onRoadDate: null,
            hand: null,
            handType: null,
            vehicleColor:null,
            vehicleTestDate:null
        }
    const [vehicleEditMode, setVehicleEditMode] = useState(false);
    const [state, setState] = useState(vehicleObj);
    const handleChange = (k,v) =>{
        setState({
            ...state,
            [k]: v,
        });
    }
    useEffect(() => {
        if(props.src==='NEW_VEHICLE')
            setVehicleEditMode(true);
    }, [props])
    useEffect(()=>{
        if(selectedPersonForVehicle){
            setState({
                ...state,
                vehicleOwner:selectedPersonForVehicle
            });
        }
    },[selectedPersonForVehicle])
    useEffect(()=>{
        if(newVehicle && newVehicle.id){
            setState({
                ...state,
                id:newVehicle.id
            });
            pipeMessage('הפעולה התבצעה בהצלחה','success');
            setButtonDisabled(false)
            history.push(`${newVehicle.carNumber}`);
        }
    },[newVehicle])
    useEffect(()=>{
        console.log('vehicleqqqqqqqqqqqqq',vehicle,props)
        if(vehicle && vehicle.id === props.vehicleId){
            vehicleObj={
                id:vehicle.id,
                carNumber:vehicle.carNumber,
                bodyNumber:vehicle.bodyNumber,
                brand:vehicle.brand,
                model:vehicle.model,
                onRoadDate:vehicle.onRoadDate,
                hand:vehicle.hand,
                handType:vehicle.handType,
                vehicleOwner:vehicle.vehicleOwner,
                vehicleColor:vehicle.vehicleColor,
                vehicleTestDate:vehicle.vehicleTestDate ? moment(vehicle.vehicleTestDate).format('YYYY-MM-DD') : null
            }
            setState(vehicleObj)
        }
    },[vehicle])
    useEffect(()=>{

        if(typeof warningAttention !== 'undefined' ){
            pipeMessage(warning,'error')
        }

    },[warningAttention])
    return(
        <Grid
            container
            spacing={3}
        >
            {!props.detailsForContract ?
            <>
                <Grid item xs={6}>רכב ובעל הרכב</Grid>
                <Grid item xs={6}>
                    <Grid
                        spacing={1}

                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Grid item xs={2}>
                            <Tooltip title="ערוך" aria-label="add">
                                <IconButton
                                    disabled={vehicle && vehicle.vehicleBook && (vehicle.vehicleBook.buyDeal || vehicle.vehicleBook.saleDeal )}
                                    size="small" onClick={()=>setVehicleEditMode(vehicleEditMode !== true)} color="primary">
                                    {vehicleEditMode===true ? <ArrowForwardIcon/> : <EditIcon/>}
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
            </>:null
            }

            <Grid item xs={5} >
                <Grid container spacing={3} >
                    <Grid item xs={12}>פרטי בעל הרכב</Grid>
                    {vehicleEditMode ?
                        <Grid item xs={12}><SearchPerson source = 'addVehicle' /></Grid>
                        :null
                    }

                    <List dense={true}>
                        <ListItem>
                            <ListItemIcon>
                                <PersonOutlineIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={state.vehicleOwner && state.vehicleOwner.fullName ? state.vehicleOwner.fullName :'--'}
                                secondary={'שם מלא'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PortraitIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={state.vehicleOwner && state.vehicleOwner.nationalId ?state.vehicleOwner.nationalId:'--'}
                                secondary={'מספר זהות'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={state.vehicleOwner && state.vehicleOwner.phoneOne ? state.vehicleOwner.phoneOne:'--'}
                                secondary={'טלפון ראשי'}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={state.vehicleOwner && state.vehicleOwner.phoneTwo ?state.vehicleOwner.phoneTwo:'--'}
                                secondary={'טלפון משני'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={state.vehicleOwner && state.vehicleOwner.fullAddress ?state.vehicleOwner.fullAddress:'--'}
                                secondary={'כתובת'}
                            />
                        </ListItem>
                    </List>

                </Grid>
            </Grid>
            {/*Vehicle details*/}
            <Grid item xs={5} >
                <Grid container spacing={3}>
                    <Grid item xs={12}>פרטי הרכב</Grid>
                    {vehicleEditMode ?
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    size={'small'}
                                    variant="outlined"
                                    disabled={state.id !== null}
                                    style = {{width: 300}}
                                    value={state.carNumber}
                                    onChange={e=>handleChange('carNumber',e.target.value)}
                                    label="מס' רכב" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size={'small'}
                                    variant="outlined"
                                    style = {{width: 300}}
                                    value={state.bodyNumber}
                                    onChange={e=>handleChange('bodyNumber',e.target.value)}
                                    label="מספר שילדה" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size={'small'}
                                    variant="outlined"
                                    style = {{width: 300}}
                                    value={state.brand}
                                    onChange={e=>handleChange('brand',e.target.value)}
                                    label="מותג" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size={'small'}
                                    variant="outlined"
                                    style = {{width: 300}}
                                    value={state.model}
                                    onChange={e=>handleChange('model',e.target.value)}
                                    label="דגם" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size={'small'}
                                    variant="outlined"
                                    style = {{width: 300}}
                                    value={state.onRoadDate}
                                    onChange={e=>handleChange('onRoadDate',e.target.value)}
                                    label="מועד עליה לכביש" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size={'small'}
                                    variant="outlined"
                                    style = {{width: 300}}
                                    type="date"
                                    value={state.vehicleTestDate}
                                    onChange={e=>handleChange('vehicleTestDate',e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="תאריך חידוש טסט" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size={'small'}
                                    variant="outlined"
                                    style = {{width: 300}}
                                    value={state.hand}
                                    onChange={e=>handleChange('hand',e.target.value)}
                                    label="יד" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size={'small'}
                                    variant="outlined"
                                    style = {{width: 300}}
                                    value={state.handType}
                                    onChange={e=>handleChange('handType',e.target.value)}
                                    label="סוג" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    size={'small'}
                                    variant="outlined"
                                    style = {{width: 300}}
                                    value={state.vehicleColor}
                                    onChange={e=>handleChange('vehicleColor',e.target.value)}
                                    label="צבע הרכב" />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={buttonDisabled}
                                    onClick={() =>handleSubmitClick()}
                                    variant="contained" size ="small" style = {{width: 300}} fullWidth color="primary">
                                    {state.id ? 'עדכן':'צור'}
                                </Button>
                            </Grid>
                        </>
                        :
                        <List dense={true}>
                            <ListItem>
                                <ListItemIcon>
                                    <DriveEtaIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={state.carNumber}
                                    secondary={'מספר רכב'}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <EventAvailableIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={state.onRoadDate}
                                    secondary={'מועד עליה לכביש'}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <EventAvailableIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={state.vehicleTestDate ? state.vehicleTestDate :'--'}
                                    secondary={'תאריך חידוש טסט'}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <BookmarkBorderOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={state.brand}
                                    secondary={'מותג'}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <CheckBoxOutlineBlankIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={state.model}
                                    secondary={'מודל'}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <RadioButtonUncheckedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={state.hand}
                                    secondary={'יד'}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <TitleIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={state.handType}
                                    secondary={'סוג'}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CalendarViewDayOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={state.vehicleColor ? state.vehicleColor :'--'}
                                    secondary={'צבע הרכב'}
                                />
                            </ListItem>
                        </List>
                    }

                </Grid>
            </Grid>
        </Grid>

    )
}
