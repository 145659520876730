import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi';

const vehiclesReducer =(state,action) =>{
    switch (action.type) {
        case 'fetch_single_vehicle':
            return{
                ...state,
                vehicle:action.payload
            }
        case 'active_component':
            return{
                ...state,
                activeComponent:action.payload
            }
        case 'vehicle_from_new_creation':
            return{
                ...state,
                newVehicle:action.payload,
                vehicle:action.payload,
                success:'הוספת רכב ' + action.payload.carNumber + ' התבצעה בהצלחה'
            }
        case 'issue_alert':

            return{
                ...state,
                warning:action.payload.msg,
                warningAttention:!state.warningAttention
            }
        case 'toggle_book':
            return{
                ...state,
                vBookEnlarged:action.payload,
            }
        case 'update_ownership':
            return{
                ...state,
                vehicle:action.payload.dataRes,
            }
        case 'create_buy_vehicle_payment_outcome':
            const v = {...state.vehicle}
            v.vehicleBook.buyDeal = action.payload
            return{
                ...state,
                vehicle:v,

            }
        case 'update_data_front_end':
            const v2 = {...state.vehicle}
            v2.vehicleBook.buyDeal = action.payload
            return {
                ...state,
                vehicle:v2,
            }
        case 'update_data_front_end_sale':

            const payment = [...state.vehicle.vehicleBook.saleDeal.payments]
            const position = payment.findIndex(p => p.id ===action.payload.id);
            payment[position].comment = action.payload.comment
            return {
                ...state,
                payments:payment,
            }
        case 'update_payment_comment_front_end':
            const payments = [...state.vehicle.vehicleBook.buyDeal.payments]
            const pos = payments.findIndex(p => p.id ===action.payload.id);
            payments[pos].comment = action.payload.comment
            return {
                ...state,
                payments,
            }
        case 'update_vehicle_front_major_change':
            return {
                ...state,
                vehicle:action.payload,
            }
        case 'recipet_vehicle_dialog_action':
            return {
                ...state,
                actionBoolean:action.payload.bool,
                dummy:!state.dummy,
                typeOfProcess:action.payload.type
            }
        case 'agencies_from_fetch':
            return {
                ...state,
                agenciesFetched:action.payload
            }
        case 'update_vehicle_receipt_date':
        return {
            ...state,
            vehicle:action.payload,
            dummy2:!state.dummy2
        }

        case 'update_vehicle_deliver_date':
        return {
            ...state,
            vehicle:action.payload,
            dummy2:!state.dummy2
        }
        case 'close_receive_car_dialog':
        return {
            ...state,
            actionBoolean:action.payload,
            dummy:!state.dummy2
        }
        case 'cab_vehicle_history':
        return {
            ...state,
            cabsHistory:action.payload,
        }
        case 'clear_vehicle_object':
        return {
            ...state,
            vehicle:null,

        }
        default: return state;
    }
}
const clearVehicleObject = dispatch =>()=>{
    dispatch({type:'clear_vehicle_object'});
}
const getCabsHistoryForVehicle =dispatch =>async(vehicleNumber)=>{
    const response = await InterceptorApi().get(`/api/getCabsHistoryForVehicle/${vehicleNumber}`);
    dispatch({type:'cab_vehicle_history',payload:response.data});
}
const closeDialog = dispatch =>async()=>{
    dispatch({type:'close_receive_car_dialog',payload:false});
}
const getAgencies = dispatch =>async()=>{
    const response = await InterceptorApi().get('/api/getAgencies');
    localStorage.setItem('agencies',JSON.stringify(response.data))
    dispatch({type:'agencies_from_fetch',payload:response.data});
}
const updateVehicleReceiptDate = dispatch =>async(data,type)=>{
    let url;
    if(type==='vehicleRecipient'){
        url = 'api/updateVehicleReceiptDate';
        const response = await InterceptorApi().put(`/${url}`, data);
        dispatch({type: 'update_vehicle_receipt_date', payload:response.data});
    }else{
        url = 'api/updateVehicleDeliverDate';
        const response = await InterceptorApi().put(`/${url}`, data);
        dispatch({type: 'update_vehicle_deliver_date', payload:response.data});
    }

}
const openRecieptVehicleDialog = dispatch =>(value,type) =>{
    dispatch({type:'recipet_vehicle_dialog_action',payload: {bool:value,type:type}});
}
const setVehicleAfterMajorChange = dispatch =>(vehicle) =>{
    dispatch({type:'update_vehicle_front_major_change',payload:vehicle});
}
const updatePaymentCommentFrontEnd= dispatch =>(data) =>{
    dispatch({type:'update_payment_comment_front_end',payload:data});
}
const updatePaymentFrontEndSale = dispatch =>(data) =>{
    dispatch({type:'update_data_front_end_sale',payload:data});
}
const updatePaymentFrontEnd = dispatch =>(data) =>{
    dispatch({type:'update_data_front_end',payload:data});
}

const savePayment = dispatch =>async(data) =>{
    const response = await InterceptorApi().post('/api/createVehiclePayment', data);
    dispatch({type: 'create_buy_vehicle_payment_outcome', payload:response.data});
}

const updateIsOwnerShip = dispatch =>async(value,dealId,type,vehicle) =>{
    const vehicleId = vehicle.id;
    const companyId = vehicle.companyId
    const obj ={value,dealId,type,vehicleId,companyId}
    const response =await InterceptorApi().put(`/api/updateIsOwnerShip`, {obj});

    dispatch({type:'update_ownership',payload: {dataRes:response.data,type}})

}
const toggleVehicleBook=dispatch =>(value) =>{
    dispatch({type:'toggle_book',payload:value})
}
const addVehicle =dispatch =>async (data)=>{
    console.log('55555555555',data)
    const response = await InterceptorApi().post(`/api/addVehicle`,data);
    if(response && response.response && response.response.status=== 411){
        //no car number
        dispatch({type: 'issue_alert', payload:{newVehicle:data,msg:'יש למלא מספר רכב' }});
    }else if(response && response.response && response.response.status=== 406){
        //no car number
        dispatch({type: 'issue_alert', payload:{newVehicle:data,msg:'מספר רכב נדרש להיות מספר בלבד' }});
    }else if(response.status === 226){
        //car number already exist
        dispatch({type: 'issue_alert', payload:{newVehicle:data,msg:'מספר רכב כבר קיים על רכב אחר' }});
    }else if(response.status === 206){
        //no person
        dispatch({type: 'issue_alert', payload: {newVehicle:data,msg:'יש לשבץ בעלים לרכב'}});
    }else if (response.status === 200){
        //success
        dispatch({type: 'vehicle_from_new_creation', payload:response.data });
    }

}

const setActiveComponent =dispatch =>(data)=>{
    dispatch({type: 'active_component', payload:data });
}
const fetchVehicleByCarNumber = dispatch => async(carNumber) =>{
    const response =await InterceptorApi().get(`/api/getSingleVehicleByCarNumber/${carNumber}`);
    dispatch({type:'fetch_single_vehicle',payload:response.data.car})
}
export const {Provider,Context} =createDataContext(
    vehiclesReducer,
    {clearVehicleObject,getCabsHistoryForVehicle,closeDialog,getAgencies,updateVehicleReceiptDate,openRecieptVehicleDialog,setVehicleAfterMajorChange,savePayment,fetchVehicleByCarNumber,setActiveComponent,addVehicle,updatePaymentCommentFrontEnd,
        toggleVehicleBook,updatePaymentFrontEndSale,updateIsOwnerShip,updatePaymentFrontEnd},
    []
)
