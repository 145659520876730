import createDataContext from "./createDataContext";
import InterceptorApi from '../api/InterceptorApi'

const cabsReducer = (state,action) =>{
    switch (action.type){
        case 'get_cabs_home_page':
            return{
                ...state,
                cabsHomePageView:action.payload
            }
        case 'update_comment':
            const newComment = state.cabsHomePageView;
            newComment[action.payload.index].comment =action.payload.response.data.comment
            return{
                ...state,
                newComment
            }
    }
}
const getCabsHomePageView  = dispatch => async () =>{
    const response = await InterceptorApi().get('/api/getCabsHomePage')
    console.log('555555555555555');console.log(response);console.log('555555555555555');

    dispatch({type:'get_cabs_home_page',payload:response.data})
}
const addNewCab = dispatch => async () =>{

}
const updateCabCommentFromHomePage = dispatch => async (Cab,index) =>{

    const response = await InterceptorApi().post('/api/updateCabComment', Cab)
    dispatch({type:'update_comment',payload: {response,index}})
}
export const {Provider,Context} = createDataContext(
    cabsReducer,
    {getCabsHomePageView,updateCabCommentFromHomePage,addNewCab},
    []
)
