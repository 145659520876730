import React from 'react';
import Vehicles from "./Vehicles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {vehicleStatuses} from '../../../helpers/convertedPaymentTypes';
import {Link} from "react-router-dom";
const switchLink = (colData,ownerId) =>{
    return (
        <Typography variant="body2">
            <Link
                to={{pathname: `/vehicles/${colData}`,state: {extraData:{id: ownerId}}}}
                style={{ textDecoration: 'none',color:'blue'}}>{colData}
            </Link>
        </Typography>
    )
}

const renderRow=(val) =>{
    return <Typography variant="body2">{val}</Typography>
}
const FleetVehiclePool = () =>{
    const columns =[
        {
            title: 'מספר רכב',
            field: 'carNumber',
            enableColumnFilter: false,
            render: rowData => rowData && rowData.carNumber ? switchLink(rowData.carNumber,rowData.id) : "--"
        },
        {
            title: 'מותג',
            field: 'brand',
            enableColumnFilter: true,
            render: rowData => rowData && rowData.brand ? renderRow(rowData.brand) : " "

        },
        {
            title: 'מודל',
            field: 'model',
            render: rowData => rowData && rowData.model ? renderRow(rowData.model) : renderRow('--')

        },
        {
            title: 'יד',
            field: 'hand',
            render: rowData => rowData && rowData.hand ? renderRow(rowData.hand) : renderRow('--')

        },
        {
            title: 'צבע',
            field: 'color',
            render: rowData => rowData && rowData.vehicleColor ? renderRow(rowData.vehicleColor) : renderRow('--')


        },
        {
            title: 'מועד עליה לכביש',
            field: 'onRoadDate',
            render: rowData => rowData && rowData.onRoadDate ? renderRow(rowData.onRoadDate) : renderRow('--')


        },
        {
            title: 'מועד חידוש רישיון',
            field: 'onRoadDate',
            render: rowData => rowData && rowData.vehicleTestDate ? renderRow(moment(rowData.vehicleTestDate).format('YYYY-MM-DD') ) : renderRow('--')


        },
        {
            title: 'סטטוס',
            field: 'status',
            render:rowData =>rowData && rowData.vehicleBook && rowData.vehicleBook.vehicleStatus ? renderRow(vehicleStatuses(rowData.vehicleBook.vehicleStatus)) : renderRow('--')

        },
        {
            title: 'ק"מ',
            field: 'kilometers',
            render: rowData => rowData && rowData.vehicleBook && rowData.vehicleBook.kilometerOnReceiptDate ? renderRow(rowData.vehicleBook.kilometerOnReceiptDate) : renderRow('--')
        },
        {
            title: 'סוג',
            field: 'handType',
            render:rowData =>rowData && rowData.handType ? renderRow(rowData.handType) : renderRow('--')

        },
        {
            title: 'תאריך יצירה',
            field: 'createdOn',
            render:rowData =>moment(rowData.createdOn).format('YYYY-MM-DD')

        },
    ]


    return (
        <Vehicles columns={columns} ptype={'vFleet'} url={'findVehiclesInStock'} title={'רכבים במלאי'} />
    )
}
export default FleetVehiclePool;
