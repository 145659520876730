import React from "react";
import axios from 'axios'
const domain = window.location.hostname === "localhost" ? 'http://localhost:8080' : 'https://motorlogi.com';

const instance =  axios.create({
    baseURL:domain,
});
const InterceptorApi = () =>{

    instance.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.interceptors.request.use(

        (req) =>{

            if(req.url !=='/api/authenticate'){
                req.headers.Authorization = 'Bearer ' + localStorage.getItem("token");
            }
            if(req.url ==='/api/refreshToken'){
                req.headers.isRefreshToken = true;
            }

            return req;
        }
    );
    instance.interceptors.response.use(
        (res) => {
            return res;
        },
         (err) => {
             let e = { ...err}

             if(e.response && e.response.data && e.response.data.status ===487){

                     localStorage.clear();
                     window.location = '/login';
             }
            return e;
        }
    );
    return instance;

}
export default InterceptorApi;
