import React, {useContext, useEffect} from 'react'
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";

import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import {Context as VehiclesContext} from "../../../context/VehiclesContext";

const switchLink = (colData,Id,pathPart) =>{
    return (
        <Typography>
            <Link
                to={{pathname: `/${pathPart}/${colData}`,state: {extraData:{id:Id}}}}
                style={{ textDecoration: 'none',color:'blue'}}>{colData}
            </Link>
        </Typography>
    )
}
const CabVehicleHistory = (props) =>{
    const {state:{vehicle,cabsHistory}, getCabsHistoryForVehicle} = useContext(VehiclesContext);
    useEffect(()=>{
        if(!cabsHistory)
            getCabsHistoryForVehicle(vehicle.carNumber)
    },[cabsHistory,vehicle])
    const cabVehicleHistColumns =[
        {
            title: 'מספר זכות',
            field: 'cabNumber',
            render: rowData => rowData && rowData.cabNumber ? switchLink(rowData.cabNumber,rowData.cabId,'cabs') : ""
        },
        {
            title: 'בעל הזכות',
            field: 'cabOwnerFullName',
            render: rowData => rowData && rowData.cabOwnerFullName ? switchLink(rowData.cabOwnerFullName,rowData.cabOwnerFullName,'cabs') : ""
        },
        {
            title: 'מספר רכב',
            field: 'vehicleNumber',
            render: rowData => rowData && rowData.vehicleNumber ? switchLink(rowData.vehicleNumber,rowData.vehicleNumber,'vehicles') : ""
        },
        {
            title: 'בעל הרכב',
            field: 'vehicleOwnerFullName',
            render: rowData => rowData && rowData.vehicleOwnerFullName ? switchLink(rowData.vehicleOwnerFullName,rowData.vehicleOwnerId,'cabsowners') : ""
        },
        {
            title: 'נהג',
            field: 'driverFullName',
            render: rowData => rowData && rowData.driverFullName ? switchLink(rowData.driverFullName,rowData.driverId,'drivers') : ""
        },
        {
            title: 'תאריך יצירה',
            field: 'createdOn',
            render: rowData => rowData && rowData.createdOn ? moment(rowData.createdOn).format('DD/MM/YYYY HH:MM:SS') : ""
        },
    ]

    return(
        <MaterialTable
            size="small"
            title={'היסטוריית זכויות ציבוריות'}
            columns={cabVehicleHistColumns}
            style={{boxShadow:"none"}}
            data= {cabsHistory}
            localization={{
                toolbar: {
                    searchPlaceholder: ''
                },
                header: {
                    actions:''
                },

            }}
            options={{actionsColumnIndex: -1, search: false, padding: "dense"}}
        />
    )

}

export default CabVehicleHistory;
