import React, {useContext, useEffect, useState} from 'react'
import MaterialTable from "material-table";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from "@material-ui/core/IconButton";
import {Context as AdminContext} from "../../../context/AdminContext";
import Chip from "@material-ui/core/Chip";
import UserManagementModal from "./UserManagementModal";
import Tooltip    from "@material-ui/core/Tooltip";
import moment from "moment";
import AppSnackbar from "../../../components/AppSnackbar";
const rolesArray = (data) =>{
    return (
        data.map((value,index) =>{
          return  <Chip size="small" variant="outlined" color="secondary" label={value.description} />
        }
    ))
}
function changeEvent(v){
   // console.log(v)
}

const UsersTable = (props) =>{

    const {state:{resMessage},showUsersTable,createUser} = useContext(AdminContext);
    const shoeUsers = () =>{
        showUsersTable(false)
    }
    const  columns= [
        {
            title: "מס' משתמש",
            field: 'id',
            editable:'never',
        },
        {
            title: "מס' סוכנות",
            field: 'agency_id',
            editable:'never',
            hidden: true
        },
        {
            title: "שם משתמש",
            field: 'fullName',
         //   editable:'never',
           // hidden: true
        },
        {
            title: 'דוא"ל',
            field: 'email',
            //hidden: true
        },
        {
            title: "פרופיל",
            field: 'role',
            render:(rowData) =>rolesArray(rowData.roles),
            //editComponent:({ value, onChange }) => MultiSelectComponent(value,onChange),
        },
        {
            title: 'ת.יצירה',
            field: 'createdOn',
            editable:'never',
            render:(rowData) =>beautifyDate(rowData.createdOn)
        },
        {
            title: 'סטטוס',
            field: 'statusId',
            render:(rowData ) =>rowData.status ? rowData.status.description:null,
            lookup: { 1: 'פעיל', 2: 'לא פעיל' },
        },
        {
            title: 'פעולות',
            field: 'actions',
            render:(rowData ) =>button(rowData),
        },

    ]
    const beautifyDate =(date) =>{
        return moment(date).format('YYYY-MM-DD');
    }
    const button = (param) =>{
        return (
            <>
                <UserManagementModal
                    agencyId ={props.agencyId}
                    compId={props.compId}
                    title="ערוך משתמש קיים"
                    ButtonIcon ="EditIcon"
                    companyIndex={props.companyIndex}
                    agencyIndex={props.agencyIndex}
                    rowData ={param}
                    type={'update'}
                />
                <UserManagementModal
                    agencyId ={props.agencyId}
                    compId={props.compId}
                    title="מחק משתמש"
                    ButtonIcon ="DeleteIcon"
                    companyIndex={props.companyIndex}
                    agencyIndex={props.agencyIndex}
                    rowData ={param}
                    type={'delete'}
                />
            </>
        )
    }
    return (
        <>
            <div style={{display:'flex'}} >
                {resMessage ? <AppSnackbar openFromOut={true} message={resMessage} /> : null}
                <Tooltip title="חזור" aria-label="back">
                <IconButton aria-label="back" color="primary" onClick={shoeUsers}>
                    <ArrowForwardIcon />
                </IconButton>
                </Tooltip>
                <UserManagementModal
                    compId={props.compId}
                    agencyId ={props.agencyId}
                    title='צור משתמש חדש'
                    ButtonIcon ="PersonAddIcon"
                    companyIndex={props.companyIndex}
                    agencyIndex={props.agencyIndex}
                    type={'create'}
                />

            </div>

            <MaterialTable
                size={ 'small'}
                title=""
                columns={columns}
                style={{boxShadow:"none"}}
                data={props.users}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'חפש'
                    },
                    header: {
                        actions:''
                    },

                }}
                options={{
                    actionsColumnIndex: -1,
                    padding: "dense",
                    paging: false
                }}

            />
        </>
    )
}

export default UsersTable;
