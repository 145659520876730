import React, {useContext, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import CreateCompany from "./CreateCompany";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import GestureIcon from '@material-ui/icons/Gesture';
import InterceptorApi from "../../../api/InterceptorApi";

export default function AdminDialog(props) {

    const [open, setOpen] = React.useState(false);
    const [imageUrl, setImageUrl] = useState();
    const getSignature =async() =>{
        const f= InterceptorApi().get(`/api/getCompanySignature`,{responseType: 'blob'})
        f.then((res) =>{
            const file = new Blob([res.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            setImageUrl(fileURL)
        })
    }


    const buttonType =() =>{
        switch (props.requestType) {
            case 'createCompany':
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpen}>
                        <AddBoxOutlinedIcon />
                        {props.title}
                    </Button>
                )
            case 'updateCompany' :
                return(
                    <IconButton onClick={handleClickOpen} aria-label="edit" size="small">
                        <EditIcon color="default" />
                    </IconButton>
                )
            case 'deleteCompany':
                return (
                    <IconButton onClick={handleClickOpen} aria-label="delete" size="small">
                        <DeleteIcon color="secondary" />
                    </IconButton>
                )
            case 'createAgency':
                return (
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={handleClickOpen}>
                        {props.title}
                    </Button>
                )

            case 'updateAgency' :
                return (
                    <IconButton onClick={handleClickOpen} aria-label="edit">
                        <EditIcon color="default" />
                    </IconButton>
                )
            case 'deleteAgency' :
                return (
                    <IconButton onClick={handleClickOpen} aria-label="delete">
                        <DeleteIcon color="secondary" />
                    </IconButton>
                )
            case 'viewCompSignature' :
                return (
                    <IconButton onClick={handleClickOpen} aria-label="delete">
                        <GestureIcon  />
                    </IconButton>
                )

        }
    }

    const handleClickOpen = () => {
        if(props.requestType ==='viewCompSignature'){
            getSignature();
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {buttonType()}
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
            >
                <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content(props.requestType,props.compId,props.indexNumber,props.oldName,props.agencyIndex,props.agencyId,props.companyGovId,imageUrl)}
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </div>
    );
}
function content( componentName,compId,indexNumber,oldName,agencyIndex,agencyId,companyGovId,imageUrl){
    switch(componentName){
        case 'createAgency':
            return <CreateCompany reqType={componentName} compId={compId} indexNumber={indexNumber} />
        case 'updateAgency':
        case 'deleteAgency':
            return <CreateCompany reqType={componentName} oldName={oldName} compId={compId} indexNumber={indexNumber} agencyIndex={agencyIndex} agencyId={agencyId} />
        case 'createCompany':
            return <CreateCompany companyGovId={companyGovId} reqType={componentName} />
        case 'updateCompany':
        case 'deleteCompany':
            return <CreateCompany companyGovId={companyGovId} reqType={componentName} oldName={oldName} compId={compId} indexNumber={indexNumber}/>
        case 'viewCompSignature':
            return <img src={imageUrl} alt="חתימה לא נמצאה" width="100" height="100" />;
        default:
            return null
    }
}
AdminDialog.propTypes = {
   // onClose: PropTypes.func.isRequired,
    //open: PropTypes.bool.isRequired,
    requestType: PropTypes.string.isRequired

};
