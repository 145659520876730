import React from 'react';
import MaterialTable from 'material-table';
import InterceptorApi from "../api/InterceptorApi";


export default function ServerSideTable(props) {

    return (
            <MaterialTable
                title={props.title}
                columns={props.columns}
                data={(query) =>
                    new Promise ((resolve,reject)=>{
                        let url= `/api/${props.url}?`
                        url +=`_page=${query.page}`
                        url +=`&_limit=${query.pageSize}`
                        if(props.initialFilter ){
                            url +=`&pType=${props.initialFilter}`
                        }
                        if(query.search){
                            url +=`&q=${query.search}`
                        }
                        InterceptorApi().get(url)
                        .then(resp =>{
                            resolve({
                                    data:resp && resp.data && resp.data.data ? resp.data.data : [],
                                    page:query.page,
                                    totalCount:resp && resp.data && resp.data.totalCount ? resp.data.totalCount : 0
                                }
                            )
                        })
                    })}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'חפש'
                    },
                    header: {
                        actions:''
                    },
                    body:{
                        editRow:{
                            deleteText:'האם אתה בטוח שברצונך למחוק שורה זו?'
                        }
                    }

                }}
                style={{boxShadow:"none",tableLayout: 'auto',minHeight: 0}}
                options={{
                    actionsColumnIndex: -1,
                    padding: "dense",
                    showFirstLastPageButtons: true,
                    pageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    filtering:props.filtering,
                }}

            />

    );
}
