import React, {useContext, useEffect, useRef, useState} from 'react';
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import {Context as VehiclesContext} from "../../../context/VehiclesContext";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BootstrapInput from "../../../style-directory/BootstrapInput";
import NativeSelect from "@material-ui/core/NativeSelect";
import {Context as NotificationContext} from "../../../context/NotificationContext";
import LocalSignatureDialog from "../single-cab/NewContract/LocalSignatureDialog";
import {Context as VehicleContractContext} from "../../../context/VehicleContractContext";

const ReceivingCar = (props) =>{
    const {pipeMessage} = useContext(NotificationContext);
    const {state:{actionBoolean,agenciesFetched,dummy,dummy2,vehicle,typeOfProcess},closeDialog,getAgencies,updateVehicleReceiptDate} = useContext(VehiclesContext);
    const {state:{dataUrlReceiveVehicleState,dataUrlDeliverVehicleState}} = useContext(VehicleContractContext);

    const agencies =useRef(JSON.parse(localStorage.getItem('agencies')));
    const [open, setOpen] = useState(false);

    const [state,setState] = useState({
        vehicleId:null,
        receiptDate:moment().format('MM/DD/YYYY'),
        comments:null,
        kilometers:null,
        ownershipTransferred:false,
        printVehicleAcceptanceCertificate:true,
        agency:-1,
        disabled:false,
        signature:null
    });
    const handleConfirm =()=>{
        if(state.agency === -1){
            pipeMessage('נא לבחור סוכנות','error');
            return;
        }
        setState({
            ...state,
            disabled: false//true
        })
        updateVehicleReceiptDate(state,typeOfProcess)
    }
    const handleClose = () => {
        closeDialog()
        setOpen(false);
    };
    const handleChange =(k,v) =>{

        setState({
            ...state,
            [k]:v
        })
    }
    useEffect(()=>{
        if(Object.keys(agencies).length === 0){
            getAgencies();
        }
        if(dummy){
            setOpen(actionBoolean);
        }

    },[dummy]);


    useEffect(()=>{
        if(dataUrlReceiveVehicleState){
            setState({
                ...state,
                signature:dataUrlReceiveVehicleState
            })
            pipeMessage('חתימה נקלטה בהצלחה','success');
        }

    },[dataUrlReceiveVehicleState]);

    useEffect(()=>{
        if(dataUrlDeliverVehicleState){
            setState({
                ...state,
                signature:dataUrlDeliverVehicleState
            })
            pipeMessage('חתימה נקלטה בהצלחה','success');
        }
    },[dataUrlDeliverVehicleState]);

    useEffect(()=>{
        if(dummy2)
            pipeMessage('הפעולה בוצעה בהצחלה','success');
    },[dummy2]);

    useEffect(()=>{
        if(Object.keys(agencies).length === 0){
            getAgencies();
            agencies.current = agenciesFetched;
        }
    },[agenciesFetched]);

    useEffect(()=>{
        if(vehicle)
            setState({

                ...state,
                vehicleId:vehicle.id
            })
    },[vehicle]);
    return(
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{ typeOfProcess ==='vehicleRecipient' ? 'קליטת רכב' : 'מסירת רכב' }</DialogTitle>
            <DialogContent>
                <FormControlLabel
                    control= {
                        <TextField
                            variant="outlined"
                            size={'small'}
                            value={state.receiptDate}
                            label={ typeOfProcess ==='vehicleRecipient' ? 'תאריך קליטה' : 'תאריך מסירה' }
                            style = {{width: 400}}
                            type="date"
                            onChange={
                                e=> handleChange("receiptDate",e.target.value)
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    }
                 label={''}/>



                <br /><br />
                <FormControlLabel
                    control={
                        <TextField
                            placeholder='ק"מ'
                            size={'small'} variant="outlined" style = {{width: 400}}
                            value={state.kilometers}
                            onChange={e=>handleChange('kilometers',e.target.value)}
                            label="קילומטראז'"
                        />
                    }
                    label={''}
                />
                <br /><br />
                <FormControlLabel
                    control={
                        <TextField
                            multiline
                            rows={3}
                            maxRows={4}
                            placeholder="שינויים מיום הצגתו"
                            size={'small'} variant="outlined" style = {{width: 400}}
                            value={state.comments}
                            onChange={e=>handleChange('comments',e.target.value)}
                            label="שינויים מיום הצגתו"
                        />
                    }
                    label={''} />
                <br /><br />
                <FormControlLabel
                    control={
                        <NativeSelect
                            style={{ minWidth: '500' }}
                            value={state.agency}
                            onChange={(e) => handleChange('agency',e.target.value)}

                            input={<BootstrapInput/>}
                        >
                            <option key={-1} value={-1}>{'בחר סוכנות'}</option>

                            {Object.entries(agencies.current).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </NativeSelect>
                    }
                    label={''}
                    />

                {/*<br /><br />*/}
                {/*<FormControlLabel*/}
                {/*    control={*/}
                {/*        <Checkbox*/}
                {/*            color="primary"*/}
                {/*            edge="start"*/}
                {/*            checked={state.ownershipTransferred}*/}
                {/*            onChange={(e) =>handleChange('ownershipTransferred',e.target.checked)}*/}
                {/*            size={'small'}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    label={ typeOfProcess ==='vehicleRecipient' ? 'העברת בעלות מהמוכר לסוכנות' : 'העברת בעלות מהסוכנות לקונה' }*/}
                {/*/>*/}
                <br /><br />
                <FormControlLabel
                    control={
                        <LocalSignatureDialog conType={typeOfProcess} conTitle={'חתימת מקבל הרכב'} />
                    }
                    label={ '' }
                />

            </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleClose()} color="primary">
                        בטל
                    </Button>
                    <Button disabled={state.disabled || !vehicle} onClick={()=>handleConfirm()} color="primary">
                        אישור
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ReceivingCar;
