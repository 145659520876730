import React, {useContext, useEffect, useRef, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import {Context as SingleCabContext}      from '../../../context/SingleCabContext';
import CreateContractComponent from "./NewContract/CreateContractComponent";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import FormDialogReplaceCar from "./FormDialogReplaceCar";
import WarningIcon from '@material-ui/icons/Warning';
import FlickeringEffectIcon from "../../../components/FlickeringEffectIcon";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReplayIcon from '@material-ui/icons/Replay';
import moment from 'moment';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import TollIcon  from '@material-ui/icons/Toll';
import InterceptorApi from "../../../api/InterceptorApi";
import FormDialogCloseContract from "./FormDialogCloseContract";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PaymentsHistoryTable from "../payments-page/PaymentsHistoryTable";
import CreatePaymentComponent from "../payments-page/CreatePaymentComponent";
import AppSnackbar from "../../../components/AppSnackbar";
import FileUploader from "../../../components/file-uploader/FilesUploader";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import BootstrapInput from "../../../style-directory/BootstrapInput";
import CabContractsHistory from "./CabContractsHistory";
import MaterialTable from "material-table";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme)=>({
    paper: {
        padding: theme.spacing(0.5),
        width: "100%",
        height: "100%",
        color: theme.palette.text.primary,
    },
    paper2:{
        padding: theme.spacing(2),

    },
    opacityCover :{
        padding: theme.spacing(2),
        opacity:0.5
    },
    textField: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        minWidth: 250,
        maxHeight:50

    },
    formControl: {
        margin: theme.spacing(1),
    },
}));
const switchLink = (colData,Id,pathPart) =>{
    return (
        <Typography>
            <Link
                to={{pathname: `/${pathPart}/${colData}`,state: {extraData:{id:Id}}}}
                style={{ textDecoration: 'none',color:'blue'}}>{colData}
            </Link>
        </Typography>
    )
}
const SingleCabNew =(props) =>{
    const classes = useStyles();
    const splitted = props.location.pathname.split('/');
    const {state:{dataCab,activeComponent,snackbar,vehiclesHistory,recurring,singleCabChangeEvent},fetchCabDataByCabNumber,setActiveComponent,getVehiclesHistory} = useContext(SingleCabContext);
    const [conSource,setConSourc] = useState();
    const cabRef = useRef(null);
    // useEffect(() =>{
    //     if(!dataCab || cabRef.current !== splitted[2]){
    //         cabRef.current = splitted[2]
    //         fetchCabDataByCabNumber(cabRef.current)
    //     }
    //
    // },[dataCab])
    useEffect(() => {
        console.log('rrrrrrrrrrrrrrrrrrr',dataCab)
            cabRef.current = splitted[2]
            fetchCabDataByCabNumber(cabRef.current)
    }, [singleCabChangeEvent]);
    const handleCreateContractOnClickEvent = (source,name) => {
        setConSourc(source);
        setActiveComponent(name);
    }
    const cabVehicleHistColumns =[
        {
            title: 'מספר זכות',
            field: 'cabNumber',
            render: rowData => rowData && rowData.cabNumber ? switchLink(rowData.cabNumber,rowData.cabId,'cabs') : ""
        },
        {
            title: 'בעל הזכות',
            field: 'cabOwnerFullName',
            render: rowData => rowData && rowData.cabOwnerFullName ? switchLink(rowData.cabOwnerFullName,rowData.cabOwnerFullName,'cabs') : ""
        },
        {
            title: 'מספר רכב',
            field: 'vehicleNumber',
            render: rowData => rowData && rowData.vehicleNumber ? switchLink(rowData.vehicleNumber,rowData.vehicleNumber,'vehicles') : ""
        },
        {
            title: 'בעל הרכב',
            field: 'vehicleOwnerFullName',
            render: rowData => rowData && rowData.vehicleOwnerFullName ? switchLink(rowData.vehicleOwnerFullName,rowData.vehicleOwnerId,'cabsowners') : ""
        },
        {
            title: 'נהג',
            field: 'driverFullName',
            render: rowData => rowData && rowData.driverFullName ? switchLink(rowData.driverFullName,rowData.driverId,'drivers') : ""
        },
        {
            title: 'תאריך יצירה',
            field: 'createdOn',
            render: rowData => rowData && rowData.createdOn ? moment(rowData.createdOn).format('DD/MM/YYYY HH:MM:SS') : ""
        },
    ]
    const innerGrid =(data) =>{
        return(
            <Grid
                spacing={1}
                display="flex"
                container
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
                justify="flex-end"

            >
                <Grid item xs={12}>
                    {data}
                </Grid>
            </Grid>
        )
    }
    const createPaymentsComponentProps =(nextPayment,entity,paymentAmount,paymentType,contractId,fullPrice,conStatus,title,vat) =>{

        return (
            <>
                <CreatePaymentComponent
                    nextPayment={nextPayment}
                    entity ={entity}
                    paymentAmount={paymentAmount}
                    paymentType={paymentType}
                    contractId={contractId}
                    fullPrice={fullPrice}
                    conStatus={conStatus}
                    title={title}
                    vat={vat}

                />
            </>
        )
    }
    const createContractComponentWithProps = (source) =>{
        return (
            <>
                <CreateContractComponent source={conSource} />
            </>
        )
    }
    const [action,setAction] = useState();
    const handleChangeEvent =(event) =>{
        setAction(event.target.value);
        if(event.target.value==='newContract'){
            handleCreateContractOnClickEvent('FREE_SOLO',"CREATE_CONTRACT")
        } else if(event.target.value==='viewContractsHistory'){
            setActiveComponent('viewContractsHistory')
        } else if(event.target.value==='viewVehiclesHistory'){
            if(!vehiclesHistory){
                getVehiclesHistory(dataCab.id);
            }
            setActiveComponent('viewVehiclesHistory')
        } else if(event.target.value !=='0'){

        }
        setAction('0')
    }
    const optionValues =[
        {value:'0',text:'בחר פעולה'},
        {value:'newContract',text:'חוזה חדש'},
        {value:'viewContractsHistory',text:'היסטוריית חוזים'},
        {value:'viewVehiclesHistory',text:'היסטוריית רכבים'},
    ]
    const checkPaymentsConsistency = (id) =>{
        if(recurring){
            const currentRecurring =recurring.find(e=> e.contractId ===id);
            if(currentRecurring){
                return currentRecurring.balance === 0;
            }
        }
        return true
    }
    const getBalance = (id) =>{
        if(recurring){
            const currentRecurring =recurring.find(e=> e.contractId ===id);
            if(currentRecurring && currentRecurring.balance)
                return  currentRecurring.balance +' ₪';
            return 0+' ₪'
        }
        return 0+' ₪'
    }
    const getNextRecurringDate = (id,type ) =>{

        if(recurring){
            const currentRecurring =recurring.find(e=> e.contractId ===id);
            if(currentRecurring)
                return  moment(currentRecurring.nextPayment).format('YYYY-MM-DD');
        }
        return '0000-00-00'
    }
    return(
        <>
            <Grid
                container
                spacing={1}
                direction="row"
            >

                <Grid item xs ={12} >
                    <Paper variant="outlined" className={classes.paper2} >
                        <Grid
                            container
                            direction="row"
                        >

                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    {dataCab && dataCab.cabNumber ? dataCab.cabNumber :'--'}
                                </Typography>
                                <Typography variant="body2">
                                    מספר זכות
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    {dataCab && dataCab.supplier ? dataCab.supplier.fullName : '--'}
                                </Typography>
                                <Typography variant="body2">
                                    בעל הזכות
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    {dataCab && dataCab.driver ? dataCab.driver.fullName : '--'}
                                </Typography>
                                <Typography variant="body2">
                                    נהג
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    {dataCab && dataCab.vehicle ? dataCab.vehicle.carNumber : '--'}
                                </Typography>
                                <Typography variant="body2">
                                    רכב
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    {dataCab && dataCab.vehicle && dataCab.vehicle.vehicleOwner ? dataCab.vehicle.vehicleOwner.fullName : '--'}
                                </Typography>
                                <Typography variant="body2">
                                    בעל הרכב
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>

                                <FormControl>
                                    <NativeSelect
                                        value={action}
                                        onChange={(e)=>handleChangeEvent(e)}
                                        input={<BootstrapInput />}
                                    >
                                        {optionValues.map((option, index) =>(
                                                <option key={index} value={option.value}>{option.text}</option>
                                            )
                                        )}
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                { activeComponent ==='SUPPLIER_PAYMENTS' ?
                    <Grid item xs={12} >
                        <Paper variant="outlined" className={classes.paper} >
                            <Grid
                                container
                                direction="row"
                            >
                                <Grid item xs={2}>
                                    <Tooltip title="חזור" aria-label="go-back">
                                        <IconButton
                                            style={{marginRight:5}}
                                            aria-label="go-back"
                                            onClick={() =>setActiveComponent('MAIN')}
                                        >
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid
                                className={classes.paper2  }
                                container
                                spacing={1}
                                direction="row"

                            >
                                <Grid item xs={3}>
                                    {createPaymentsComponentProps(
                                        getNextRecurringDate(dataCab.supplierContract.id),
                                        'owner',
                                        dataCab.supplierContract.paymentAmount,
                                        dataCab.supplierContract.paymentType,
                                        dataCab.supplierContract.id,
                                        dataCab.supplierContract.amount + dataCab.supplierContract.vat,
                                        dataCab.supplierContract.status,
                                        ` תשלום לבעל הזכות  ${dataCab.supplier.fullName} חוזה ${dataCab.supplierContract.id} `,
                                        0.17
                                    )}
                                </Grid>
                                <Grid item xs={9}>
                                    {dataCab.supplierContract.payments ?
                                        <PaymentsHistoryTable
                                            payments = {dataCab.supplierContract.payments}
                                            entity = 'owner'
                                            tableTitle={` פירוט תשלומים לבעל הזכות  ${dataCab.supplier.fullName} חוזה מספר ${dataCab.supplierContract.id} `}
                                        />:null
                                    }

                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>
                    :
                    null
                }
                { activeComponent ==='CONSUMER_PAYMENTS' ?
                    <Grid item xs={12} >
                        <Paper variant="outlined" className={classes.paper} >
                            <Grid
                                container
                                direction="row"
                            >
                                <Grid item xs={2}>
                                    <Tooltip title="חזור" aria-label="go-back">
                                        <IconButton
                                            style={{marginRight:5}}
                                            aria-label="go-back"
                                            onClick={() =>setActiveComponent('MAIN')}
                                        >
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid
                                className={classes.paper2  }
                                container
                                spacing={1}
                                direction="row"

                            >
                                <Grid item xs={4}>
                                    {createPaymentsComponentProps(
                                        getNextRecurringDate(dataCab.consumerContract.id),
                                        'consumer',
                                        dataCab.consumerContract.paymentAmount,
                                        dataCab.consumerContract.paymentType,
                                        dataCab.consumerContract.id,
                                        dataCab.consumerContract.amount + dataCab.consumerContract.vat,
                                        dataCab.consumerContract.status,
                                        ` תקבולים מהנהג  ${dataCab.driver.fullName} חוזה ${dataCab.consumerContract.id} `,
                                        0.17
                                    )}
                                </Grid>
                                <Grid item xs={8}>
                                    {dataCab.consumerContract.payments ?
                                        <PaymentsHistoryTable
                                            entity = 'consumer'
                                            payments = {dataCab.consumerContract.payments}
                                            tableTitle={` פירוט תקבולים מהנהג  ${dataCab.driver.fullName} חוזה מספר ${dataCab.consumerContract.id} `}
                                        />:null
                                    }

                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>
                    :
                    null
                }
                {dataCab && dataCab.supplierContract && (!activeComponent || activeComponent ==='MAIN') ?
                    <>
                        <Grid item xs ={4} >
                            <Paper variant="outlined" className={classes.paper} >
                                <Grid
                                    className={classes.paper2}
                                    container
                                    spacing={1}
                                    direction="row"
                                >
                                    <Grid item xs={6}>חוזה בעל זכות</Grid>
                                    <Grid item xs={6}>
                                        <Grid
                                            spacing={1}
                                            display="flex"
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="flex-end"
                                            justify="flex-end"
                                        >
                                            {
                                                checkPaymentsConsistency(dataCab.supplierContract.id) ? null :
                                                    <Grid item xs={2}>
                                                        <FlickeringEffectIcon Icon={WarningIcon} color={'orange'} size={'small'} title={'אנא בדוק את רצף התשלומים. ייתכן כי חסרים תשלומים'} />
                                                    </Grid>
                                            }

                                            <Grid item xs={2}>
                                                <Tooltip title="תשלומים" aria-label="add">
                                                    <IconButton
                                                        onClick={() =>handleCreateContractOnClickEvent(null,'SUPPLIER_PAYMENTS')}
                                                        size="small"
                                                        aria-label="delete"
                                                    >
                                                        <TollIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>

                                            <Grid item xs={2}>
                                                <Tooltip title="צפה בחוזה חתום" aria-label="add">
                                                    <IconButton size="small" aria-label="delete"
                                                                onClick={() =>displayContract(dataCab.supplierContract.id)}>
                                                        <VisibilityIcon fontSize="small" color="secondary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Tooltip title="חדש חוזה" aria-label="add">
                                                    <IconButton
                                                        onClick={() =>handleCreateContractOnClickEvent('ownerRenewal','CREATE_CONTRACT')}
                                                        size="small" aria-label="delete">
                                                        <ReplayIcon fontSize="small" color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormDialogCloseContract
                                                    props={{con:dataCab.supplierContract,refrence: 'supplier'}}
                                                    entity={'supplier'}
                                                    title={'סיום חוזה מספר '+dataCab.supplierContract.id}
                                                    content={'חוזה מול בעל זכות'}
                                                />
                                            </Grid>


                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}><Divider /></Grid>

                                </Grid>
                                <Grid
                                    className={dataCab.supplierContract.status === 'ACTIVE' ? classes.paper2 : classes.opacityCover }
                                    container
                                    spacing={1}
                                    direction="row"

                                >
                                    <Grid item xs={6}>סטטוס</Grid><Grid item xs={6}>{innerGrid(setStatus(dataCab.supplierContract.status))}</Grid>
                                    <Grid item xs={6}>תאריך התחלה</Grid><Grid item xs={6}>{innerGrid(moment(dataCab.supplierContract.startDate).format('YYYY-MM-DD'))}</Grid>
                                    <Grid item xs={6}>תאריך סיום מקורי</Grid><Grid item xs={6}>{innerGrid(moment(dataCab.supplierContract.endDate).format('YYYY-MM-DD'))}</Grid>
                                    <Grid item xs={6}>תאריך סיום בפועל</Grid><Grid item xs={6}>{innerGrid(moment(dataCab.supplierContract.closeDate).format('YYYY-MM-DD'))}</Grid>
                                    <Grid item xs={6}>מספר תשלומים</Grid><Grid item xs={6}>{innerGrid(dataCab.supplierContract.numberOfPayments)}</Grid>
                                    <Grid item xs={6}>מספר תשלומים ששולמו</Grid>
                                    <Grid item xs={6}>
                                    {
                                        dataCab.supplierContract.payments !==null ?  innerGrid(dataCab.supplierContract.payments.length) : innerGrid(0)
                                    }
                                    </Grid>
                                    <Grid item xs={6}>מאזן חוזי</Grid>
                                    <Grid item xs={6}>
                                        {
                                          dataCab.supplierContract ?  innerGrid(getBalance(dataCab.supplierContract.id)) : innerGrid(0)
                                        }
                                    </Grid>
                                    <Grid item xs={6}>סך כל תשלום</Grid><Grid item xs={6}>{innerGrid(dataCab.supplierContract.paymentAmount+' ₪')}</Grid>
                                    <Grid item xs={6}>אמצעי תשלום</Grid><Grid item xs={6}>{innerGrid(paymentType(dataCab.supplierContract.paymentType))}</Grid>
                                    <Grid item xs={6}>שולם מצטבר</Grid><Grid item xs={6}>
                                    {
                                        dataCab.supplierContract.payments !==null ?  innerGrid(accumulatedPaymentAmount(dataCab.supplierContract.payments)+' ₪') : innerGrid(0+' ₪')
                                    }
                                </Grid>
                                    <Grid item xs={6}>תשלום הבא בתאריך</Grid><Grid item xs={6}>{innerGrid(getNextRecurringDate(dataCab.supplierContract.id,'supplier'))}</Grid>
                                    <Grid item xs={6}>הערות</Grid><Grid item xs={6}>{innerGrid(paymentType(dataCab.supplierContract.comments))}</Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </>
                    :
                    <>
                        {(!activeComponent || activeComponent === 'MAIN') ?
                            <Grid item xs={4} >
                                <Paper variant="outlined" className={classes.paper} >
                                    <Grid
                                        className={classes.paper2}
                                        container
                                        spacing={1}
                                        direction="row"
                                    >
                                        <Grid item xs={12}>חוזה בעל זכות</Grid>
                                        <Grid item xs={12}><Divider /></Grid>

                                    </Grid>
                                    <Grid
                                        className={classes.paper2}
                                        container
                                        spacing={1}
                                        direction="row"
                                    >
                                        <Grid item xs={12}>{!dataCab ? 'אנא המתן':dataCab && !dataCab.supplierContract ? 'אין חוזה בעל זכות משודך':null}</Grid>

                                    </Grid>
                                </Paper>
                            </Grid>
                            :
                            null
                        }
                    </>
                }

                {dataCab && dataCab.consumerContract && (!activeComponent || activeComponent ==='MAIN')  ?
                    <Grid item xs ={4} >
                        <Paper variant="outlined" className={classes.paper} >
                            <Grid
                                className={classes.paper2}
                                container
                                spacing={1}
                                direction="row"
                            >
                                <Grid item xs={6}>חוזה נהג</Grid>
                                <Grid item xs={6}>
                                    <Grid
                                        spacing={1}
                                        display="flex"
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                        justify="flex-end"
                                    >
                                        {
                                            checkPaymentsConsistency(dataCab.consumerContract.id) ? null :
                                                <Grid item xs={2}>
                                                    <FlickeringEffectIcon Icon={WarningIcon} color={'orange'} size={'small'} title={'אנא בדוק את רצף התשלומים. ייתכן כי חסרים תשלומים'} />
                                                </Grid>
                                        }
                                        <Grid item xs={2}>
                                            <Tooltip title="תשלומים" aria-label="add">
                                                <IconButton
                                                    onClick={() =>handleCreateContractOnClickEvent(null,'CONSUMER_PAYMENTS')}
                                                    size="small"
                                                    aria-label="delete"
                                                >
                                                    <TollIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Tooltip title="צפה בחוזה חתום" aria-label="add">
                                                <IconButton size="small" aria-label="delete" onClick={() =>displayContract(dataCab.consumerContract.id)}>
                                                    <VisibilityIcon fontSize="small" color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Tooltip title="חדש חוזה" aria-label="add">
                                                <IconButton
                                                    onClick={() =>handleCreateContractOnClickEvent('driverRenewal','CREATE_CONTRACT')}
                                                    size="small" aria-label="delete">
                                                    <ReplayIcon fontSize="small" color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormDialogCloseContract
                                                props={{con:dataCab.consumerContract,refrence: 'consumer'}}
                                                entity={'supplier'}
                                                title={'סיום חוזה מספר '+dataCab.consumerContract.id}
                                                content={'חוזה מול נהג'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}><Divider /></Grid>

                            </Grid>
                            <Grid
                                className={dataCab.consumerContract.status === 'ACTIVE' ? classes.paper2 : classes.opacityCover }
                                container
                                spacing={1}
                                direction="row"
                            >
                                <Grid item xs={6}>סטטוס</Grid><Grid item xs={6}>{innerGrid(setStatus(dataCab.consumerContract.status))}</Grid>
                                <Grid item xs={6}>תאריך התחלה</Grid><Grid item xs={6}>{innerGrid(moment(dataCab.consumerContract.startDate).format('YYYY-MM-DD'))}</Grid>
                                <Grid item xs={6}>תאריך סיום מקורי</Grid><Grid item xs={6}>{innerGrid(moment(dataCab.consumerContract.endDate).format('YYYY-MM-DD'))}</Grid>
                                <Grid item xs={6}>תאריך סיום בפועל</Grid><Grid item xs={6}>{innerGrid(moment(dataCab.consumerContract.closeDate).format('YYYY-MM-DD'))}</Grid>
                                <Grid item xs={6}>מספר תשלומים</Grid><Grid item xs={6}>{innerGrid(dataCab.consumerContract.numberOfPayments)}</Grid>
                                <Grid item xs={6}>מספר תשלומים ששולמו</Grid>
                                <Grid item xs={6}>
                                {
                                    dataCab.consumerContract.payments !==null ?  innerGrid(dataCab.consumerContract.payments.length) : innerGrid(0)
                                }
                                </Grid>
                                <Grid item xs={6}>מאזן חוזי</Grid>
                                <Grid item xs={6}>
                                    {
                                        dataCab.consumerContract ?  innerGrid(innerGrid(getBalance(dataCab.consumerContract.id))) : innerGrid(0)
                                    }
                                </Grid>
                                <Grid item xs={6}>סך כל תשלום</Grid><Grid item xs={6}>{innerGrid(dataCab.consumerContract.paymentAmount+' ₪')}</Grid>
                                <Grid item xs={6}>אמצעי תשלום</Grid><Grid item xs={6}>{innerGrid(paymentType(dataCab.consumerContract.paymentType))}</Grid>
                                <Grid item xs={6}>שולם מצטבר</Grid><Grid item xs={6}>
                                {
                                    dataCab.consumerContract.payments !==null ?  innerGrid(accumulatedPaymentAmount(dataCab.consumerContract.payments)+' ₪') : innerGrid(0+' ₪')
                                }
                            </Grid>
                                <Grid item xs={6}>תשלום הבא בתאריך</Grid><Grid item xs={6}>{innerGrid(getNextRecurringDate(dataCab.consumerContract.id,'consumer'))}</Grid>
                                <Grid item xs={6}>הערות</Grid><Grid item xs={6}>{innerGrid(dataCab.consumerContract.comments)}</Grid>

                            </Grid>
                        </Paper>
                    </Grid>
                    :
                    <>
                        {(!activeComponent || activeComponent === 'MAIN') ?
                            <Grid item xs={4}>
                                <Paper variant="outlined" className={classes.paper}>
                                    <Grid
                                        className={classes.paper2}
                                        container
                                        spacing={1}
                                        direction="row"
                                    >
                                        <Grid item xs={12}>חוזה נהג</Grid>
                                        <Grid item xs={12}><Divider/></Grid>

                                    </Grid>
                                    <Grid
                                        className={classes.paper2}
                                        container
                                        spacing={1}
                                        direction="row"
                                    >
                                        <Grid item
                                              xs={12}>{!dataCab ? 'אנא המתן' : dataCab && !dataCab.consumerContract ? 'אין חוזה נהג משודך. ייתכן ויש חוזה עתיתי. חוזה עתידי קיים ניתן לראות בהיסטוריית חוזים' : null}</Grid>


                                    </Grid>
                                </Paper>
                            </Grid>
                            :
                            null
                        }
                    </>
                }


                {dataCab && dataCab.vehicle && (!activeComponent || activeComponent ==='MAIN') ?
                    <Grid item xs ={4} >
                        <Paper variant="outlined" className={classes.paper} >
                            <Grid
                                className={classes.paper2}
                                container
                                spacing={1}
                                direction="row"
                            >
                                <Grid item xs={6}>פרטי רכב</Grid>
                                <Grid item xs={6}>
                                    <Grid
                                        spacing={1}
                                        display="flex"
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                    >
                                        <Grid item xs={2}>
                                            <FormDialogReplaceCar type={'replace'} title={' החלפת רכב מספר ' +dataCab.vehicle.carNumber } />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormDialogReplaceCar type={'detouch'} title={' הסרת רכב מספר ' +dataCab.vehicle.carNumber } />
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={12}><Divider /></Grid>

                            </Grid>
                            <Grid
                                className={classes.paper2}
                                container
                                spacing={1}
                                direction="row"
                            >
                                <Grid item xs={6}>תאריך כניסה למערכת</Grid><Grid item xs={6}>{innerGrid(moment(dataCab.vehicle.createdOn).format('YYYY-MM-DD'))}</Grid>
                                <Grid item xs={6}>מועד עליה לכביש</Grid><Grid item xs={6}>{innerGrid(dataCab.vehicle.onRoadDate)}</Grid>
                                <Grid item xs={6}>מספר רכב</Grid><Grid item xs={6}>{innerGrid(dataCab.vehicle.carNumber)}</Grid>
                                <Grid item xs={6}>מספר שילדה</Grid><Grid item xs={6}>{innerGrid(dataCab.vehicle.bodyNumber)}</Grid>
                                <Grid item xs={6}>מותג</Grid><Grid item xs={6}>{innerGrid(dataCab.vehicle.brand)}</Grid>
                                <Grid item xs={6}>דגם</Grid><Grid item xs={6}>{innerGrid(dataCab.vehicle.model)}</Grid>
                                <Grid item xs={6}>יד</Grid><Grid item xs={6}>{innerGrid(dataCab.vehicle.hand)}</Grid>
                                <Grid item xs={6}>סוג</Grid><Grid item xs={6}>{innerGrid(dataCab.vehicle.handType)}</Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    :
                    <>
                        {(!activeComponent || activeComponent === 'MAIN') ?
                            <Grid item xs={4}>
                                <Paper variant="outlined" className={classes.paper}>
                                    <Grid
                                        className={classes.paper2}
                                        container
                                        spacing={1}
                                        direction="row"
                                    >
                                        <Grid item xs={6}>פרטי רכב</Grid>
                                        <Grid item xs={6}>
                                            <Grid
                                                spacing={1}
                                                display="flex"
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="flex-end"
                                                justify="flex-end"
                                            >
                                                <Grid item xs={2}>
                                                    {dataCab ?  <FormDialogReplaceCar type={'new'} title={'שידוך רכב לזכות ציבורית'} /> : null}
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}><Divider/></Grid>

                                    </Grid>
                                    <Grid
                                        className={classes.paper2}
                                        container
                                        spacing={1}
                                        direction="row"
                                    >
                                        <Grid item
                                              xs={12}>{!dataCab ? 'אנא המתן' : dataCab && !dataCab.vehicle ? 'רכב לא משודך' : null}</Grid>

                                    </Grid>
                                </Paper>
                            </Grid>
                            :
                            null
                        }
                    </>
                }
                {activeComponent ==="CREATE_CONTRACT" ?
                    <Grid item xs={12} >
                        {createContractComponentWithProps()}
                    </Grid>
                    :
                    null

                }
                {
                    activeComponent === 'viewContractsHistory' ?
                        <Grid item xs={12} >
                            <CabContractsHistory cabsRef={cabRef}  cabMumber={dataCab.cabNumber} id={dataCab.id} title={dataCab.cabNumber} />
                        </Grid>: null
                }
                {
                    activeComponent === 'viewVehiclesHistory' ?
                        <Grid item xs={12} >
                            <Paper variant="outlined" className={classes.paper} >
                                <Tooltip title="חזור" aria-label="go-back">
                                    <IconButton
                                        style={{marginRight:5}}
                                        aria-label="go-back"
                                        onClick={() =>setActiveComponent('MAIN')}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </Tooltip>
                                <MaterialTable
                                    size="small"
                                    title={'היסטוריית רכבים'}
                                    columns={cabVehicleHistColumns}
                                    style={{boxShadow:"none"}}
                                    data= {vehiclesHistory}
                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: ''
                                        },
                                        header: {
                                            actions:''
                                        },

                                    }}
                                    options={{actionsColumnIndex: -1, search: false, padding: "dense"}}
                                />
                            </Paper>
                        </Grid>: null
                }
            </Grid>
            {!!snackbar ?
                <AppSnackbar message={snackbar} openFromOut={!!snackbar} />
                :
                null
            }

            {
                dataCab ? <FileUploader module={'dataCabs'} id={dataCab.id} /> : null
            }


        </>
    )
}
const displayContract = (conId) =>{

    const f=   InterceptorApi().get(`/api/files/${conId}`,{responseType: 'blob'})
    f.then((res) =>{
        if(res.data && res.status ===200){
            const file = new Blob([res.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }else{
            if(res && res.status ===404)
                alert('לא הצלחנו למצוא את החוזה במערכת');
            else
                alert('משהו השתבש');
        }
    })
}

function getNextPaymentDate(paymentsArray,numOfPayments){
    if(!Array.isArray(paymentsArray) || paymentsArray.length ===0)
        return 'טרם בוצע תשלום ראשון';

    if(!paymentsArray[paymentsArray.length -1].dueDate)
        return 'טרם בוצע תשלום ראשון';

    const date = paymentsArray[paymentsArray.length -1].dueDate.substring(0,10);
    const formatedDate = new Date(date);
    const interval = 12/numOfPayments;
    const newDate = new Date(formatedDate.setMonth(formatedDate.getMonth()+interval));

    return moment(newDate).format('YYYY-MM-DD');
}
const paymentType =(type) =>{
    switch (type) {
        case 'FLUID': return 'העברה'
        case 'CHECKS': return 'המחאות'
        case 'WIRE_TRANSFER': return 'העברה בנקאית'
        case 'CREDIT_CARD': return 'כ.אשראי'
        case 'CASH': return 'מזומן'
        default: return 'ללא'
    }
}
function accumulatedPaymentAmount  (array){
    return  array.reduce((a,b) => a + b.amount, 0)
}

const setStatus =(status) =>{
    switch (status) {

        case 'CREATED': return <Chip variant="outlined" color="secondary" size="small" avatar={<Avatar>נ</Avatar>}  label="נוצר חדש" />
        case 'ACTIVE': return <Chip variant="outlined" color="primary" size="small" avatar={<Avatar>פ</Avatar>}  label="פעיל" />
        case 'FREEZE': return <Chip variant="outlined" size="small" avatar={<Avatar>מ</Avatar>}  label="מוקפא" />
        case 'CANCELED': return <Chip variant="outlined"  size="small" avatar={<Avatar>מ</Avatar>}  label="מבוטל" />
        case 'ENDED': return <Chip variant="outlined" size="small" avatar={<Avatar>ה</Avatar>}  label="הסתיים" />
        case 'STOPPED': return <Chip variant="outlined" size="small" avatar={<Avatar>ה</Avatar>}  label="הופסק" />
        default: return 'ללא'
    }
}
export default SingleCabNew;
