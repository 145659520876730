import React, {useContext, useEffect, useMemo, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import {Context as VehiclesContext} from '../../../context/VehiclesContext';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import PurchaseVehicleContract from "./vehicle-contract/PurchaseVehicleContract";
import VehicleDetails from "./VehicleDetails";
import Spinner from "../../../components/Spinner";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import VehicleBook from "./VehicleBook";
import NativeSelect from "@material-ui/core/NativeSelect";
import BootstrapInput from "../../../style-directory/BootstrapInput";
import FormControl from "@material-ui/core/FormControl";
import FileUploader from "../../../components/file-uploader/FilesUploader";

import ReceivingCar from "./ReceivingCar";
import CabVehicleHistory from "./CabVehicleHistory";
//import {Context as VehicleContractContext} from "../../../context/VehicleContractContext";

const useStyles = makeStyles((theme)=>({
    paper2:{
        padding: theme.spacing(2),
        width: "100%",
        height: "100%",
    },
    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }

}));
const VehicleComponent = (props) =>{
    const classes = useStyles();
    const splited = props.location.pathname.split('/');
    const {state:{vehicle, activeComponent,vBookEnlarged},clearVehicleObject, fetchVehicleByCarNumber,setActiveComponent,openRecieptVehicleDialog} = useContext(VehiclesContext);
    //const {setPersonIdBuyer,setPersonIdSeller} = useContext(VehicleContractContext);
    useEffect(()=>{
        if(splited[2] ==="addVehicle"){
            setActiveComponent("NEW_VEHICLE");
        }else if (activeComponent !== 'buyVehicle' && activeComponent !== 'saleVehicle'){
            setActiveComponent("MAIN");
        }
        if(activeComponent !== 'buyVehicle' && ((splited[2] !=="addVehicle" && !vehicle) || (vehicle && splited[2] !== vehicle.carNumber)))
            fetchVehicleByCarNumber(splited[2]);
        //
    },[vehicle]);
    useEffect(() => {
        return () =>clearVehicleObject()
    }, []);
    const [action,setAction] = useState();
    const handleChangeEvent =(event) =>{

        setAction(event.target.value);

        if(event.target.value==='vehicleRecipient'){
            openRecieptVehicleDialog(true,'vehicleRecipient');
        } else if(event.target.value==='vehicleDeliver'){
            openRecieptVehicleDialog(true,'vehicleDeliver');
        } else if(event.target.value !=='0'){
            setActiveComponent(event.target.value);
        }else if(event.target.value ==='cabHistoryForVehicle'){
            setActiveComponent(event.target.value);
        }
    }
    const optionValues =[
        {value:'0',text:'בחר פעולה'},
        {value:'buyVehicle',text:'עסקת קניית רכב'},
        {value:'saleVehicle',text:'עסקת מכירת רכב'},
        {value:'vehicleRecipient',text:'קליטת רכב'},
        {value:'vehicleDeliver',text:'מסירת רכב'},
        {value:'cabHistoryForVehicle',text:'היסטוריית זכויות לרכב'},
        ]
    const checkIfDisabled = (type) =>{
        switch(type){
            case 'buyVehicle':
                return !!(vehicle && vehicle.vehicleBook && vehicle.vehicleBook.buyDeal);
            case 'saleVehicle':
                 return !!(vehicle && vehicle.vehicleBook && vehicle.vehicleBook.saleDeal);
            case 'vehicleRecipient':
                return !(vehicle && vehicle.vehicleBook && vehicle.vehicleBook.buyDeal && (vehicle.vehicleBook.vehicleStatus ==='BOUT'|| vehicle.vehicleBook.vehicleStatus==='BUY_CONTRACT_SIGNED'));
            case 'vehicleDeliver':
                return !(vehicle && vehicle.vehicleBook && vehicle.vehicleBook.saleDeal && (vehicle.vehicleBook.vehicleStatus ==='SOLD'|| vehicle.vehicleBook.vehicleStatus==='SALE_CONTRACT_SIGNED'));
            case 'changeVehicleLocation': return true;
            default: return false;
        }
    }
    return(
        <>
            <Grid container direction={'row'} spacing={1}>
                <Grid item xs ={12} >
                    <Paper variant="outlined" className={classes.paper2} >
                        <Grid
                            container
                            direction="row"
                        >
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    {vehicle && vehicle.carNumber ? vehicle.carNumber :'--'}
                                </Typography>
                                <Typography variant="body2">
                                    מספר רכב
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    {vehicle && vehicle.vehicleOwner ? vehicle.vehicleOwner.fullName :'--'}
                                </Typography>
                                <Typography variant="body2">
                                    בעל הרכב
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    {vehicle && vehicle.brand ? vehicle.brand :'--'}
                                </Typography>
                                <Typography variant="body2">
                                   מותג
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    {vehicle && vehicle.model ? vehicle.model :'--'}
                                </Typography>
                                <Typography variant="body2">
                                    מודל
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    {vehicle && vehicle.onRoadDate ? vehicle.onRoadDate :'--'}
                                </Typography>
                                <Typography variant="body2">
                                    מועד עליה לכביש
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>

                                <FormControl>
                                    <NativeSelect
                                        value={action}
                                        onChange={(e)=>handleChangeEvent(e)}
                                        input={<BootstrapInput />}
                                    >
                                        {optionValues.map((option, index) =>(
                                                <option key={index} value={option.value} disabled={checkIfDisabled(option.value)}>{option.text}</option>
                                            )
                                        )}
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {!activeComponent || activeComponent ==='MAIN' ?
                    <>
                    {!vBookEnlarged || vBookEnlarged===false ?

                        <>
                            <Grid item xs ={8} >
                                <Card style={{height: "100%"}}>
                                    <CardContent >
                                        <Grid
                                            className={classes.paper2}
                                            container
                                            spacing={1}
                                            direction="row"
                                        >
                                            {!vehicle ?
                                                <Spinner />
                                                :
                                                <VehicleDetails vehicleId={vehicle && vehicle.id ? vehicle.id : null} src={vehicle && vehicle.vehicleBook ? 'VIEW_VEHICLE': 'EDIT_VEHICLE'} />
                                            }

                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs ={4}>
                                <Card style={{height: "100%"}}>
                                    <CardContent >
                                        <Grid
                                            className={classes.paper2}
                                            container
                                            spacing={1}
                                            direction="row"
                                        >
                                            {!vehicle ?
                                                <Spinner />
                                                :
                                                <VehicleBook />
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>
                        :
                        <Grid item xs ={12} style={{height: "100%"}}>
                        <Card >
                            <CardContent >
                                <Grid
                                className={classes.paper2}
                                container
                                spacing={1}
                                direction="row"
                                >
                                {!vehicle ?
                                    <Spinner />
                                    :
                                    <VehicleBook />
                                }

                                </Grid>
                            </CardContent>
                        </Card>
                        </Grid>
                    }
                    </>
                    :null
                }
                {activeComponent && (activeComponent ==='buyVehicle' || activeComponent ==='saleVehicle')?
                <Grid item xs={12}>
                    <PurchaseVehicleContract typeOfContract ={activeComponent} />
                </Grid>
                :null
                }
                {activeComponent && activeComponent ==='NEW_VEHICLE' ?
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <VehicleDetails src={'NEW_VEHICLE'} />
                        </CardContent>
                    </Card>
                </Grid>
                :
                null
                }
                {activeComponent && activeComponent ==='cabHistoryForVehicle' ?
                    <Grid item xs={12} >
                        <CabVehicleHistory />
                    </Grid>

                    :null
                }
                {activeComponent !=='NEW_VEHICLE' ?
                    <Grid item xs={12} >
                        {!vehicle ? <Spinner />:<FileUploader module={'vehicles'} id={vehicle && vehicle.id ? vehicle.id : null} />}
                    </Grid>
                    :null
                }

            </Grid>
            <ReceivingCar />
        </>
    )
}
export default VehicleComponent;
