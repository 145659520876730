import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";

import {AutoWidthSelect} from "../../../../components/SelectComponent"
import {Context as CreateContractContext} from "../../../../context/CreateContractContext"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Grid from "@material-ui/core/Grid";
import {Context as SingleCabContext} from "../../../../context/SingleCabContext";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";

const CreateContractContractDetails =() =>{
    const {state:{paymentAmount,contractType},
        setPaymentAmount,setStartDate,setEndDate,setDateFirstPayment,
        setPaymentType,setDatePaymentCycle,setNumPayments} = useContext(CreateContractContext);
    const {state:{dataCab}} = useContext(SingleCabContext);
    let contractDetailsObj;
    switch (contractType) {
        case 'ownerRenewal':
            contractDetailsObj ={

                startDate:moment(dataCab.supplierContract.endDate).format('YYYY-MM-DD'),
                endDate:moment(dataCab.supplierContract.endDate).add(12, 'month').format('YYYY-MM-DD'),
                dateFirstPayment:moment(dataCab.supplierContract.endDate).format('YYYY-MM-DD'),
                datePaymentCycle:dataCab.supplierContract.paymentCycle,
                paymentType:dataCab.supplierContract.paymentType,
                numPayments:dataCab.supplierContract.numberOfPayments,
                paymentAmount:dataCab.supplierContract.paymentAmount,

            }
            break;
        case 'driver':
        case 'owner':
        default:
            contractDetailsObj ={
                startDate:'',
                endDate:'',
                dateFirstPayment:'',
                datePaymentCycle:'',
                paymentType:'',
                numPayments:'',
                paymentAmount:'',
            }
            break;
        case 'driverRenewal':
            contractDetailsObj ={
                startDate:dataCab.consumerContract.endDate,
                endDate:moment(dataCab.consumerContract.endDate).add(12, 'M').format('mm/dd/yyyy'),
                dateFirstPayment:dataCab.consumerContract.endDate,
                datePaymentCycle:dataCab.consumerContract.paymentCycle,
                paymentType:dataCab.consumerContract.paymentType,
                numPayments:dataCab.consumerContract.numberOfPayments,
                paymentAmount:dataCab.consumerContract.paymentAmount,
            }
            break;


    }
    useEffect(() =>{
        if(contractType ==='ownerRenewal' || contractType ==='driverRenewal'){
            setStartDate(contractDetailsObj.startDate)
            setEndDate(contractDetailsObj.endDate)
            setDateFirstPayment(contractDetailsObj.dateFirstPayment)
            setPaymentType(contractDetailsObj.paymentType)
            setDatePaymentCycle(contractDetailsObj.datePaymentCycle)
            setNumPayments(contractDetailsObj.numPayments)
            setPaymentAmount(contractDetailsObj.paymentAmount)

        }
    },[contractType])
    const [state, setState] = useState(contractDetailsObj);

    const handleDateChange = (refrence,date) => {

        switch (refrence) {
            case"S_DATE":setStartDate(date)
                break;

            case"E_DATE":setEndDate(date)
                break;
            case"F_DATE":setDateFirstPayment(date)
                break;
            default:
                break;
        }
    };
    const handlePaymentAmountChange = (value) =>{

        setPaymentAmount(value);
        setState({...state,paymentAmount:value})

    }
    return(
        <>
        <Grid item xs={12}>
            <TextField
                variant="outlined"
                size={'small'}
                defaultValue={state.startDate}
                label={'תאריך התחלה'}
                style = {{width: 400}}
                type="date"
                onChange={
                    e=> handleDateChange("S_DATE",e.target.value)
                }
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                variant="outlined"
                size={'small'}
                defaultValue={state.endDate}
                label={'תאריך סיום'}
                style = {{width: 400}}
                type="date"
                onChange={
                    e=> handleDateChange("E_DATE",e.target.value)
                }
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                variant="outlined"
                size={'small'}
                defaultValue={state.dateFirstPayment}
                label={'תאריך תשלום ראשון'}
                style = {{width: 400}}
                type="date"
                onChange={
                    e=> handleDateChange("F_DATE",e.target.value)
                }
                InputLabelProps={{
                    shrink: true,
                }}
            />        </Grid>
        <Grid item xs={12}>
            <AutoWidthSelect defaultValue={state.datePaymentCycle} fullWidth HelperText="מועד תשלום חודשי" refrence={"M_DATE"} />
        </Grid>
        <Grid item xs={12}>
            <AutoWidthSelect defaultValue={state.paymentType} fullWidth HelperText="סוג עסקה" refrence="DEAL_TYPE" />
        </Grid>
        <Grid item xs={12}>
            <AutoWidthSelect defaultValue={state.numPayments} HelperText="מס' תשלומים" refrence="NUM_PAY" />
        </Grid>
        <Grid item xs={12}>
            <TextField
                size={'small'}
                variant="outlined"
                style = {{width: 400}}
                id="standard-basic"
                value={state.paymentAmount}
                onChange={e=>handlePaymentAmountChange(e.target.value)}
                label="סכום פר תשלום"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            ₪
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
    </>
    )
}
export default CreateContractContractDetails
