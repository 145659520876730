import React, { useState, useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import { Context as SingleCabContext } from '../../../context/SingleCabContext';
import TablePagination from '@material-ui/core/TablePagination';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {paymentTypes} from "../../../helpers/convertedPaymentTypes";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

const useStyles = makeStyles({
    tableContainer: {
        width: '100%',
        boxShadow: 'none',
    },
    table: {
        border: 'none',
        tableLayout: 'fixed',
    },
    grayedRow: {
        backgroundColor: '#eee',
    },
    clickableRow: {
        cursor: 'pointer',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        textTransform: 'none',
        size: 'small',
        variant: 'text',
    },
    doneButton: {
        textTransform: 'none',
        size: 'small',
        variant: 'text',
        color: 'green',
    },
    denseTable: {
        '& th, & td': {
            padding: '1px 1px', // Adjusted padding for more density
            textAlign: 'left', // Align all cells to the right side
        },
    },
});

const AllPayments = (props) => {
    const classes = useStyles();
    const { state: { todaysPaymentsDuties, paymentUpdated }, getTodaysPaymentsDuties, updatePaymentStatus } = useContext(SingleCabContext);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const rowsPerPageRef = useRef(20);
    const [originalRowData, setOriginalRowData] = useState({});
    const [inUpdateProgress, setInUpdateProgress] = useState(false);
    useEffect(() => {
        if (!todaysPaymentsDuties) {
            getTodaysPaymentsDuties(page, rowsPerPageRef.current, props.contractType);
            setRows(todaysPaymentsDuties ?? []);
        } else {
            setRows(todaysPaymentsDuties);
        }
    }, [todaysPaymentsDuties]);

    useEffect(() => {
        if (paymentUpdated && paymentUpdated.status === 'DONE_SUCCESSFULLY') {
            setInUpdateProgress(false);
            let updatedRow = rows && rows.data && rows.data.data ? rows.data.data.find((r) => r.id === paymentUpdated.id) : {}
            updatedRow.status = paymentUpdated.paymentStatus;
            updatedRow.executionDate = paymentUpdated.executionDate;
            setRows((prevState) => ({
                ...prevState,
                updatedRow,
            }));
            setEditRowId(null);
            setOriginalRowData({});

        }
        if(paymentUpdated && paymentUpdated.status === 'FAILED'){
            setInUpdateProgress(false);
            setEditRowId(null);
            setOriginalRowData({});
            alert('משהו השתבש, נסה שוב מאוחר יותר');
        }
    }, [paymentUpdated]);
    const [editedValues, setEditedValues] = useState({});
    const handleValueChange = (rowId, field, value) => {
        setRows((prevRows) => {
            const newData = prevRows.data.data.map((row) => {
                if (row.id === rowId) {
                    return { ...row, [field]: value };
                }
                return row;
            });

            return {
                ...prevRows,
                data: {
                    ...prevRows.data,
                    data: newData,
                },
            };
        });
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        getTodaysPaymentsDuties(page, rowsPerPageRef.current, props.contractType);
    }, [page]);

    const handleRowClick = (row) => {
        setInUpdateProgress(true);
        updatePaymentStatus(row.id,row.invoiceNumber,row.receiptNumber,row.comment,1);
    };
    const handleCancelPayment = (row) => {
        setInUpdateProgress(true);
        updatePaymentStatus(row.id,row.invoiceNumber,row.receiptNumber,row.comment,0);
    };
    const handleChangeRowsPerPage = (event) => {
        rowsPerPageRef.current = parseInt(event.target.value, 10);
        setPage(0);
        getTodaysPaymentsDuties(page, rowsPerPageRef.current, props.contractType);
    };
    const [editRowId, setEditRowId] = useState(null);

    const handleEditToggle = (row) => {
        if (editRowId === row.id) {
            setEditRowId(null);
            setOriginalRowData({});
        } else {
            setEditRowId(row.id);
            setOriginalRowData({
                ...originalRowData,
                [row.id]: row,
            });
        }
    };
    const handleClearChanges = (rowId) => {
        setRows((prevRows) => {
            const newData = prevRows.data.data.map((row) => {
                if (row.id === rowId) {
                    return originalRowData[rowId];
                }
                return row;
            });

            return {
                ...prevRows,
                data: {
                    ...prevRows.data,
                    data: newData,
                },
            };
        });
        handleEditToggle({ id: rowId });
    };
    return (
        <>
            {rows && rows.data && rows.data.data.length > 0 && (
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={`${classes.table} ${classes.denseTable}`} aria-label="Payments table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">שם בעל הזכות</TableCell>
                                <TableCell align="right">מספר הזכות</TableCell>
                                <TableCell align="right">מספר חוזה</TableCell>
                                <TableCell align="right">תאריך שורה</TableCell>
                                <TableCell align="right">תאריך ביצוע</TableCell>
                                <TableCell align="right">אמצעי תשלום</TableCell>
                                <TableCell align="right">סכום</TableCell>
                                <TableCell align="right">מע"מ</TableCell>
                                <TableCell align="right">סכום כולל מע"מ</TableCell>
                                <TableCell align="right">חשבונית</TableCell>
                                <TableCell align="right">קבלה</TableCell>
                                <TableCell align="right">הערות</TableCell>
                                <TableCell align="left">פעולות</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.data.data.map((row) => (
                                <TableRow
                                    key={row.id}
                                    hover
                                    className={`${row.status === 1 ? classes.grayedRow : ''} ${classes.clickableRow}`}
                                >

                                    <TableCell align="left">{row.fullName}</TableCell>
                                    <TableCell align="right">{row.cabNumber}</TableCell>
                                    <TableCell align="right">{row.contractId}</TableCell>
                                    <TableCell align="right">{row.dueDate}</TableCell>
                                    <TableCell align="right">{row.executionDate ? moment(row.executionDate).format('YYYY-MM-DD HH:mm:ss') : '--'}</TableCell>
                                    <TableCell align="right">{paymentTypes(row.paymentType)}</TableCell>
                                    <TableCell align="right">{row.amount} ₪ </TableCell>
                                    <TableCell align="right">{row.vat} ₪ </TableCell>
                                    <TableCell align="right">{row.amountWithVat} ₪ </TableCell>
                                    <TableCell align="right">
                                        {editRowId === row.id ? (
                                            <input
                                                placeholder={'חשבונית'}
                                                style={{ borderColor: 'gray',width:'100%', borderRadius: '4px', borderWidth: '1px'}}
                                                type="text"
                                                value={editedValues[row.id]?.invoiceNumber || row.invoiceNumber}
                                                onChange={(e) => handleValueChange(row.id, 'invoiceNumber', e.target.value)}
                                            />
                                        ) : (
                                            row.invoiceNumber
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        {editRowId === row.id ? (
                                            <input
                                                placeholder={'קבלה'}
                                                style={{ borderColor: 'gray',width:'100%', borderRadius: '4px', borderWidth: '1px'}}
                                                type="text"
                                                value={editedValues[row.id]?.receiptNumber || row.receiptNumber}
                                                onChange={(e) => handleValueChange(row.id, 'receiptNumber', e.target.value)}
                                            />
                                        ) : (
                                            row.receiptNumber
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {editRowId === row.id ? (
                                            <input
                                                placeholder={'הערות'}
                                                style={{ borderColor: 'gray',width:'100%', borderRadius: '4px', borderWidth: '1px'}}
                                                type="text"
                                                value={editedValues[row.id]?.comment || row.comment}
                                                onChange={(e) => handleValueChange(row.id, 'comment', e.target.value)}
                                            />
                                        ) : (
                                            row.comment
                                        )}
                                    </TableCell>
                                    <TableCell align="left">

                                        <div className={classes.buttonContainer}>
                                            { inUpdateProgress && editRowId === row.id ?
                                                <IconButton
                                                    size={'small'}
                                                    color="primary"
                                                >
                                                    <CircularProgress />
                                                </IconButton>
                                                :
                                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                                    {
                                                        editRowId === row.id ?
                                                            <>
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() => handleRowClick(row)}
                                                                >
                                                                    <DoneIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    color="error"
                                                                    onClick={() => handleClearChanges(row.id)}
                                                                >
                                                                    <ClearRoundedIcon />
                                                                </IconButton>
                                                            </>
                                                            :
                                                            <>
                                                                { row.status === 0 ?
                                                                    <IconButton
                                                                        color="primary"
                                                                        onClick={() => handleEditToggle(row)}
                                                                    >
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    :
                                                                    <IconButton
                                                                        color="error"
                                                                        onClick={() => handleCancelPayment(row)}
                                                                    >
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                }
                                                            </>


                                                    }

                                                </ButtonGroup>

                                            }

                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {(!rows || rows.length === 0) && <Typography variant="body2">לא נמצאו נתונים</Typography>}
            <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={rows && rows.data && rows.data.totalCount ? rows.data.totalCount : 0}
                rowsPerPage={rowsPerPageRef.current}
                page={page}
                // onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
            />
        </>
    );
};

export default AllPayments;
