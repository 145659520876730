import React, {useContext, useEffect} from 'react'
import ServerSideTable from "../../../components/ServerSideTable";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import InterceptorApi from "../../../api/InterceptorApi";
import {Button, Paper} from "@material-ui/core";
import {paymentTypes} from '../../../helpers/convertedPaymentTypes'
import DialogPaymentsHistory from "./DialogPaymentsHistory";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import IconButton from "@material-ui/core/IconButton";
import {Context as SingleCabContext} from "../../../context/SingleCabContext";
import {translateCabContractStatuses} from '../../../helpers/convertedPaymentTypes'
import FormDialogCloseContract from "./FormDialogCloseContract";
import StopIcon from "@material-ui/icons/Stop";
import {red} from "@material-ui/core/colors";

const CabContractsHistory = (props) =>{
    const {state:{dataCab},setActiveComponent} = useContext(SingleCabContext);

    useEffect(() => {

    }, [props.cabsRef]);


    const renderRow=(val) =>{
        return <Typography variant="body2">{val}</Typography>
    }

    const switchLink = (rowData,colData,id,pathPart) =>{
        const path =   rowData.contractType ==='owner' || rowData.contractType ==='ownerRenewal'  ? 'cabsowners' : 'drivers'
        return (
            <Typography variant="body2">
                <Link
                    to={{pathname: `/${path}/${colData}`,state: {extraData:{id:id}}}}
                    style={{ textDecoration: 'none',color:'blue'}}>
                    {colData}
                </Link>
            </Typography>
        )
    }
    const payedAccumulation =(payments) =>{
      const sumPayments =   payments.reduce((a, b) => ({amount: a.amount + b.amount}), {amount: 0});
        return <Typography variant="body2">{' ₪ '+sumPayments.amount}</Typography>
    }

    const displayContract = (id) =>{
            const f=   InterceptorApi().get(`/api/files/${id}`,{responseType: 'blob'})
            f.then((res) =>{
                const file = new Blob([res.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
    }

    const contractTypeTranslate =(rowData) =>{
        const type=  rowData.contractType ==='owner' || rowData.contractType ==='ownerRenewal'  ? 'ספק' : 'נהג'
        return <Typography variant="body2">{type}</Typography>
    }
    const translatePaymentTYpe =(payment) =>{
        return <Typography variant="body2">{paymentTypes(payment)}</Typography>

    }
    const closeContract = (rowData) =>{
        console.log('tttttttttttttttt',rowData);
        if(rowData.endDate && moment(rowData.endDate).format('YYYY-MM-DD') >moment().format('YYYY-MM-DD') && !rowData.closeDate && (rowData.status ==='ACTIVE' || rowData.status ==='FUTURE') ){
            console.log('tttttttttttttttt222',rowData);
            if(rowData.contractType ==='ownerRenewal' || rowData.contractType ==='owner'){
                console.log('tttttttttttttttt333',rowData);
                return (
                    <FormDialogCloseContract
                        props={{con:dataCab.supplierContract, refrence: 'driver'}}
                        entity={'supplier'}
                        title={'סיום חוזה מספר '+rowData.id}
                        content={'חוזה מול בעל זכות'}
                        contractId={rowData.id}
                    />
                )
            }else if(rowData.contractType ==='driverRenewal' || rowData.contractType ==='driver'){
                return (
                    <FormDialogCloseContract
                        props={{con:dataCab.consumerContract, refrence: 'consumer'}}
                        entity={'supplier'}
                        title={'סיום חוזה מספר '+rowData.id}
                        content={'חוזה מול נהג'}
                        contractId={rowData.id}
                    />
                )
            }else{
                return ''
            }
        }else{
            return <IconButton
                size="small"
                aria-label="delete"
                disabled={true}
            >
                <StopIcon fontSize="small"/>
            </IconButton>
        }


    }
    const url= '/getCabContractsHistory/'+props.id;
    const columns =[
        // {
        //     title: 'מזהה',
        //     field: 'id',
        //     render: rowData => renderRow(rowData.id)
        // },
        // {
        //     title: 'מספר זכות',
        //     field: 'cabNumber',
        //     render: rowData => renderRow(rowData.cabNumber)
        // },

        {
            title: 'סוג חוזה',
            field: 'contractType',
            render: rowData => rowData && rowData.contractType ? contractTypeTranslate(rowData) : renderRow('--')

        },
        {
            title: 'חוזה מול',
            field: 'personFullName',
            render: rowData => rowData && rowData.personFullName ? switchLink(rowData,rowData.personFullName,rowData.person) : renderRow('--')

        },
        {
            title: 'סטטוס',
            field: 'status',
            render: rowData => rowData && rowData.status ? translateCabContractStatuses(rowData.status) : renderRow('--')

        },
        {
            title: 'תאריך התחלה',
            field: 'startDate',
            render: rowData => rowData && rowData.startDate ? renderRow(rowData.startDate) : renderRow('--')

        },
        {
            title: 'תאריך סיום מקורי',
            field: 'endDate',
            render: rowData => rowData && rowData.endDate ? renderRow(rowData.endDate) : renderRow('--')

        },
        {
            title: 'תאריך סיום בפועל',
            field: 'closeDate',
            render:rowData => rowData && rowData.closeDate ? renderRow(moment(rowData.closeDate).format('YYYY-MM-DD')) : renderRow('--')

        },
        {
            title: 'מספר תשלומים',
            field: 'numberOfPayments',
            render:rowData => rowData && rowData.numberOfPayments ? renderRow(rowData.numberOfPayments) : renderRow('--')
        },
        {
            title: 'אמצעי תשלום',
            field: 'paymentType',
            render:rowData => rowData && rowData.paymentType ? translatePaymentTYpe(rowData.paymentType) : renderRow('--')
        },
        {
            title: 'סך כל תשלום',
            field: 'paymentAmount',
            render:rowData => rowData && rowData.paymentAmount ? renderRow(' ₪ '+rowData.paymentAmount ) : renderRow('--')
        },{
            title: 'סך הכל שולם',
            field: 'TotalPayed',
            render:rowData => rowData && rowData.payments ? payedAccumulation(rowData.payments) : renderRow('--')
        },
        {
            title: 'הערות',
            field: 'comment',
            render:rowData => rowData && rowData.comment ? renderRow(rowData.comment) : renderRow('--')
        },
        {
            title: 'תשלומים',
            field: 'paymentsArray',
            render:rowData => rowData && rowData.payments ? <DialogPaymentsHistory title={'היסטוריית תשלומים חוזה מספר '+ rowData.id} rowData ={rowData} />: '--'
        },
        {
            title: 'חוזה',
            field: 'contract',
            render:rowData => rowData ? <Button onClick={()=>displayContract(rowData.id)} color={'secondary'} >הצג חוזה</Button> : '--'
        },
        {
            title: 'סטטוס',
            field: 'statusChange',
            render:rowData => rowData ? closeContract(rowData)  : '--'
        }

    ]

     return(
         <Paper>
         <IconButton color="primary" onClick={() =>setActiveComponent("MAIN")}>
             <ArrowForwardIcon />
         </IconButton>
        <ServerSideTable columns={columns} title={'חוזים עבור זכות מספר: '+props.title} url={url}/>
        </Paper>
    )

}
export default CabContractsHistory;
