import React, {useContext, useEffect, useState} from 'react'
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Context as AuthContext} from "../../../context/AuthenticationContext";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import {useHistory} from 'react-router-dom';
import {Context as NotificationContext} from "../../../context/NotificationContext";

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        flexDirection:'row',
        flexGrow: 1,
        alignItems:'center',
        marginTop:"5%",
    },
    paper: {
         padding: theme.spacing(2),
         // textAlign: 'center',

            borderStyle:'solid',
            borderWidth:'1px',
            borderColor:'lightBlue',

    },
    field:{
        width:'100%'
    }
}));
const Login = (props) =>{
   console.log('---#');console.log(props);console.log('#---');
    const history = useHistory();
    const classes = useStyles();
    const {state:{isAuthenticated,token,errorMessage},authenticate,checkAuth} = useContext(AuthContext);
    const {pipeMessage} = useContext(NotificationContext);
    const [state,setState] = useState({userName:null,password:null});
    const handleClick = async() =>{
    await  authenticate(state.userName,state.password)
    }
    useEffect(() => {
        checkAuth()
        if(isAuthenticated === true && !props.err){
            history.push('/home');
        }
    }, [isAuthenticated,token]);
    useEffect(() => {
        if(errorMessage)
            alert('משהו השתבש. אנאנסה שנית בעוד מספר שניות')
    }, [errorMessage])

    return (

        <>
        <AppBar>
            <Toolbar>ניהול תשלומים</Toolbar>
        </AppBar>
            <Container  className={classes.root} fixed>
                <Grid
                    className={classes.root}
                >
                    <Grid item  style={{margin:'0 auto'}} xs={12} xl={5}>
                        <Paper elevation={3} className={classes.paper}>
                            <Typography style={{textAlign: 'center'}} variant="h5">כניסה למערכת</Typography>
                            <TextField
                                className={classes.field}
                                label="שם משתמש"
                                value={state.userName}
                                onChange={e =>setState({...state,userName:e.target.value})}
                            />
                            <br /><br />
                            <TextField
                                className={classes.field}
                                label="סיסמה"
                                value={state.password}
                                type="password"
                                onChange={e =>setState({...state,password:e.target.value})}
                            />
                            <br /><br /><br />
                            <Button style={{width:'100%'}} variant="contained" color="primary" onClick={handleClick}>
                                {'כניסה'}
                            </Button>
                            <br /><br />
                            <Link style={{ textAlign: 'right !important'}} href="#">
                                שכחתי סיסמה
                            </Link>
                        </Paper>
                    </Grid>
                </Grid>
        </Container>
        </>
    )

}

export default Login;
